import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import formatDateYYYYMMDD from './../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';
import { AccountContext } from './AccountContext';

export const ReportBuilderContext = React.createContext();

// #################### imports regarding date range #################################

export const ReportBuilderProvider = (props) => {
  const { managerAccounts, setManagerAccounts } = useContext(AccountContext);
  const { user } = useContext(AuthContext);
  const { listOfData } = useContext(DataContext);

  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] = useState();

  const [widgets, setWidgets] = useState([]);

  const [
    widgetIdThatTriggeredContextMenu,
    setWidgetIdThatTriggeredContextMenu
  ] = useState();

  const updateWidget = (widget, index) => {
    const copyOfWidgets = [...widgets];
    copyOfWidgets.splice(index, 1, widget);
    setWidgets(copyOfWidgets);
  };

  return (
    <ReportBuilderContext.Provider
      value={{
        contextMenuAnchorPosition,
        setContextMenuAnchorPosition,
        widgetIdThatTriggeredContextMenu,
        setWidgetIdThatTriggeredContextMenu,
        widgets,
        setWidgets,
        updateWidget
      }}
    >
      {props.children}
    </ReportBuilderContext.Provider>
  );
};
