import React, { useContext } from 'react';
import { MenuItem } from '@mui/material';

import html2canvas from 'html2canvas';

import { AccountContext } from '../../../../context/AccountContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

const saveAs = (blob, fileName) => {
  var elem = window.document.createElement('a');
  elem.href = blob;
  elem.download = fileName;
  elem.style = 'display:none;';
  (document.body || document.documentElement).appendChild(elem);
  if (typeof elem.click === 'function') {
    elem.click();
  } else {
    elem.target = '_blank';
    elem.dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      })
    );
  }
  URL.revokeObjectURL(elem.href);
  elem.remove();
};

function ExportIMG({ handleClose, zoom }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { setExportMode, isTableShifted } = useContext(InterfaceContext);

  const saveIMG = () => {
    // const logoUrl =
    //   currentManagerAccount?.logo ||
    //   'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

    const domElement = document.getElementById('report-builder-page');

    const parentDivElement = document.getElementById(
      'report-builder-page-parent-div'
    );

    parentDivElement.style.zoom = 1;
    // domElement.style.position = 'fixed';
    // domElement.style.padding = '50px';
    // domElement.className = '';

    // const gridContainerDomElement = document.getElementById('gridContainer');

    // gridContainerDomElement.style.height = 'unset';

    // var managerAccountLogo_IMG = document.createElement('img');
    // managerAccountLogo_IMG.src = logoUrl;

    // //remove bottom shadow

    // const bottomShadowElement = document.getElementById('bottomShadow');
    // if (bottomShadowElement) {
    //   bottomShadowElement.style.visibility = 'hidden';
    // }

    // const topShadowElement = document.getElementById('topShadow');
    // if (topShadowElement) {
    //   topShadowElement.style.visibility = 'hidden';
    // }

    // managerAccountLogo_IMG.style.width = 'auto';
    // managerAccountLogo_IMG.style.height = '100px';
    // managerAccountLogo_IMG.style.margin = '10px 0px 40px 0px';
    // // domElement.appendChild(DOM_img);
    // domElement.insertBefore(managerAccountLogo_IMG, domElement.firstChild);

    // var NexusTableLogo_IMG = document.createElement('img');
    // var NexusTableLogo_DIV = document.createElement('div');
    // var NexusTableLogo_TEXT = document.createElement('div');
    // NexusTableLogo_DIV.style.display = 'flex';
    // NexusTableLogo_DIV.style.marginTop = '10px';
    // NexusTableLogo_DIV.style.alignItems = 'center';
    // NexusTableLogo_TEXT.style.fontSize = '8px';
    // NexusTableLogo_TEXT.innerText = 'Powered by ';
    // NexusTableLogo_TEXT.style.verticalAlign = 'center';
    // NexusTableLogo_TEXT.style.padding = '0px 0px 2px 0px';

    // NexusTableLogo_IMG.src =
    //   'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

    // NexusTableLogo_IMG.style.width = '60px';
    // NexusTableLogo_IMG.style.height = 'auto';
    // NexusTableLogo_IMG.style.marginLeft = '10px';

    // NexusTableLogo_DIV.style.display = 'flex';
    // NexusTableLogo_DIV.style.flexDirection = 'row';

    // NexusTableLogo_DIV.style.alignContent = 'center';
    // NexusTableLogo_DIV.style.alignItems = 'center';

    // NexusTableLogo_DIV.appendChild(NexusTableLogo_TEXT);
    // NexusTableLogo_DIV.appendChild(NexusTableLogo_IMG);

    // domElement.appendChild(NexusTableLogo_DIV);

    html2canvas(domElement, {
      scale: 2,
      logging: true,
      letterRendering: 1,
      // allowTaint: false,
      useCORS: true
    }).then((canvas) => {
      var image = canvas.toDataURL('image/png', 1.0);
      // var fileName = currentExport.split('Export')[0] + '.png';
      const fileName = 'NexusTable_export.png';
      saveAs(image, fileName);
    });
    // domElement.style.position = 'unset';
    // domElement.style.padding = '0';
    // domElement.className = isTableShifted
    //   ? 'isTableShiftedTrue'
    //   : 'isTableShiftedFalse';
    // managerAccountLogo_IMG.remove();
    // // NexusTableLogo_IMG.remove();
    // NexusTableLogo_DIV.remove();
    // gridContainerDomElement.style.height = 'calc(100vh - 140px)';
    // if (bottomShadowElement) {
    //   bottomShadowElement.style.visibility = 'visible';
    // }
    // if (topShadowElement) {
    //   topShadowElement.style.visibility = 'visible';
    // }

    parentDivElement.style.zoom = zoom;
    setExportMode(false);
    handleClose();
  };

  return (
    <MenuItem
      style={{ fontSize: '12px', fontWeight: 'bold' }}
      onClick={saveIMG}
    >
      IMG
    </MenuItem>
  );
}

export default ExportIMG;
