import React, { useEffect, useState } from 'react';

import { TextField } from '@mui/material';
import { set } from 'mongoose';

import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import PieChartIcon from '@mui/icons-material/PieChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextFieldsIcon from '@mui/icons-material/TextFields';

import ImageIcon from '@mui/icons-material/Image';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

import FiveKIcon from '@mui/icons-material/FiveK';

import { useTheme } from '@mui/material';

function TaxonomyWidgetList({ isDraggingWidget, setIsDraggingWidget, zoom }) {
  const theme = useTheme();
  const [isDraggingThisWidgetType, setIsDraggingThisWidgetType] =
    useState(false);
  const [search, setSearch] = React.useState('');

  // const [grabbing, toggleGrabbing] = useState(false);
  const widgets = [
    // { name: '-', type: 'STRING', value: '-', defaultValue: '' },
    // { name: '_', type: 'STRING', value: '_', defaultValue: '' },
    // { name: '|', type: 'STRING', value: '|', defaultValue: '' },
    // { name: '#', type: 'STRING', value: '#', defaultValue: '' },
    // { name: '(', type: 'STRING', value: '(', defaultValue: '' },
    // { name: ')', type: 'STRING', value: ')', defaultValue: '' },
    // { name: '[', type: 'STRING', value: '[', defaultValue: '' },
    // { name: ']', type: 'STRING', value: ']', defaultValue: '' },
    // { name: '/', type: 'STRING', value: '/', defaultValue: '' },
    {
      isResizable: true,
      category: 'WIDGET',
      title: '',
      description: '',
      groupBy: [],
      metrics: [],
      name: 'Image',
      type: 'IMAGE_WIDGET',
      imageUrl:
        'https://nexustable.wpengine.com/wp-content/uploads/2024/10/white-wall-1-scaled.jpg',
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      style: {
        height: '200px',
        width: '200px',
        // overflow: 'hidden',
        // padding: '10px'
        opacity: 1,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'WIDGET',
      title: '',
      description: '',
      groupBy: [],
      metrics: [],
      name: 'Phone mockup',
      type: 'MOCKUP_WIDGET',
      mockupUrl:
        'https://nexustable.wpengine.com/wp-content/uploads/2024/10/phone-mockup.png',

      imageUrl: undefined,
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      style: {
        height: '711px',
        width: '345px',
        opacity: 1,
        // overflow: 'hidden',
        // padding: '10px'
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'WIDGET',
      title: '',
      description: '',
      groupBy: [],
      metrics: [],
      name: 'Manager Account Logo',
      type: 'MANAGER_ACCOUNT_LOGO_WIDGET',
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      style: {
        height: '60px',
        width: '200px',
        padding: '10px',
        opacity: 1
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: false,
      category: 'WIDGET',
      title: '',
      description: '',
      groupBy: [],
      metrics: [],
      name: 'Date Picker',
      type: 'DATE_PICKER_WIDGET',
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      style: {
        height: '60px',
        width: '200px',
        padding: '10px',
        opacity: 1
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: false
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'WIDGET',
      title: '',
      description: '',
      groupBy: [],
      metrics: [],
      name: 'Text',
      type: 'TEXT_WIDGET',
      value:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      style: {
        height: '100px',
        width: '200px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'WIDGET',
      title: 'Cost',
      description: '',
      groupBy: [],
      metrics: ['cost'],
      name: 'Number',
      type: 'NUMBER_WIDGET',
      value: '',
      variant: 'STANDARD',
      style: {
        height: '200px',
        width: '200px',
        opacity: 1,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'WIDGET',
      title: 'Cost',
      description: '',
      groupBy: [],
      metrics: ['cost'],
      name: 'Line Chart',
      type: 'LINE_CHART_WIDGET',

      style: {
        height: '250px',
        width: '410px',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'WIDGET',
      title: 'Cost',
      description: '',
      groupBy: ['documentType'],
      metrics: ['cost'],
      name: 'Pie Chart',
      type: 'PIE_CHART_WIDGET',

      style: {
        height: '250px',
        width: '410px',
        opacity: 1,
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'GROUP_BY',
      title: 'Name',
      description: '',
      groupBy: ['name'],
      metrics: [],
      name: 'Name',
      type: 'GROUP_BY',

      style: {
        // height: '250px',
        // width: '400px',
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    },
    {
      isResizable: true,
      category: 'GROUP_BY',
      title: 'Data Source',
      description: '',
      groupBy: ['documentType'],
      metrics: [],
      name: 'Data Source',
      type: 'GROUP_BY',

      style: {
        // height: '250px',
        // width: '400px',
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    },
    ...[
      'impressions',
      'cpm',
      'clicks',
      // 'ctr',
      'cpcv',
      'cpc',
      'cost',
      'budget',
      'action01Count',
      'action01Cpa',
      'action01Value',
      'action01Roas'
    ].map((column) => ({
      isResizable: true,
      category: 'METRIC',
      title: column?.[0]?.toUpperCase() + column?.slice(1),
      description: '',
      groupBy: [],
      metrics: [column],
      name: column?.[0]?.toUpperCase() + column?.slice(1),
      type: 'METRIC',

      style: {
        // height: '250px',
        // width: '400px',
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    })),
    {
      isResizable: true,
      category: 'METRIC',
      title: 'CTR (%)',
      description: '',
      groupBy: [],
      metrics: ['ctr'],
      name: 'CTR (%)',
      type: 'METRIC',

      style: {
        // height: '250px',
        // width: '400px',
        // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      },
      isShowingCard: true
      // defaultValue: ''
    }
    // {
    //   isResizable: true,
    //   category: 'METRIC',
    //   title: 'Impressions',
    //   description: '',
    //   groupBy: [],
    //   metrics: ['impressions'],
    //   name: 'Impressions',
    //   type: 'METRIC',

    //   style: {
    //     // height: '250px',
    //     // width: '400px',
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // defaultValue: ''
    // },
    // {
    //   isResizable: true,
    //   category: 'METRIC',
    //   title: 'Clicks',
    //   description: '',
    //   groupBy: [],
    //   metrics: ['clicks'],
    //   name: 'clicks',
    //   type: 'METRIC',

    //   style: {
    //     // height: '250px',
    //     // width: '400px',
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // defaultValue: ''
    // },
    // {
    //   isResizable: true,
    //   category: 'METRIC',
    //   title: 'Budget',
    //   description: '',
    //   groupBy: [],
    //   metrics: ['budget'],
    //   name: 'budget',
    //   type: 'METRIC',

    //   style: {
    //     // height: '250px',
    //     // width: '400px',
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // defaultValue: ''
    // }
    // {
    //   name: 'Static text',
    //   type: 'STATICTEXT',
    //   value: 'STATIC TEXT'
    //   // defaultValue: ''
    // },

    // {
    //   name: 'List',
    //   type: 'LIST',
    //   value: 'LIST',
    //   // defaultValue: '',
    //   options: []
    // },
    // // {
    // //   name: 'Dynamic List',
    // //   type: 'DYNAMICLIST',
    // //   value: 'DYNAMIC LIST',
    // //   // defaultValue: '',
    // //   options: []
    // // },
    // {
    //   name: 'Year YYYY',
    //   type: 'YEARYYYY',
    //   value: () => new Date().getFullYear()
    //   // defaultValue: ''
    // },

    // {
    //   name: 'Year YY',
    //   type: 'YEARYY',
    //   value: () => String(String(new Date().getFullYear()).slice(2))
    //   // defaultValue: ''
    // },
    // // {
    // //   name: 'Month M',
    // //   type: 'THISMONTH',
    // //   value: () => new Date().getMonth() + 1
    // //   // defaultValue: ''
    // // },
    // {
    //   name: 'Month MM',
    //   type: 'MONTHMM',
    //   value: () =>
    //     (new Date().getMonth() + 1).length !== 2
    //       ? '0' + (new Date().getMonth() + 1)
    //       : new Date().getMonth() + 1
    //   // defaultValue: ''
    // },
    // // {
    // //   name: 'Today D',
    // //   type: 'TODAY',
    // //   value: new Date().getDate()
    // //   // defaultValue: ''
    // // },
    // {
    //   name: 'Day DD',
    //   type: 'DAYDD',
    //   value: () =>
    //     new Date().getDate().toString().length !== 2
    //       ? '0' + new Date().getDate()
    //       : new Date().getDate()
    //   // defaultValue: ''
    // }
  ];

  //   const dragStart = (e, value) => {
  //     const target = e.target;
  //     console.log('value: ', value);
  //     e.dataTransfer.setData('droppedItem', JSON.stringify(value));
  //     setTimeout(() => {
  //       target.style.display = 'none';
  //     }, 0);
  //   };

  const dragStart = (e, value, origin, originIndex) => {
    setIsDraggingThisWidgetType(value.type);
    setIsDraggingWidget(true);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        value,
        origin,
        originIndex,
        eNativeEventOffsetX: e.nativeEvent.offsetX,
        eNativeEventOffsetY: e.nativeEvent.offsetY
      })
    );
    console.log('{ value, origin, originIndex }: ', {
      value,
      origin,
      originIndex
    });

    switch (value.type) {
      // case 'TEXT':
      //   target.style.width = '200px';
      //   target.style.height = '100px';
      //   break;
      // case 'LINE_CHART':
      //   target.style.width = '400px';
      //   target.style.height = '300px';
      //   break;

      default:
        target.style.width = value.style.width;
        target.style.minWidth = value.style.width;
        target.style.height = value.style.height;
        target.style.minHeight = value.style.height;
        target.style.zoom = zoom;
        target.style.display = 'flex';
        target.style.justifyContent = 'center';
        target.style.alignItems = 'center';

      // target.style.transform = `scale(${transformScale})`;

      // target.style.transformOrigin = '0% 0%';
      // target.style.height = value.style.height;
      // target.style.height = '100%';

      // var elem = window.document.createElement('div');
      // elem.style.width = '100px';
      // elem.style.height = '100px';
      // elem.style.backgroundColor = 'green';
      // target.appendChild(elem);
    }

    setTimeout(() => {
      target.style.display = 'none';
      target.style.zoom = 1;
    }, 0);
  };

  const dragEnd = (e, value) => {
    setIsDraggingThisWidgetType(null);
    setIsDraggingWidget(false);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
      target.style.width = 'auto';
      target.style.minWidth = 'auto';
      target.style.height = 'auto';
      target.style.minHeight = 'auto';
    }, 0);
    // toggleGrabbing(false);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const [filteredWidgetList, setFilteredWidgetList] = useState([]);
  useEffect(() => {
    const newWidgetList = widgets.filter(
      (widget) =>
        widget.type.includes(search.toLocaleUpperCase()) ||
        (widget.type === 'METRIC' &&
          widget?.metrics?.filter((metric) => metric?.includes(search))
            ?.length) ||
        (widget.type === 'GROUP_BY' &&
          widget?.groupBy?.filter((groupBy) => groupBy?.includes(search))
            ?.length)
    );
    setFilteredWidgetList(newWidgetList);
  });
  return (
    <div style={{}}>
      <div
        style={{
          padding: '0 0 10px 10px'
          // background: 'rgba(255,255,255,0.6)'
        }}
      >
        <TextField
          size="small"
          variant="standard"
          id="outlined-controlled"
          label="Search"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div
        style={{
          width: '100%',
          //   height: '100%',
          // background: 'rgba(255,255,255,0.6)',
          padding: '10px',
          rowGap: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          //   maxHeight: 'calc(71vh - 64px)',
          overflowY: 'scroll',
          // height: '100%'
          height: 'calc(80vh - 78px)'
          // height: '80vh'
          //   maxHeight: 'calc(100vh - 64px)'
        }}
      >
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Widgets
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type.includes('WIDGET'))
          .map((widget, index) => {
            let icon = <></>;

            const iconStyle = {
              color: theme.palette.mode === 'light' && 'grey',
              fontSize: '20px'
            };
            switch (widget.type) {
              case 'IMAGE_WIDGET':
                icon = <ImageIcon style={iconStyle} />;
                break;

              case 'MOCKUP_WIDGET':
                icon = <SmartphoneIcon style={iconStyle} />;
                break;
              case 'MANAGER_ACCOUNT_LOGO_WIDGET':
                icon = <BrandingWatermarkIcon style={iconStyle} />;
                break;

              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  // background: 'white',
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>{' '}
                {icon}
              </div>
            );
          })}
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Group By
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type === 'GROUP_BY')
          .map((widget, index) => {
            let icon = <></>;

            const iconStyle = {
              color: theme.palette.mode === 'light' && 'grey',
              fontSize: '20px'
            };
            switch (widget.type) {
              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>{' '}
                {icon}
              </div>
            );
          })}
        <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Metrics
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type === 'METRIC')
          .map((widget, index) => {
            let icon = <></>;

            const iconStyle = {
              color: theme.palette.mode === 'light' && 'grey',
              fontSize: '20px'
            };
            switch (widget.type) {
              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>{' '}
                {icon}
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default TaxonomyWidgetList;
