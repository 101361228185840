import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { DataContext } from '../../../../../context/DataContext';

function NumberWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const theme = useTheme();

  const { listOfData, arrayOfData, listOfFilteredDataSourceRows } =
    useContext(DataContext);

  // let value = 1000;

  const [value, setValue] = useState(null);

  useEffect(() => {
    let sumObject = {};
    for (let row of listOfFilteredDataSourceRows) {
      for (let key of [
        'budget',
        'cost',
        'impressions',
        'clicks',
        'action01Count',
        'action01Value',
        'action02Count',
        'action02Value',
        'action03Count',
        'action03Value'
      ]) {
        sumObject[key] = (sumObject[key] || 0) + (row[key] || 0);
      }
    }

    // console.log('sumObject: ', sumObject);
    // console.log('widget.metrics[0]: ', widget.metrics[0]);

    let newValue = null;
    switch (true) {
      case widget.metrics[0] === 'cpm':
        newValue = sumObject['impressions']
          ? sumObject['cost'] / (sumObject['impressions'] / 1000)
          : '-';
        break;

      case widget.metrics[0] === 'cpcv':
        newValue = sumObject['videoCompletions']
          ? sumObject['cost'] / sumObject['videoCompletions']
          : '-';
        break;

      case widget.metrics[0] === 'cpc':
        newValue = sumObject['clicks']
          ? sumObject['cost'] / sumObject['clicks']
          : '-';
        break;

      case widget.metrics[0] === 'ctr':
        newValue = sumObject['impressions']
          ? (sumObject['clicks'] / sumObject['impressions']) * 100
          : '-';
        break;

      case widget.metrics[0] === 'action01Roas':
        newValue = sumObject['cost']
          ? sumObject['action01Value'] / sumObject['cost']
          : '-';
        break;

      case widget.metrics[0] === 'action01Cpa':
        newValue = sumObject['action01Count']
          ? sumObject['cost'] / sumObject['action01Count']
          : '-';
        break;

      case [
        'budget',
        'cost',
        'impressions',
        'clicks',
        'action01Count',
        'action01Value',
        'action02Count',
        'action02Value',
        'action03Count',
        'action03Value'
      ].includes(widget.metrics[0]):
        newValue = sumObject[widget.metrics[0]];
        break;

      default:
        newValue = 'error';
    }

    if (newValue !== 'error') {
      setValue(
        (Math.round((newValue + Number.EPSILON) * 100) / 100).toLocaleString()
      );
    } else {
      setValue(newValue);
    }
  }, [listOfData, widget, listOfFilteredDataSourceRows]);

  return (
    <div
      onDrop={(e) => {
        e.preventDefault();
        // e.stopPropagation();
      }}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
      }}
    >
      <div
        onDrop={(e) => {
          e.preventDefault();
          // e.stopPropagation();
        }}
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {widget.title}
      </div>
      <div
        onDrop={(e) => {
          e.preventDefault();
          // e.stopPropagation();
        }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: '22px'
        }}
      >
        <span>{value}</span>
      </div>
    </div>
  );
}

export default React.memo(NumberWidget);
