import React from 'react';

import Tooltip from './Tooltip';

import CommentIcon from '@mui/icons-material/Comment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AdsClickIcon from '@mui/icons-material/AdsClick';

import FormatColorResetIcon from '@mui/icons-material/FormatColorReset';

import SwipeUpAltIcon from '@mui/icons-material/SwipeUpAlt';

function DefaultHead({
  headStyle,
  keyForMap,
  itemKey,
  sortColumnHandler,
  colHandler,
  colHandlerOnRelease,
  sortedColumn,
  tooltip,
  index,
  numberOfVisibleColumns
}) {
  let headTitle = itemKey;

  switch (itemKey) {
    case 'status':
      headTitle = 'Status';
      break;

    case 'checkedAt':
      headTitle = (
        <FactCheckIcon
          style={{ margin: '0px 0px 0px 10px', fontSize: '20px' }}
        />
      );
      break;
    case 'allowMissingBudget':
      headTitle = (
        <MoneyOffIcon
          style={{
            margin: '0px 0px 0px 12px',
            fontSize: '20px'
          }}
        />
      );
      break;
    case 'allowMissingClicks':
      headTitle = (
        <AdsClickIcon
          style={{
            margin: '0px 0px 0px 12px',
            fontSize: '20px'
          }}
        />
      );
      break;
    case 'allowAbnormallyHighCtr':
      headTitle = (
        <SwipeUpAltIcon
          style={{
            margin: '0px 0px 0px 12px',
            fontSize: '20px'
          }}
        />
      );
      break;
    case 'allowMissingImpressionsYesterday':
      headTitle = (
        <FormatColorResetIcon
          style={{
            margin: '0px 0px 0px 12px',
            fontSize: '20px'
          }}
        />
      );
      break;
    case 'allowImpressionsBeforeStartDate':
      headTitle = (
        <AirlineStopsIcon
          style={{
            margin: '0px 0px 0px 12px',
            fontSize: '20px'
          }}
        />
      );
      break;
    case 'allowImpressionsAfterEndDate':
      headTitle = (
        <AirlineStopsIcon
          style={{
            margin: '0px 0px 0px 12px',
            fontSize: '20px'
          }}
        />
      );
      break;
    case 'template':
      headTitle = 'Template';
      break;
    case 'type':
      headTitle = '';
      break;
    case 'path':
      headTitle = 'Path';
      break;
    case 'members':
      headTitle = 'Members';
      break;
    case 'labels':
      headTitle = 'Labels';
      break;
    case 'comments':
      headTitle = (
        <CommentIcon style={{ padding: '0px 0px 0px 4px', fontSize: '14px' }} />
      );
      break;
    case 'budget':
    case 'budgetSparkline':
      headTitle = 'Budget';
      break;

    case 'plannedGross':
    case 'plannedGrossSparkline':
      headTitle = 'Gross';
      break;

    case 'plannedNet':
    case 'plannedNetSparkline':
      headTitle = 'Net';
      break;

    case 'plannedNetNet':
    case 'plannedNetNetSparkline':
      headTitle = 'Net Net';
      break;

    case 'plannedCtc':
    case 'plannedCtcSparkline':
      headTitle = 'Cost To Client';
      break;

    case 'plannedTrp':
    case 'plannedTrpSparkline':
      headTitle = 'Target Rating Points';
      break;

    case 'plannedCpp':
    case 'plannedCppSparkline':
      headTitle = 'Cost Per Point';
      break;
    case 'plannedTechFee':
    case 'plannedTechFeeSparkline':
      headTitle = 'Tech Fee';
      break;
    case 'plannedAdOpsFee':
    case 'plannedAdOpsFeeSparkline':
      headTitle = 'Ad Ops Fee';
      break;
    case 'plannedAdServingCost':
    case 'plannedAdServingCostSparkline':
      headTitle = 'Ad Serving Cost';
      break;
    case 'plannedFeePercent':
    case 'plannedFeePercentSparkline':
      headTitle = 'Fee Percent';
      break;

    case 'kpi':
      headTitle = 'KPI';
      break;

    case 'plannedImpressions':
    case 'plannedImpressionsSparkline':
      headTitle = 'Impressions';
      break;

    case 'plannedClicks':
    case 'plannedClicksSparkline':
      headTitle = 'Clicks';
      break;

    case 'plannedCtr':
    case 'plannedCtrSparkline':
      headTitle = 'CTR';
      break;

    case 'plannedCpc':
    case 'plannedCpcSparkline':
      headTitle = 'CPC';
      break;

    case 'plannedReach':
    case 'plannedReachSparkline':
      headTitle = 'Reach';
      break;

    case 'plannedCpm':
    case 'plannedCpmSparkline':
      headTitle = 'CPM';
      break;

    case 'plannedFrequency':
    case 'plannedFrequencySparkline':
      headTitle = 'Frequency';
      break;

    case 'plannedActionCount':
    case 'plannedActionCountSparkline':
      headTitle = 'Actions';
      break;

    case 'plannedActionValue':
    case 'plannedActionValueSparkline':
      headTitle = 'Action Value';
      break;

    case 'plannedActionRoas':
    case 'plannedActionRoasSparkline':
      headTitle = 'Action ROAS';
      break;

    case 'plannedCpa':
    case 'plannedCpaSparkline':
      headTitle = 'CPA';
      break;

    // ############

    case 'generalDescription':
      headTitle = 'General';
      break;

    case 'buyingTypeDescription':
      headTitle = 'Buying Type';
      break;
    case 'creativeDescription':
      headTitle = 'Creative';
      break;
    case 'formatDescription':
      headTitle = 'Format';
      break;
    case 'channelDescription':
      headTitle = 'Channel';
      break;

    case 'audienceDataDescription':
      headTitle = 'Audience Data';
      break;

    case 'targetGroupDescription':
      headTitle = 'Target Group';
      break;

    case 'placementDescription':
      headTitle = 'Placement';
      break;

    case 'ganttBarDisplaySetting':
      headTitle = 'GanttBar Display';
      break;

    case 'goalDescription':
      headTitle = 'Goal';
      break;

    case 'budgetCurrency':
      headTitle = 'Budget';
      break;

    case 'averageDailyBudget':
      headTitle = 'Daily Budget';
      break;
    case 'calcDailyBudget':
    case 'calcDailyBudgetYesterday':
      headTitle = 'Daily Budget';
      break;
    case 'startDate':
      headTitle = 'Start Date';
      break;
    case 'endDate':
      headTitle = 'End Date';
      break;
    case 'numberOfDays':
      headTitle = 'Days';
      break;
    case 'numberOfDaysSinceStart':
      headTitle = 'Days';
      break;
    case 'numberOfDaysLeft':
      headTitle = 'Num. Days';
      break;
    case 'daysLeft':
      headTitle = 'Days';
      break;
    case '_id':
      headTitle = 'ID';
      break;

    case 'universe':
      headTitle = 'Universe';
      break;
    case 'trp':
    case 'trpSparkline':
      headTitle = 'TRPs';
      break;

    case 'spots':
    case 'spotsSparkline':
      headTitle = 'Spots';
      break;

    case 'impressions':
    case 'impressionsToday':
    case 'impressionsLifetime':
    case 'impressionsYesterday':
    case 'impressionsSparkline':
      headTitle = 'Impressions';
      break;

    case 'viewableImpressions':
    case 'viewableImpressionsToday':
    case 'viewableImpressionsLifetime':
    case 'viewableImpressionsYesterday':
    case 'viewableImpressionsSparkline':
      headTitle = 'Viewable Impressions';
      break;

    case 'measurableImpressions':
    case 'measurableImpressionsToday':
    case 'measurableImpressionsLifetime':
    case 'measurableImpressionsYesterday':
    case 'measurableImpressionsSparkline':
      headTitle = 'Measurable Impressions';
      break;

    case 'eligibleImpressions':
    case 'eligibleImpressionsToday':
    case 'eligibleImpressionsLifetime':
    case 'eligibleImpressionsYesterday':
    case 'eligibleImpressionsSparkline':
      headTitle = 'Eligible Impressions';
      break;

    case 'videoCompletions':
    case 'videoCompletionsToday':
    case 'videoCompletionsLifetime':
    case 'videoCompletionsYesterday':
    case 'videoCompletionsSparkline':
      headTitle = 'Video Completions';
      break;

    case 'reach':
      headTitle = 'Reach';
      break;
    // case 'impressionsYesterday':
    //   headTitle = 'Impressions Yest.';
    //   break;
    case 'totalImpressions':
    case 'totalImpressionsSparkline':
      headTitle = 'Impressions';
      break;
    case 'totalCpm':
    case 'cpm':
    case 'cpmToday':
    case 'cpmYesterday':
    case 'cpmSparkline':
      headTitle = 'CPM';
      break;
    case 'totalVCpm':
    case 'vCpm':
    case 'vCpmToday':
    case 'vCpmYesterday':
    case 'vCpmSparkline':
      headTitle = 'vCPM';
      break;
    case 'reach':
      headTitle = 'Reach';
      break;
    case 'cost':
    case 'costToday':
    case 'costLifetime':
    case 'costYesterday':
    case 'costSparkline':
      headTitle = 'Cost';
      break;

    case 'totalCost':
    case 'totalCostSparkline':
      headTitle = 'Cost';
      break;
    case 'pace':
    case 'paceYesterday':
    case 'calcPaceYesterday':
      headTitle = 'Pace';
      break;

    case 'clicks':
    case 'clicksToday':
    case 'clicksYesterday':
    case 'clicksSparkline':
    case 'totalClicks':
    case 'totalClicksSparkline':
      headTitle = 'Clicks';
      break;

    case 'ctr':
    case 'ctrToday':
    case 'ctrYesterday':
    case 'ctrSparkline':
      headTitle = 'CTR';
      break;

    case 'viewableRate':
    case 'viewableRateToday':
    case 'viewableRateYesterday':
    case 'viewableRateSparkline':
      headTitle = 'Viewable Rate';
      break;

    case 'vcr':
    case 'vcrToday':
    case 'vcrYesterday':
    case 'vcrSparkline':
      headTitle = 'Video Completion Rate';
      break;

    case 'searchImpressionShare':
    case 'searchImpressionShareToday':
    case 'searchImpressionShareYesterday':
    case 'searchImpressionShareSparkline':
      headTitle = 'Search Imp.';
      break;

    case 'cpcv':
    case 'cpcvToday':
    case 'cpcvSparkline':
    case 'cpcvYesterday':
      headTitle = 'CPCV';
      break;

    case 'cpc':
    case 'cpcToday':
    case 'cpcSparkline':
    case 'cpcYesterday':
      headTitle = 'CPC';
      break;

    case 'action01':
    case 'action01Count':
    case 'action01Cvr':
    case 'action01Cpa':
    case 'action01Value':
    case 'action01Roas':
    case 'action01Vpa':

    case 'action01CountSparkline':
    case 'action01CvrSparkline':
    case 'action01CpaSparkline':
    case 'action01ValueSparkline':
    case 'action01RoasSparkline':
    case 'action01VpaSparkline':

    case 'action01CountToday':
    case 'action01CvrToday':
    case 'action01CpaToday':
    case 'action01ValueToday':
    case 'action01RoasToday':
    case 'action01VpaToday':

    case 'action01CountYesterday':
    case 'action01CvrYesterday':
    case 'action01CpaYesterday':
    case 'action01ValueYesterday':
    case 'action01RoasYesterday':
    case 'action01VpaYesterday':
      headTitle = 'Action 01';
      break;

    case 'totalAction01':
    case 'totalAction01Count':
    case 'totalaction01Cvr':
    case 'totalAction01Cpa':
    case 'totalAction01Value':
    case 'totalAction01Roas':
    case 'totalAction01Vpa':

    case 'totalAction01CountSparkline':
    case 'totalaction01CvrSparkline':
    case 'totalAction01CpaSparkline':
    case 'totalAction01ValueSparkline':
    case 'totalAction01RoasSparkline':
    case 'totalAction01VpaSparkline':

    case 'totalAction01CountToday':
    case 'totalaction01CvrToday':
    case 'totalAction01CpaToday':
    case 'totalAction01ValueToday':
    case 'totalAction01RoasToday':
    case 'totalAction01VpaToday':

    case 'totalAction01CountYesterday':
    case 'totalaction01CvrYesterday':
    case 'totalAction01CpaYesterday':
    case 'totalAction01ValueYesterday':
    case 'totalAction01RoasYesterday':
    case 'totalAction01VpaYesterday':
      headTitle = 'Total Action 01';
      break;

    case 'action02':
    case 'action02Count':
    case 'action02Cvr':
    case 'action02Cpa':
    case 'action02Value':
    case 'action02Roas':
    case 'action02Vpa':

    case 'action02CountSparkline':
    case 'action02CvrSparkline':
    case 'action02CpaSparkline':
    case 'action02ValueSparkline':
    case 'action02RoasSparkline':
    case 'action02VpaSparkline':

    case 'action02CountToday':
    case 'action02CvrToday':
    case 'action02CpaToday':
    case 'action02ValueToday':
    case 'action02RoasToday':
    case 'action02VpaToday':

    case 'action02CountYesterday':
    case 'action02CvrYesterday':
    case 'action02CpaYesterday':
    case 'action02ValueYesterday':
    case 'action02RoasYesterday':
    case 'action02VpaYesterday':
      headTitle = 'Action 02';
      break;

    case 'totalAction02':
    case 'totalAction02Count':
    case 'totalaction02Cvr':
    case 'totalAction02Cpa':
    case 'totalAction02Value':
    case 'totalAction02Roas':
    case 'totalAction02Vpa':

    case 'totalAction02CountSparkline':
    case 'totalaction02CvrSparkline':
    case 'totalAction02CpaSparkline':
    case 'totalAction02ValueSparkline':
    case 'totalAction02RoasSparkline':
    case 'totalAction02VpaSparkline':

    case 'totalAction02CountToday':
    case 'totalaction02CvrToday':
    case 'totalAction02CpaToday':
    case 'totalAction02ValueToday':
    case 'totalAction02RoasToday':
    case 'totalAction02VpaToday':

    case 'totalAction02CountYesterday':
    case 'totalaction02CvrYesterday':
    case 'totalAction02CpaYesterday':
    case 'totalAction02ValueYesterday':
    case 'totalAction02RoasYesterday':
    case 'totalAction02VpaYesterday':
      headTitle = 'Total Action 02';
      break;

    case 'action03':
    case 'action03Count':
    case 'action03Cvr':
    case 'action03Cpa':
    case 'action03Value':
    case 'action03Roas':
    case 'action03Vpa':

    case 'action03CountSparkline':
    case 'action03CvrSparkline':
    case 'action03CpaSparkline':
    case 'action03ValueSparkline':
    case 'action03RoasSparkline':
    case 'action03VpaSparkline':

    case 'action03CountToday':
    case 'action03CvrToday':
    case 'action03CpaToday':
    case 'action03ValueToday':
    case 'action03RoasToday':
    case 'action03VpaToday':

    case 'action03CountYesterday':
    case 'action03CvrYesterday':
    case 'action03CpaYesterday':
    case 'action03ValueYesterday':
    case 'action03RoasYesterday':
    case 'action03VpaYesterday':
      headTitle = 'Action 03';
      break;

    case 'totalAction03':
    case 'totalAction03Count':
    case 'totalaction03Cvr':
    case 'totalAction03Cpa':
    case 'totalAction03Value':
    case 'totalAction03Roas':
    case 'totalAction03Vpa':

    case 'totalAction03CountSparkline':
    case 'totalaction03CvrSparkline':
    case 'totalAction03CpaSparkline':
    case 'totalAction03ValueSparkline':
    case 'totalAction03RoasSparkline':
    case 'totalAction03VpaSparkline':

    case 'totalAction03CountToday':
    case 'totalaction03CvrToday':
    case 'totalAction03CpaToday':
    case 'totalAction03ValueToday':
    case 'totalAction03RoasToday':
    case 'totalAction03VpaToday':

    case 'totalAction03CountYesterday':
    case 'totalaction03CvrYesterday':
    case 'totalAction03CpaYesterday':
    case 'totalAction03ValueYesterday':
    case 'totalAction03RoasYesterday':
    case 'totalAction03VpaYesterday':
      headTitle = 'Total Action 03';
      break;

    case 'ganttBarColor':
      headTitle = 'Bar';
      break;
  }

  if (itemKey.includes('Today')) {
    headTitle = headTitle + ' TD';
  }

  if (itemKey.includes('Yesterday') && !itemKey.includes('allow')) {
    headTitle = headTitle + ' YD';
  }

  let subTitle = '';
  switch (itemKey) {
    case 'allowImpressionsBeforeStartDate':
      subTitle = 'bef.';
      break;
    case 'allowImpressionsAfterEndDate':
      subTitle = 'aft.';
      break;
    case 'members':
    case 'labels':
      subTitle = '';
      break;

    case 'searchImpressionShare':
      subTitle = 'Share';
      break;

    case 'totalCost':
    case 'totalImpressions':
    case 'totalClicks':
      subTitle = 'total';
      break;
    case 'numberOfDays':
      subTitle = 'total';
      break;
    case 'numberOfDaysSinceStart':
      subTitle = 'since start';
      break;
    case 'numberOfDaysLeft':
    case 'daysLeft':
      subTitle = 'left';
      break;
    // case 'costSparkline':
    //   subTitle = 'sparkline';
    //   break;
    case 'totalCostSparkline':
    case 'totalImpressionsSparkline':
    case 'totalClicksSparkline':
      subTitle = 'total sparkline';
      break;
    case 'pace':
    case 'paceYesterday':
    case 'allowMissingBudget':
    case 'allowMissingClicks':
    case 'allowAbnormallyHighCtr':
    case 'allowMissingImpressionsYesterday':
    case 'allowImpressionsBeforeStartDate':
    case 'allowImpressionsAfterEndDate':
      subTitle = true;
      break;
    // case 'totalImpressions':
    //   subTitle = 'total';
    //   break;
    case 'totalCpm':
    case 'totalVCpm':
      subTitle = 'total';
      break;

    // case 'trpSparkline':
    // case 'spotsSparkline':
    // case 'impressionsSparkline':
    //   subTitle = 'sparkline';
    //   break;
    // case 'cpmSparkline':
    // case 'clicksSparkline':
    // case 'ctrSparkline':
    // case 'cpcSparkline':
    //   subTitle = 'sparkline';
    //   break;
    // case 'action01CountSparkline':
    // case 'action02CountSparkline':
    // case 'action03CountSparkline':
    //   subTitle = '(count)';
    //   break;
    // case 'action01ValueSparkline':
    // case 'action02ValueSparkline':
    // case 'action03ValueSparkline':
    //   subTitle = 'sparkline (value)';
    //   break;
    case 'calcDailyBudget':
    case 'calcDailyBudgetYesterday':
    case 'calcPaceYesterday':
      subTitle = 'calculated';
      break;
    case 'averageDailyBudget':
      subTitle = 'total average';
      break;
    case 'conversion_1_count':
      subTitle = 'count';
      break;
    case 'ganttBarColor':
      subTitle = 'color';
      break;
    case 'action01':
    case 'action02':
    case 'action03':
    case 'totalAction01':
    case 'totalAction02':
    case 'totalAction03':
      subTitle = 'type';
      break;
    // case 'action01CpaSparkline':
    // case 'action02CpaSparkline':
    // case 'action03CpaSparkline':
    // case 'totalAction01CpaSparkline':
    // case 'totalAction02CpaSparkline':
    // case 'totalAction03CpaSparkline':
    //   subTitle = 'sparkline (cpa)';
    //   break;
    // case 'action01RoasSparkline':
    // case 'action02RoasSparkline':
    // case 'action03RoasSparkline':
    // case 'totalAction01RoasSparkline':
    // case 'totalAction02RoasSparkline':
    // case 'totalAction03RoasSparkline':
    //   subTitle = 'sparkline (ROAS)';
    //   break;
    // case 'action01Count':
    // case 'action02Count':
    // case 'action03Count':
    // case 'action01CountToday':
    // case 'action02CountToday':
    // case 'action03CountToday':
    // case 'action01CountYesterday':
    // case 'action02CountYesterday':
    // case 'action03CountYesterday':
    // case 'totalAction01Count':
    // case 'totalAction02Count':
    // case 'totalAction03Count':
    // case 'totalAction01CountToday':
    // case 'totalAction02CountToday':
    // case 'totalAction03CountToday':
    // case 'totalAction01CountYesterday':
    // case 'totalAction02CountYesterday':
    // case 'totalAction03CountYesterday':
    //   subTitle = 'count';
    //   break;
    // case 'action01Cpa':
    // case 'action02Cpa':
    // case 'action03Cpa':
    // case 'action01CpaToday':
    // case 'action02CpaToday':
    // case 'action03CpaToday':
    // case 'action01CpaYesterday':
    // case 'action02CpaYesterday':
    // case 'action03CpaYesterday':
    // case 'totalAction01Cpa':
    // case 'totalAction02Cpa':
    // case 'totalAction03Cpa':
    // case 'totalAction01CpaToday':
    // case 'totalAction02CpaToday':
    // case 'totalAction03CpaToday':
    // case 'totalAction01CpaYesterday':
    // case 'totalAction02CpaYesterday':
    // case 'totalAction03CpaYesterday':
    //   subTitle = 'cpa';
    //   break;
    // case 'action01Value':
    // case 'action02Value':
    // case 'action03Value':
    // case 'action01ValueToday':
    // case 'action02ValueToday':
    // case 'action03ValueToday':
    // case 'action01ValueYesterday':
    // case 'action02ValueYesterday':
    // case 'action03ValueYesterday':
    // case 'totalAction01Value':
    // case 'totalAction02Value':
    // case 'totalAction03Value':
    // case 'totalAction01ValueToday':
    // case 'totalAction02ValueToday':
    // case 'totalAction03ValueToday':
    // case 'totalAction01ValueYesterday':
    // case 'totalAction02ValueYesterday':
    // case 'totalAction03ValueYesterday':
    //   subTitle = 'value';
    //   break;
    // case 'action01Roas':
    // case 'action02Roas':
    // case 'action03Roas':
    // case 'action01RoasToday':
    // case 'action02RoasToday':
    // case 'action03RoasToday':
    // case 'action01RoasYesterday':
    // case 'action02RoasYesterday':
    // case 'action03RoasYesterday':
    // case 'totalAction01Roas':
    // case 'totalAction02Roas':
    // case 'totalAction03Roas':
    // case 'totalAction01RoasToday':
    // case 'totalAction02RoasToday':
    // case 'totalAction03RoasToday':
    // case 'totalAction01RoasYesterday':
    // case 'totalAction02RoasYesterday':
    // case 'totalAction03RoasYesterday':
    //   subTitle = 'roas';
    //   break;
    // case 'action01Vpa':
    // case 'action02Vpa':
    // case 'action03Vpa':
    // case 'action01VpaToday':
    // case 'action02VpaToday':
    // case 'action03VpaToday':
    // case 'action01VpaYesterday':
    // case 'action02VpaYesterday':
    // case 'action03VpaYesterday':
    // case 'totalAction01Vpa':
    // case 'totalAction02Vpa':
    // case 'totalAction03Vpa':
    // case 'totalAction01VpaToday':
    // case 'totalAction02VpaToday':
    // case 'totalAction03VpaToday':
    // case 'totalAction01VpaYesterday':
    // case 'totalAction02VpaYesterday':
    // case 'totalAction03VpaYesterday':
    //   subTitle = 'avg value/action';
    //   break;
    case 'action01Cvr':
    case 'action02Cvr':
    case 'action03Cvr':
    case 'action01CvrToday':
    case 'action02CvrToday':
    case 'action03CvrToday':
    case 'action01CvrYesterday':
    case 'action02CvrYesterday':
    case 'action03CvrYesterday':
    case 'totalaction01Cvr':
    case 'totalaction02Cvr':
    case 'totalaction03Cvr':
    case 'totalaction01CvrToday':
    case 'totalaction02CvrToday':
    case 'totalaction03CvrToday':
    case 'totalaction01CvrYesterday':
    case 'totalaction02CvrYesterday':
    case 'totalaction03CvrYesterday':
      subTitle = 'conv. rate';
      break;
    case 'action01CvrSparkline':
    case 'action02CvrSparkline':
    case 'action03CvrSparkline':
      subTitle = '(CVR)';
      break;

    case 'budgetCurrency':
      subTitle = 'currency';
      break;

    case 'ganttBarDisplaySetting':
      subTitle = 'Setting';
      break;
    case 'reach':
      subTitle = 'manual';
      break;

    default:
      subTitle = '';
  }

  if (itemKey.includes('Lifetime')) {
    subTitle = 'lifetime (beta)';
  }

  if (itemKey.includes('Count')) {
    subTitle = subTitle + 'count ';
  }

  if (itemKey.includes('Value')) {
    subTitle = subTitle + 'value ';
  }

  if (itemKey.includes('Vpa')) {
    subTitle = 'vpa';
  }

  if (itemKey.includes('Cpa')) {
    subTitle = subTitle + 'cpa ';
  }

  if (itemKey.includes('Roas')) {
    subTitle = subTitle + 'roas ';
  }

  if (itemKey.includes('Description')) {
    subTitle = subTitle + 'desc ';
  }

  if (itemKey.includes('planned')) {
    subTitle = subTitle + 'planned ';
  }

  if (itemKey.includes('Sparkline')) {
    subTitle = subTitle + 'sparkline ';
  }

  let minWidth = null;
  let padding = '10px 20px';
  switch (itemKey) {
    case 'budget':
    case 'cost':
      minWidth = '140px';
      break;
    case 'allowMissingBudget':
    case 'allowMissingClicks':
    case 'allowAbnormallyHighCtr':
    case 'allowMissingImpressionsYesterday':
    case 'allowImpressionsBeforeStartDate':
    case 'allowImpressionsAfterEndDate':
      minWidth = '56px';
      padding = '10px 0px 0px 0px';
      break;
  }

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
      key={keyForMap}
      // draggable
      style={{
        gridColumn: index + 1,
        // backgroundImage: 'linear-gradient(to bottom, white, #f9fafa)',
        backgroundImage: ['checkbox', 'name', 'warning'].includes(itemKey)
          ? headStyle.background
          : headStyle.backgroundImage,
        color: headStyle.color,
        // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        // borderTop: '1px solid rgba(200, 200, 200, 0.5)',
        borderRight: headStyle['border'],
        borderBottom: headStyle['border'],
        borderTop: headStyle['border'],
        fontSize: '12px',
        gridRowStart: 1,
        gridRowEnd: 1,
        position: 'sticky',
        top: '0',
        // display: 'grid',
        display: 'inline-table',
        zIndex: '1000',
        height: '100%',
        width: '100%',
        padding,
        fontWeight: '800',
        minWidth,
        borderRadius: numberOfVisibleColumns === index + 1 && '0px 5px 0px 0px'
      }}
    >
      <div
        style={{
          margin: 'auto',
          cursor: 'pointer'
        }}
        onClick={(e) => sortColumnHandler(e, itemKey)}
      >
        <div
          style={{
            textAlign: 'center',
            margin: 'auto',
            whiteSpace: 'nowrap',
            height: '18px'
          }}
        >
          {/* <span
            // style={{ marginLeft: '6px', whiteSpace: 'nowrap' }}
            style={{
              margin: 'auto',
              whiteSpace: 'nowrap'
            }}
          > */}{' '}
          {headTitle}{' '}
          {sortedColumn.column === itemKey ? (
            sortedColumn.asc ? (
              <i className="fas fa-sort-down"></i>
            ) : (
              <i className="fas fa-sort-up"></i>
            )
          ) : (
            <span style={{ visibility: 'hidden' }}>
              <i className="fas fa-sort-up"></i>
            </span>
          )}
          {/* </span> */}
        </div>
        <div
          style={{
            fontSize: '10px',
            // color: '#bcbcbc',
            color: headStyle.color,
            margin: 'auto',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            visibility: subTitle ? 'visible' : 'hidden'
          }}
        >
          {subTitle} <Tooltip itemKey={itemKey} />
        </div>
      </div>
      <div
        className="hover-col-resize"
        style={{
          top: '0px',
          right: '0px',
          width: '6px',
          position: 'absolute',
          cursor: 'col-resize',

          userSelect: 'none',
          height: '100%'
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          return false;
        }}
        onMouseDown={(e) => colHandler(e, itemKey)}
        onMouseUp={(e) => colHandlerOnRelease(e, itemKey)}
      ></div>
    </div>
  );
}

export default React.memo(DefaultHead);
