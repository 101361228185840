import React, { useContext, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SpeedIcon from '@mui/icons-material/Speed';

import { InterfaceContext } from '../../../context/InterfaceContext';

import Slider from '@mui/material/Slider';

import Tooltip from '@mui/material/Tooltip';

import ZoomInIcon from '@mui/icons-material/ZoomIn';

export default function BasicPopover({ zoom, setZoom }) {
  // const { daysToGraph, setDaysToGraph } = useContext(InterfaceContext);

  // console.log('daysToGraph: ', daysToGraph);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [value, setValue] = React.useState(10);

  // useEffect(() => {
  //   setValue(zoom);
  // }, [zoom, open]);

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setZoom(newValue);
    }
  };

  return (
    <div>
      <Tooltip title="Speed settings">
        <Button
          aria-describedby={id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '20px',
            height: '20px',
            minHeight: 0,
            minWidth: 0
          }}
          onClick={handleClick}
        >
          <ZoomInIcon />
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            rowGap: '10px'
          }}
        >
          {/* <div
            style={{ width: '160px', fontSize: '12px', textAlign: 'center' }}
          >
            The number of days back NexusTable will visualize graphs. NexusTable
            runs slower the higher number you enter.
          </div> */}
          {/* {value < 15 && (
            <div
              style={{
                width: '160px',
                fontSize: '12px',
                textAlign: 'center',
                color: value < 15 && 'rgb(255, 107, 107)'
              }}
            >
              If you have lower than 15 days the orange performance warnings
              won't work.
            </div>
          )} */}
          <div style={{ fontWeight: 'bold', fontSize: '12px' }}>Zoom</div>
          <div style={{ paddingLeft: '7px' }}>{zoom * 100 + '%'}</div>
          <div style={{ width: '140px' }}>
            <Slider
              value={zoom}
              min={0.1}
              step={0.1}
              max={1}
              // scale={calculateValue}
              // getAriaValueText={valueLabelFormat}
              // valueLabelFormat={valueLabelFormat}
              onChange={handleChange}
            />
          </div>
          {/* <div
            style={{ display: 'flex', flexDirection: 'row', columnGap: '10px' }}
          >
            <Button
              size="small"
              onClick={() => {
                setZoom(zoom);
                setAnchorEl(null);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              size="small"
              onClick={() => {
                setZoom(value);
                setAnchorEl(null);
              }}
              variant="contained"
            >
              Save
            </Button>
          </div> */}
        </div>
      </Popover>
    </div>
  );
}
