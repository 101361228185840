import gql from 'graphql-tag';
// import client from './client';

import {
  allEvalFields,
  sumEvalFields,
  nonSumEvalFields,
  plannedEvalFields,
  aggregatedFields
} from '../components/tableV4/fields';

const evalFields = [
  ...plannedEvalFields,
  ...sumEvalFields,
  ...nonSumEvalFields
  // ...aggregatedFields
].join('\r\n');

const action = `
  {
    dataSource
    dataSourceRef
    dataSourceId
    dataSourceAccountId
    type
    name

    manualDataEntry {
      name
    }

    googleAds {
      id
      name
      type
    }
    adform {
      id
      name
      type
      adInteraction
    }
    linkedInAds {
      id
      name
      type
      adInteraction
    }
    cm360 {
      type
      advertiserId
      activityId
      activityName
      activityGroupId
      activityGroupName 
      attributionType
    }
    dv360 {
      type
      advertiserId
      activityId
      activityName
      activityGroupId
      activityGroupName 
      attributionType
    }
    facebookAds {
      id
      name
      type
      attributionWindow
    }
    snapchatAds {
      name
      type
    }
    tikTokAds {
      name
      type
    }
    pinterestAds {
      name
      type
      adInteraction
    }
    readpeak {
      name
      type
      id
    }
    strossle {
      name
      type
      id
    }
    amazonDsp {
      name
      type
    }
    taboolaAds {
      name
      type
    }
    microsoftAdvertising {
      name
      type
      id
    }
    microsoftInvest {
      name
      type
      id
      adInteraction
    }
    appleSearchAds {
      name
      type
    }
    googleAnalytics4 {
      name
      eventName {
        operator
        value
      }

      firstUserCampaignName {
        operator
        value
      }
      firstUserMedium {
        operator
        value
      }
      firstUserSource {
        operator
        value
      }
      sessionCampaignName {
        operator
        value
      }
      sessionMedium {
        operator
        value
      }
      sessionSource {
        operator
        value
      }

    }
  }
`;

const totaldata = `
  impressions
  cost
`;

const linkedinadsbasicdata = `
  accountId
  campaignGroupId
  campaignId
  startDate
  endDate
  costInLocalCurrency
  impressions
  clicks
`;

const linkedinadsconversiondata = `
  accountId
  campaignGroupId
  campaignId
  startDate
  endDate
  conversionId
  viralExternalWebsitePostClickConversions
  viralExternalWebsiteConversions
  externalWebsitePostClickConversions
  externalWebsitePostViewConversions
  externalWebsiteConversions
  pivot
  viralExternalWebsitePostViewConversions
  conversionValueInLocalCurrency
`;

const adformbasic = `

  clientID
  client
  campaignID
  campaign
  lineItemID
  lineItem
  date
  cost
  impressions
  clicks
  conversionsAll
  salesAll
  measurableImpressions
  viewImpressionsIAB
  viewableImpressions

`;

const adformconversions = `
  nexusActionType
  trackingFilterId
  clientID
  client
  campaignID
  campaign
  lineItemID
  lineItem
  date
  conversions
  sales
  conversionsAll
  salesAll

`;

const dv360basic = `
  advertiserId
  advertiserCurrency
  insertionOrderId
  lineItemId
  partnerCurrency
  date
  impressions
  billableImpressions
  clicks

  totalConversions
  postClickConversions
  postViewConversions
  revenueAdvertiserCurrency
  mediaCostAdvertiserCurrency
  customFee1AdvertiserCurrency
  customFee2AdvertiserCurrency
  customFee3AdvertiserCurrency
  customFee4AdvertiserCurrency
  customFee5AdvertiserCurrency
  mediaCostPartnerCurrency
  mediaCostUSD

  totalMediaCostAdvertiserCurrency
  totalMediaCostPartnerCurrency
  totalMediaCostUSD
  revenuePartnerCurrency
  revenueUSD
  firstQuartileViewsVideo
  midpointViewsVideo
  thirdQuartileViewsVideo
  completeViewsVideo
  startsVideo
  pausesVideo
  fullscreensVideo
  audioMutesAudio
  completeListensAudio
  firstQuartileAudio
  midpointAudio
  startsAudio
  audioStopsAudio
  thirdQuartileAudio
  audioMutesVideo
  unmutesVideo
  cm360PostViewRevenue
  cm360PostClickRevenue
  engagements
  provisionalImpressions
  trackedAds
  trueviewViews

  activeViewAudibleFullyOnScreenForHalfOfDuration15SecCapImpressions
  activeViewAudibleFullyOnScreenForHalfOfDuration15SecCapMeasurableImpressions
 
  activeViewAudibleFullyOnScreenForHalfOfDurationTrueviewImpressions
  activeViewAudibleFullyOnScreenForHalfOfDurationTrueviewMeasurableImpressions

  activeViewAverageViewableTimeSeconds
  activeViewViewableImpressions
  activeViewMeasurableImpressions

  activeViewImpressionsVisible10Seconds
  billableCostAdvertiserCurrency
  billableCostPartnerCurrency
  billableCostUSD
  companionClicksVideo
  companionViewsVideo
  skipsVideo
`;

const dv360conversions = `
  advertiserId
  advertiserCurrency
  insertionOrderId
  lineItemId
  partnerCurrency
  date

  floodlightActivityId
  postViewConversions
  postClickConversions
  cm360PostViewRevenue
  cm360PostClickRevenue
  totalConversions
`;

const cm360basic = `
  advertiser
  advertiserId
  campaign
  campaignId
  site
  siteId
  placement
  date
  placementId
  impressions
  clicks
  activeViewViewableImpressions
  activeViewMeasurableImpressions
  activeViewEligibleImpressions
  totalConversions
  richMediaVideoCompletions
  activityClickThroughConversions
  activityClickThroughRevenue
  richMediaTrueViewViews
  richMediaVideoViews
  activityViewThroughConversions
  activityViewThroughRevenue
  effectiveCpm
  dv360Cost
  mediaCost
  plannedMediaCost
  dv360CostUsd
  audioCompanionClicks
  audioCompanionImpressions
  richMediaAudioCompletions
  richMediaAudioFirstQuartileCompletes
  richMediaAudioThirdQuartileCompletes
  richMediaVideoInteractions
`;

const cm360conversions = `
  advertiser
  advertiserId
  campaign
  campaignId
  site
  siteId
  placement
  placementId
  date
  activityGroupId
  activityId
  activityViewThroughConversions
  activityViewThroughRevenue
  activityClickThroughConversions
  activityClickThroughRevenue
  totalConversions
  totalConversionsRevenue
`;

const googleanalytics4data = `(input: $nexusDocumentsQueryInput) {
  _id
  campaignName
  date
  eventName
  firstUserCampaignName
  firstUserMedium
  firstUserSource
  sessionCampaignName
  sessionMedium
  sessionSource
  medium
  propertyId
  source
  conversions
  engagedSessions
  eventCount
  eventRevenue
  eventValue
  last_updated
  sessions
  totalRevenue
}`;

const snapchatAdsData = `
  ad_account_id
  campaign_id
  ad_squad_id
  start_time
  end_time
  startDate
  endDate
  impressions
  swipes
  uniques
  spend
  ios_installs
  ios_installs_swipe_up
  ios_installs_view
  android_installs
  android_installs_swipe_up
  android_installs_view
  total_installs
  total_installs_swipe_up
  total_installs_view
  conversion_purchases
  conversion_purchases_swipe_up
  conversion_purchases_view
  conversion_purchases_value
  conversion_purchases_value_swipe_up
  conversion_purchases_value_view
  conversion_save
  conversion_save_swipe_up
  conversion_save_view
  conversion_start_checkout
  conversion_start_checkout_swipe_up
  conversion_start_checkout_view
  conversion_add_cart
  conversion_add_cart_swipe_up
  conversion_add_cart_view
  conversion_view_content
  conversion_view_content_swipe_up
  conversion_view_content_view
  conversion_add_billing
  conversion_add_billing_swipe_up
  conversion_add_billing_view
  conversion_sign_ups
  conversion_sign_ups_swipe_up
  conversion_sign_ups_view
  conversion_searches
  conversion_searches_swipe_up
  conversion_searches_view
  conversion_level_completes
  conversion_level_completes_swipe_up
  conversion_level_completes_view
  conversion_app_opens
  conversion_app_opens_swipe_up
  conversion_app_opens_view
  conversion_page_views
  conversion_page_views_swipe_up
  conversion_page_views_view
  conversion_subscribe
  conversion_subscribe_swipe_up
  conversion_subscribe_view
  conversion_ad_click
  conversion_ad_click_swipe_up
  conversion_ad_click_view
  conversion_ad_view
  conversion_ad_view_swipe_up
  conversion_ad_view_view
  conversion_complete_tutorial
  conversion_complete_tutorial_swipe_up
  conversion_complete_tutorial_view
  conversion_invite
  conversion_invite_swipe_up
  conversion_invite_view
  conversion_login
  conversion_login_swipe_up
  conversion_login_view
  conversion_share
  conversion_share_swipe_up
  conversion_share_view
  conversion_reserve
  conversion_reserve_swipe_up
  conversion_reserve_view
  conversion_achievement_unlocked
  conversion_achievement_unlocked_swipe_up
  conversion_achievement_unlocked_view
  conversion_add_to_wishlist
  conversion_add_to_wishlist_swipe_up
  conversion_add_to_wishlist_view
  conversion_spend_credits
  conversion_spend_credits_swipe_up
  conversion_spend_credits_view
  conversion_rate
  conversion_rate_swipe_up
  conversion_rate_view
  conversion_start_trial
  conversion_start_trial_swipe_up
  conversion_start_trial_view
  conversion_list_view
  conversion_list_view_swipe_up
  conversion_list_view_view
  custom_event_1
  custom_event_1_swipe_up
  custom_event_1_view
  custom_event_2
  custom_event_2_swipe_up
  custom_event_2_view
  custom_event_3
  custom_event_3_swipe_up
  custom_event_3_view
  custom_event_4
  custom_event_4_swipe_up
  custom_event_4_view
  custom_event_5
  custom_event_5_swipe_up
  custom_event_5_view
`;

const tikTokAdsData = `
  service_type
  advertiser_id
  campaign_id
  adgroup_id
  adgroup_name
  stat_time_day
  date
  dateString
  start_trial
  real_time_app_install
  total_download_start_value
  subscribe
  total_initiate_checkout_value
  shares
  initiate_checkout
  total_online_consult_value
  conversion
  spend_credits
  total_purchase_value
  total_app_event_add_to_cart_value
  total_ratings_value
  spend
  clicks_on_music_disc
  view_content
  total_search
  total_complete_payment_rate
  complete_payment_roas
  product_details_page_browse
  total_subscribe
  onsite_add_to_wishlist
  total_on_web_order_value
  loan_credit
  total_registration
  add_billing
  checkout
  real_time_conversion
  total_ratings
  on_web_subscribe
  total_add_to_wishlist_value
  video_views_p50
  total_form_value
  onsite_add_billing
  total_start_trial
  download_start
  user_registration
  online_consult
  button_click
  campaign_name
  registration
  total_spend_credits_value
  onsite_on_web_cart
  onsite_form
  comments
  impressions
  total_loan_credit
  page_event_search
  ix_button_click_count
  total_complete_tutorial_value
  objective_type
  app_install
  profile_visits
  total_web_event_add_to_cart_value
  video_play_actions
  total_view_content
  total_complete_tutorial
  total_app_event_add_to_cart
  follows
  video_watched_6s
  onsite_shopping
  onsite_initiate_checkout_count
  total_on_web_subscribe_value
  search
  total_loan_disbursement
  currency
  app_event_add_to_cart
  total_add_billing_value
  video_views_p100
  real_time_result
  loan_apply
  purchase
  video_views_p25
  total_on_web_add_to_wishlist_value
  video_watched_2s
  total_button_click_value
  add_to_wishlist
  clicks
  add_payment_info
  total_add_to_wishlist
  launch_app
  total_checkout
  ix_product_click_count
  onsite_on_web_detail
  likes
  video_views_p75
  total_add_payment_info
  total_spend_credits
  total_subscribe_value
  result
  total_product_details_page_browse_value
  form
  complete_payment
  reach
  sales_lead
  on_web_order
  total_sales_lead
  on_web_add_to_wishlist
  total_sales_lead_value
  total_purchase
  total_page_event_search_value
  onsite_download_start
  total_loan_apply
  secondary_goal_result
  complete_tutorial
  web_event_add_to_cart
  total_view_content_value
  total_launch_app
  total_login
  total_checkout_value
  total_user_registration_value
  login
  ratings
  loan_disbursement
  ix_page_view_count

`;

const pinterestAdsData = `
  accountId
  campaignId
  adGroupId
  date
  cost
  impressions
  clicks
  checkout
  checkout_value
  click_checkout
  click_checkout_value
  view_checkout
  view_checkout_value
`;

const readpeakBasicData = `
  clientId
  campaignId
  adGroupId
  date
  cost
  impressions
  clicks
  conversions
`;

const strossleBasicData = `
  groupId
  campaignId
  adSetId
  date
  cost
  impressions
  clicks
  conversions
`;

const amazonDspData = `
  advertiserId
  orderId
  liteItemId
  cost
  impressions
  viewableImpressions
  measurableImpressions
  clicks
  date
`;

const taboolaAdsData = `
  account_id
  account_number
  campaign
  date
  date_end_period
  clicks
  impressions
  visible_impressions
  spent
  conversions_value
  cpa_actions_num
  cpa_actions_num_from_clicks
  cpa_actions_num_from_views
`;

const microsoftAdvertisingBasicData = `
  accountId
  campaignId
  timePeriod
  adGroupId
  clicks
  impressions
  spend
  conversions
  allConversions
  revenue
  allRevenue
`;

const microsoftAdvertisingActionData = `
  accountId
  campaignId
  timePeriod
  adGroupId
  goalId
  conversions
  allConversions
  revenue
  allRevenue
`;

const microsoftInvestBasicData = `
  day
  advertiser_id
  campaign_id
  insertion_order_id
  line_item_id
  imps
  imps_viewed
  view_measured_imps
  clicks
  spend
  spend_adv_curr
  attributed_revenue
  attributed_pc_revenue
  attributed_pv_revenue
  post_view_convs
  post_view_revenue
  post_click_convs
  post_click_revenue
  total_revenue
  total_convs
  video_starts
  video_25_pcts
  video_50_pcts
  video_75_pcts
  video_completions
  video_served
`;

const microsoftInvestActionData = `
  day
  advertiser_id
  insertion_order_id
  pixel_id
  attributed_revenue
  attributed_pc_revenue
  attributed_pv_revenue
  post_view_convs
  post_view_revenue
  post_click_convs
  post_click_revenue
  total_revenue
  total_convs
  
`;

const appleSearchAdsData = `
  orgId
  campaignId
  date
  impressions
  cost
  installs
  taps
  conversions
`;

const googleanalytics4dataWithoutInput = `{
  _id
  campaignName
  date
  eventName
  firstUserCampaignName
  firstUserMedium
  firstUserSource
  sessionCampaignName
  sessionMedium
  sessionSource
  medium
  propertyId
  source
  conversions
  engagedSessions
  eventCount
  eventRevenue
  eventValue
  last_updated
  sessions
  totalRevenue
}`;

const nexusDocumentBasic = `
    createdAt
    taxonomyWidgets {
      isDynamic
      type
      value
      options {
        value
        dynamicType
        regex
      }
      description
    }
    isSet
    setFilters {
      type
      field
      operator
      values
      conjunction
      isCaseSensitive
    }
    defaultNexusBoardId
    defaultFields { 
      name
      string
      leftYAxisMetrics {
        name
        string
      }
      rightYAxisMetrics {
        name
        string
      }
    }
    personal
    template
    templateDescendant
    _id
    documentType
    campaignType
    name

    dataSourceCurrency

    dataSourcePartnerId
    dataSourceAccountId
    dataSourceAccountNumber
    dataSourceAdvertiserId
    dataSourceProfileId
    dataSourceProfileAccountId
    dataSourceClientId
    dataSourceGroupId
    dataSourceCustomerId
    dataSourceCampaignGroupId
    dataSourceCampaignId
    dataSourceInsertionOrderId
    dataSourceOcid
    lastDataFetch
    status
    invoiceStatus
    invoiceOrderId
    statusUpdatedAt
    checkedAt
    dateType
    startDate
    endDate
    costFactor
    generalDescription
    buyingTypeDescription
    creativeDescription
    formatDescription
    channelDescription
    audienceDataDescription
    targetGroupDescription
    placementDescription
    ganttBarDisplaySetting
    
    goalDescription
    
    showSubTasksOnCard

    allowMissingBudget
    allowMissingClicks
    allowAbnormallyHighCtr
    allowMissingImpressionsYesterday
    allowImpressionsBeforeStartDate
    allowImpressionsAfterEndDate

    comments



    ${evalFields}
  

    




    action01 ${action}
    action02 ${action}
    action03 ${action}

    totalAction01 ${action}
    totalAction02 ${action}
    totalAction03 ${action}

    members {
      _id
      firstName
      lastName
      email
    }

    labels {
      _id
      accountId
      name
      color
    }

    style {
      gantt {
        bar {
          backgroundColor
        }
      }
    }

    
`;

const nexusDocument = `
    createdAt
    taxonomyWidgets {
      isDynamic
      type
      value
      options {
        value
        dynamicType
        regex
      }
      description
    }

    nexusAssistant {
          
          
      overspendWarning
      overspendWarningAt
      overspendWarningResolved
      overspendWarningResolvedAt
      overspendWarningTextSent
      overspendWarningEmailSent


      impressionsBeforeStartDateWarning
      impressionsBeforeStartDateWarningAt
      impressionsBeforeStartDateWarningResolved
      impressionsBeforeStartDateWarningResolvedAt
      impressionsBeforeStartDateWarningTextSent
      impressionsBeforeStartDateWarningEmailSent

      impressionsAfterEndDateWarning
      impressionsAfterEndDateWarningAt
      impressionsAfterEndDateWarningResolved
      impressionsAfterEndDateWarningResolvedAt
      impressionsAfterEndDateWarningTextSent
      impressionsAfterEndDateWarningEmailSent

      impressionsAfterStatusChangeWarning
      impressionsAfterStatusChangeWarningAt
      impressionsAfterStatusChangeWarningResolved
      impressionsAfterStatusChangeWarningResolvedAt
      impressionsAfterStatusChangeWarningTextSent
      impressionsAfterStatusChangeWarningEmailSent



      overpace150PercentWarning
      overpace200PercentWarning
      overpace300PercentWarning
      overpace400PercentWarning
      overpace600PercentWarning



    }

    impressionsBeforeStartDate (input: $nexusDocumentsQueryInput)
    impressionsAfterEndDate (input: $nexusDocumentsQueryInput)
    impressionsAfterStatusChange (input: $nexusDocumentsQueryInput)

    allowMissingBudget
    allowMissingClicks
    allowAbnormallyHighCtr
    allowMissingImpressionsYesterday
    allowImpressionsBeforeStartDate
    allowImpressionsAfterEndDate
        


  
    isSet
    setFilters {
      type
      field
      operator
      values
      conjunction
      isCaseSensitive
    }

    defaultNexusBoardId
    defaultFields { 
      name
      string
      leftYAxisMetrics {
        name
        string
      }
      rightYAxisMetrics {
        name
        string
      }
    }
    personal
    template
    templateDescendant
    _id
    documentType
    campaignType
    name

    dataSourceCurrency

    dataSourcePartnerId
    dataSourceAccountId
    dataSourceAccountNumber
    dataSourceAdvertiserId
    dataSourceProfileId
    dataSourceProfileAccountId
    dataSourceClientId
    dataSourceGroupId
    dataSourceCustomerId
    dataSourceCampaignGroupId
    dataSourceCampaignId
    dataSourceInsertionOrderId
    dataSourceOcid
    lastDataFetch
    status
    invoiceStatus
    invoiceOrderId
    statusUpdatedAt
    checkedAt
    dateType
    startDate
    endDate
    costFactor
    generalDescription
    buyingTypeDescription
    creativeDescription
    formatDescription
    channelDescription
    audienceDataDescription
    targetGroupDescription
    placementDescription
    ganttBarDisplaySetting
    
    goalDescription
    
    showSubTasksOnCard


    comments



    ${evalFields}
  

    




    action01 ${action}
    action02 ${action}
    action03 ${action}

    totalAction01 ${action}
    totalAction02 ${action}
    totalAction03 ${action}

    members {
      _id
      firstName
      lastName
      email
    }

    labels {
      _id
      accountId
      name
      color
    }

    style {
      gantt {
        bar {
          backgroundColor
        }
      }
    }

    action01googleanalytics4data ${googleanalytics4data}
    action02googleanalytics4data ${googleanalytics4data}
    action03googleanalytics4data ${googleanalytics4data}

    totalAction01googleanalytics4data ${googleanalytics4data}
    totalAction02googleanalytics4data ${googleanalytics4data}
    totalAction03googleanalytics4data ${googleanalytics4data}
    

    facebookadsadsets (input: $nexusDocumentsQueryInput) {
      campaign_name
      campaign_id
      adset_name
      adset_id
      spend
      impressions
      date_start
      date_stop
      actions {
        action_type
        value
        _1d_view
        _1d_click
        _7d_click
        _28d_click
      }
      action_values {
        action_type
        value
        _1d_view
        _1d_click
        _7d_click
        _28d_click
      }
      outbound_clicks {
        action_type
        value
        _1d_click
        _7d_click
        _28d_click
      }
    }

    snapchatadscampaigndata (input: $nexusDocumentsQueryInput){
      ${snapchatAdsData}
    }

    tiktokadscampaigndata (input: $nexusDocumentsQueryInput){
      ${tikTokAdsData}
    }


    pinterestadscampaigndata (input: $nexusDocumentsQueryInput){
      ${pinterestAdsData}
    }

    readpeakadreportbasicdata (input: $nexusDocumentsQueryInput){
      ${readpeakBasicData}
    }

    strossleadreportbasicdata (input: $nexusDocumentsQueryInput){
      ${strossleBasicData}
    }

    amazondspadreportdata (input: $nexusDocumentsQueryInput){
      ${amazonDspData}
    }

    taboolaadscampaigndata (input: $nexusDocumentsQueryInput){
      ${taboolaAdsData}
    }

    microsoftadvertisingbasicdata (input: $nexusDocumentsQueryInput){
      ${microsoftAdvertisingBasicData}
    }

    microsoftadvertisingactiondata (input: $nexusDocumentsQueryInput){
      ${microsoftAdvertisingActionData}
    }


    microsoftinvestbasicdata (input: $nexusDocumentsQueryInput){
      ${microsoftInvestBasicData}
    }

    microsoftinvestactiondata (input: $nexusDocumentsQueryInput){
      ${microsoftInvestActionData}
    }

    applesearchadsdata (input: $nexusDocumentsQueryInput){
      ${appleSearchAdsData}
    }

   

    googleadsadgroups (input: $nexusDocumentsQueryInput){
      adGroup {
        id
        name
      }
      campaign {
        id
        advertisingChannelType
        name
      }
      segments {
        date
      }
      metrics {
        clicks
        searchAbsoluteTopImpressionShare
        searchBudgetLostAbsoluteTopImpressionShare
        searchBudgetLostTopImpressionShare
        searchImpressionShare
        conversionsValue
        conversions
        costMicros
        allConversionsValue
        allConversions
        impressions
        videoQuartileP100Rate
      }
    }

  
    googleadsadgroupconversions (input: $nexusDocumentsQueryInput) {
      adGroup {
        id
        name
      }
      campaign {
        id
        advertisingChannelType
        name
      }
      segments {
        date
        conversionAction
        conversionActionName
      }
      metrics {
        allConversionsValue
        allConversions
      }
    }



    cm360basic (input: $nexusDocumentsQueryInput) {
      ${cm360basic}
    }

    cm360conversions (input: $nexusDocumentsQueryInput) {
      ${cm360conversions}
    }
    
    dv360basic (input: $nexusDocumentsQueryInput){
      ${dv360basic}
    }

    
    dv360conversions (input: $nexusDocumentsQueryInput){
      ${dv360conversions}
    }

    dv360lineiteminfo(input: $nexusDocumentsQueryInput){
      lineItemId
      displayName
    }

    adformbasicdata(input: $nexusDocumentsQueryInput){
      ${adformbasic}
    }

    adformconversiondata(input: $nexusDocumentsQueryInput){
      ${adformconversions}
    }



    linkedinadsbasicdata(input: $nexusDocumentsQueryInput){
      ${linkedinadsbasicdata}
    }

    linkedinadsconversiondata(input: $nexusDocumentsQueryInput){
      ${linkedinadsconversiondata}
    }





    

    snapchatadsadsquadinfo(input: $nexusDocumentsQueryInput){
      id
      name
    }
    tiktokadsadgroupinfo(input: $nexusDocumentsQueryInput){
      id
      name
    }
    pinterestadsadgroupinfo(input: $nexusDocumentsQueryInput){
      id
      name
    }

    readpeakadgroupinfo(input: $nexusDocumentsQueryInput){
      id
      name
    }

    strossleadsetinfo(input: $nexusDocumentsQueryInput){
      id
      name
    }

    amazondsplineiteminfo(input: $nexusDocumentsQueryInput){
      id
      name
    }


    
`;

const nexusDocumentBasicWithoutInput = `
    createdAt
    taxonomyWidgets {
      isDynamic
      type
      value
      options {
        value
        dynamicType
        regex
      }
      description
    }

    isSet
    setFilters {
      type
      field
      operator
      values
      conjunction
      isCaseSensitive
    }

    defaultNexusBoardId
    defaultFields { 
      name
      string
      leftYAxisMetrics {
        name
        string
      }
      rightYAxisMetrics {
        name
        string
      }
    }
    personal
    template
    templateDescendant
    _id
    documentType
    campaignType
    name

    dataSourceCurrency

    dataSourcePartnerId
    dataSourceAccountId
    dataSourceAccountNumber
    dataSourceAdvertiserId
    dataSourceProfileId
    dataSourceProfileAccountId
    dataSourceClientId
    dataSourceGroupId
    dataSourceCustomerId
    dataSourceCampaignGroupId
    dataSourceCampaignId
    dataSourceInsertionOrderId
    dataSourceOcid
    lastDataFetch
    status
    invoiceStatus
    invoiceOrderId
    statusUpdatedAt
    checkedAt
    dateType
    startDate
    endDate
    costFactor
    generalDescription
    buyingTypeDescription
    creativeDescription
    formatDescription
    channelDescription
    audienceDataDescription
    targetGroupDescription
    placementDescription
    ganttBarDisplaySetting
    goalDescription

    showSubTasksOnCard
    allowMissingBudget
    allowMissingClicks
    allowAbnormallyHighCtr
    allowMissingImpressionsYesterday
    allowImpressionsBeforeStartDate
    allowImpressionsAfterEndDate

    comments

    ${evalFields}

    action01 ${action}
    action02 ${action}
    action03 ${action}

    totalAction01 ${action}
    totalAction02 ${action}
    totalAction03 ${action}

    members {
      _id
      firstName
      lastName
      email
    }

    labels {
      _id
      accountId
      name
      color
    }

    style {
      gantt {
        bar {
          backgroundColor
        }
      }
    }
`;

const nexusDocumentWithoutInput = `
    createdAt
    taxonomyWidgets {
      isDynamic
      type
      value
      options {
        value
        dynamicType
        regex
      }
      description
    }

    isSet

    setFilters {
      type
      field
      operator
      values
      conjunction
      isCaseSensitive
    }

    defaultNexusBoardId
    defaultFields { 
      name
      string
      leftYAxisMetrics {
        name
        string
      }
      rightYAxisMetrics {
        name
        string
      }
    }
    personal
    template
    templateDescendant
    _id
    documentType
    campaignType
    name

    dataSourceCurrency

    dataSourcePartnerId
    dataSourceAccountId
    dataSourceAccountNumber
    dataSourceAdvertiserId
    dataSourceProfileId
    dataSourceProfileAccountId
    dataSourceClientId
    dataSourceGroupId
    dataSourceCustomerId
    dataSourceCampaignGroupId
    dataSourceCampaignId
    dataSourceInsertionOrderId
    dataSourceOcid
    lastDataFetch
    status
    invoiceStatus
    invoiceOrderId
    statusUpdatedAt
    checkedAt
    dateType
    startDate
    endDate
    costFactor
    generalDescription
    buyingTypeDescription
    creativeDescription
    formatDescription
    channelDescription
    audienceDataDescription
    targetGroupDescription
    placementDescription
    ganttBarDisplaySetting
    goalDescription

    showSubTasksOnCard
    allowMissingBudget
    allowMissingClicks
    allowAbnormallyHighCtr
    allowMissingImpressionsYesterday
    allowImpressionsBeforeStartDate
    allowImpressionsAfterEndDate

    comments

    ${evalFields}

    action01 ${action}
    action02 ${action}
    action03 ${action}

    totalAction01 ${action}
    totalAction02 ${action}
    totalAction03 ${action}

    members {
      _id
      firstName
      lastName
      email
    }

    labels {
      _id
      accountId
      name
      color
    }

    style {
      gantt {
        bar {
          backgroundColor
        }
      }
    }


    action01googleanalytics4data ${googleanalytics4dataWithoutInput}
    action02googleanalytics4data ${googleanalytics4dataWithoutInput}
    action03googleanalytics4data ${googleanalytics4dataWithoutInput}

    totalAction01googleanalytics4data ${googleanalytics4dataWithoutInput}
    totalAction02googleanalytics4data ${googleanalytics4dataWithoutInput}
    totalAction03googleanalytics4data ${googleanalytics4dataWithoutInput}

    googleadsadgroups {
      adGroup {
        id
        name
      }
      campaign {
        id
        advertisingChannelType
        name
      }
      segments {
        date
      }
      metrics {
        clicks
        searchAbsoluteTopImpressionShare
        searchBudgetLostAbsoluteTopImpressionShare
        searchBudgetLostTopImpressionShare
        searchImpressionShare
        conversionsValue
        conversions
        costMicros
        allConversionsValue
        allConversions
        impressions
      }
    }



    facebookadsadsets {
      campaign_name
      campaign_id
      adset_name
      adset_id
      spend
      impressions
      date_start
      date_stop
      actions {
        action_type
        value
        _1d_view
        _1d_click
        _7d_click
        _28d_click
      }
      action_values {
        action_type
        value
        _1d_view
        _1d_click
        _7d_click
        _28d_click
      }
      outbound_clicks {
        action_type
        value
        _1d_click
        _7d_click
        _28d_click
      }
    }

    snapchatadscampaigndata {
      ${snapchatAdsData}
    }

    tiktokadscampaigndata {
      ${tikTokAdsData}
    }

    pinterestadscampaigndata {
      ${pinterestAdsData}
    }

    readpeakadreportbasicdata {
      ${readpeakBasicData}
    }


    strossleadreportbasicdata {
      ${strossleBasicData}
    }

    amazondspadreportdata {
      ${amazonDspData}
    }

    taboolaadscampaigndata {
      ${taboolaAdsData}
    }

    microsoftadvertisingbasicdata {
      ${microsoftAdvertisingBasicData}
    }
    microsoftadvertisingactiondata {
      ${microsoftAdvertisingActionData}
    }

    microsoftinvestbasicdata {
      ${microsoftInvestBasicData}
    }
    microsoftinvestactiondata {
      ${microsoftInvestActionData}
    }


    applesearchadsdata {
      ${appleSearchAdsData}
    }

    
  
    googleadsadgroupconversions {
      adGroup {
        id
        name
      }
      campaign {
        id
        advertisingChannelType
        name
      }
      segments {
        date
        conversionAction
        conversionActionName
      }
      metrics {
        allConversionsValue
        allConversions
      }
    }



    dv360basic {
      ${dv360basic}
    }
    dv360conversions {
      ${dv360conversions}
    }

    dv360lineiteminfo {
      lineItemId
      displayName
    }

    adformbasicdata {
      ${adformbasic}
    }

    adformconversiondata {
      ${adformconversions}
    }

    linkedinadsbasicdata {
      ${linkedinadsbasicdata}
    }

    linkedinadsconversiondata {
      ${linkedinadsconversiondata}
    }

    snapchatadsadsquadinfo {
      id
      name
    }

    tiktokadsadgroupinfo {
      id
      name
    }


    pinterestadsadgroupinfo {
      id
      name
    }

    readpeakadgroupinfo {
      id
      name
    }

   strossleadsetinfo {
      id
      name
    }

    amazondsplineiteminfo {
      id
      name
    }

    cm360basic {
      ${cm360basic}
    }
    
    cm360conversions {
      ${cm360conversions}
    }
`;

export const messagesQuery = gql`
  query MessagesQuery {
    messages {
      id
      from {
        name
      }
      text
    }
  }
`;

export const addMessageMutation = gql`
  mutation AddMessageMutation($input: MessageInput!) {
    message: addMessage(input: $input) {
      id
      from {
        name
      }
      text
    }
  }
`;

export const dataDashboardQuery = gql`
  query QueryDashboardData($input: DashboardDataInput) {
    dashboardData(input: $input) {
      media
      date
      cost
      impressions
      viewableImpressions
      measurableImpressions
      clicks
      objective
    }
  }
`;

export const messageAddedSubscription = gql`
  subscription {
    messageAdded {
      id
      from {
        name
      }
      text
    }
  }
`;

export const managerAccountsQuery = gql`
  query QueryManagerAccounts {
    managerAccounts {
      _id
      name
      logo
      role
      scanMode
      isTimeboxingOn
      isGoogleAnalyticsOn
      nexusDocumentStatuses {
        _id
        number
        status
        name
        string
        color
      }
      nexusDocumentInvoiceStatuses {
        _id
        number
        status
        name
        string
        color
        warningRules {
          rule
          message
        }
      }
    }
  }
`;

export const accountQuery = gql`
  query QueryAccounts($accountsInput: AccountsInput) {
    accounts(input: $accountsInput) {
      _id
      name
      globalTasksId
      isFavorite
    }
  }
`;

export const timeboxesQuery = gql`
  query QueryTimeboxes($input: TimeboxesInput) {
    timeboxes(input: $input) {
      status
      isMutable
      type
      _id
      userId
      text
      startDateTime
      endDateTime
      startDate
      endDate
      deadline
      nexusDocuments {
        _id
        name
        documentType
      }
      accountName
      accountColor
      accountId
      createdByUser {
        _id
        firstName
        lastName
        email
      }
      lastUpdatedByUser {
        _id
        firstName
        lastName
        email
      }
      assignedToUser {
        _id
        firstName
        lastName
        email
      }
    }
  }
`;

export const nexusDocumentTimeboxesQuery = gql`
  query QueryNexusDocumentsTimeboxes($input: NexusDocumentsTimeboxesInput) {
    nexusDocumentsTimeboxes(input: $input) {
      status
      isMutable
      type
      _id
      userId
      text
      startDateTime
      endDateTime
      startDate
      endDate
      deadline
      nexusDocuments {
        _id
        name
        documentType
      }
      accountName
      accountColor
      accountId
      createdByUser {
        _id
        firstName
        lastName
        email
      }
      lastUpdatedByUser {
        _id
        firstName
        lastName
        email
      }
      assignedToUser {
        _id
        firstName
        lastName
        email
      }
      assignedToTimeboxList {
        _id
        name
      }
    }
  }
`;

export const timeboxListTimeboxesQuery = gql`
  query QueryTimeboxListTimeboxes($input: TimeboxListTimeboxesInput) {
    timeboxListTimeboxes(input: $input) {
      isMutable
      status
      type
      _id
      userId
      text
      startDateTime
      endDateTime
      startDate
      endDate
      deadline
      nexusDocuments {
        _id
        name
        documentType
      }
      accountName
      accountColor
      accountId
    }
  }
`;

export const userTimeboxListsQuery = gql`
  query QueryTimeboxLists($input: UserTimeboxListsInput) {
    userTimeboxLists(input: $input) {
      _id
      name
      assignedToUserId
    }
  }
`;

export const timeboxAutoTaggingKeywordsQuery = gql`
  query QueryTimeboxAutoTaggingKeywords(
    $input: TimeboxAutoTaggingKeywordsInput
  ) {
    timeboxAutoTaggingKeywords(input: $input) {
      _id
      status
      managerAccountId
      accountId
      operator
      conjunction
      values
      isCaseSensitive
    }
  }
`;

export const nexusTemplateDocumentsQuery = gql`
  query QueryNexusTemplateDocuments($input: NexusTemplateDocumentsInput) {
    nexusTemplateDocuments(input: $input) {
    template
    _id
    documentType
    name
    dataSourcePartnerId
    dataSourceAccountId
    dataSourceAccountNumber
    dataSourceAdvertiserId
    dataSourceProfileId
    dataSourceProfileAccountId
    dataSourceClientId
    dataSourceGroupId
    dataSourceCustomerId
    dataSourceCampaignGroupId
    dataSourceCampaignId
    dataSourceInsertionOrderId
    lastDataFetch
    status
    invoiceStatus
    invoiceOrderId
    dateType
    startDate
    endDate
    costFactor
    generalDescription
    buyingTypeDescription
    creativeDescription
    formatDescription
    channelDescription
    audienceDataDescription
    targetGroupDescription
    placementDescription
    ganttBarDisplaySetting
    goalDescription

    showSubTasksOnCard
    allowMissingBudget
    allowMissingClicks
    allowAbnormallyHighCtr
    allowMissingImpressionsYesterday
    allowImpressionsBeforeStartDate
    allowImpressionsAfterEndDate

    budget
    budgetCurrency
    plannedGross
    plannedNet
    plannedNetNet
    plannedCtc

    plannedTrp
    plannedCpp
    plannedTechFee
    plannedAdOpsFee
    plannedAdServingCost
    plannedFeePercent

    kpi
    plannedImpressions
    plannedClicks
    plannedCtr
    plannedCpc
    plannedReach
    plannedCpm
    plannedFrequency
    plannedActionCount
    plannedActionValue
    plannedActionRoas
    plannedCpa



    comments

    cost

    universe
    trp
    spots
    impressions
    viewableImpressions
    measurableImpressions
    eligibleImpressions
    reach
    videoCompletions
    clicks
    action01Count
    action02Count
    action03Count
    action01Value
    action02Value
    action03Value

    action01 ${action}
    action02 ${action}
    action03 ${action}

    totalAction01 ${action}
    totalAction02 ${action}
    totalAction03 ${action}

    members {
      _id
      firstName
      lastName
      email
    }

    labels {
      _id
      accountId
      name
      color
    }

    style {
      gantt {
        bar {
          backgroundColor
        }
      }
    }



    }
  }
`;

export const addNexusTemplateDocumentMutation = gql`
  mutation AddNexusTemplateDocumentMutation(
    $input: AddNexusTemplateDocumentInput!
  ) {
    addNexusTemplateDocument(input: $input) {
      response
    }
  }
`;

export const addDocumentMutation = gql`
  mutation AddDocumentMutation($input: AddDocumentsInput!) {
    addDocument(input: $input) {
      response
      action
      documentsAdded {
        checkedDocumentId
        action
        documentType
        level
        newNexusDocument {
          ${nexusDocumentWithoutInput}
        }
      }
    }
  }
`;

export const addTimeboxesMutation = gql`
  mutation AddTimeboxesMutation($input: AddTimeboxesInput!) {
    addTimeboxes(input: $input) {
      response
      action
      timeboxesAdded {
        status
        isMutable
        type
        _id
        userId
        text
        startDateTime
        endDateTime
        startDate
        endDate
        deadline
        nexusDocuments {
          _id
          name
          documentType
        }
        accountName
        accountColor
        accountId
        createdByUser {
          _id
          firstName
          lastName
          email
        }
        lastUpdatedByUser {
          _id
          firstName
          lastName
          email
        }
        assignedToUser {
          _id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const addTimeboxAutoTaggingKeywordsMutation = gql`
  mutation AddTimeboxAutoTaggingKeywordsMutation(
    $input: AddTimeboxAutoTaggingKeywordsInput!
  ) {
    addTimeboxAutoTaggingKeywords(input: $input) {
      response
      action
    }
  }
`;

export const updateTimeboxAutoTaggingKeywordsMutation = gql`
  mutation UpdateTimeboxAutoTaggingKeywordsMutation(
    $input: UpdateTimeboxAutoTaggingKeywordsInput!
  ) {
    updateTimeboxAutoTaggingKeywords(input: $input) {
      response
      action
    }
  }
`;

export const updateTimeboxesMutation = gql`
  mutation UpdateTimeboxesMutation($input: UpdateTimeboxesInput!) {
    updateTimeboxes(input: $input) {
      response
      action
      timeboxesUpdated {
        status
        isMutable
        type
        _id
        userId
        text
        startDateTime
        endDateTime
        startDate
        endDate
        deadline
        nexusDocuments {
          _id
          name
          documentType
        }
        accountName
        accountColor
        accountId
        createdByUser {
          _id
          firstName
          lastName
          email
        }
        lastUpdatedByUser {
          _id
          firstName
          lastName
          email
        }
        assignedToUser {
          _id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const updateUserToManagerAccountSettingsMutation = gql`
  mutation UpdateUserToManagerAccountSettingsMutation(
    $input: UpdateUserToManagerAccountSettingsInput!
  ) {
    updateUserToManagerAccountSettings(input: $input) {
      response
      action
    }
  }
`;

export const updateManagerAccountToAccountSettingsMutation = gql`
  mutation UpdateManagerAccountToAccountSettingsMutation(
    $input: UpdateManagerAccountToAccountSettingsInput!
  ) {
    updateManagerAccountToAccountSettings(input: $input) {
      response
      action
    }
  }
`;

export const fetchOutlookCalendarEventsMutation = gql`
  mutation FetchOutlookCalendarEventsMutation(
    $input: FetchOutlookCalendarEventsInput!
  ) {
    fetchOutlookCalendarEvents(input: $input) {
      response
      action
    }
  }
`;

export const addTaxonomyDocumentMutation = gql`
  mutation AddTaxonomyDocumentMutation($input: AddTaxonomyDocumentsInput!) {
    addTaxonomyDocument(input: $input) {
      response
    }
  }
`;

export const scanDocumentMutation = gql`
  mutation ScanDocumentMutation($input: ScanDocumentsInput!) {
    scanDocument(input: $input) {
      response
      scanResults {
        id
        name
        pass
        countriesPass
        countries
        budgetTypes
        budgetTypesPass
      }
    }
  }
`;

export const DocumentsToBeCopiedQuery = gql`
  query QueryDocumentsToBeCopied($input: DocumentsToBeCopiedInput) {
    documentsToBeCopied(input: $input) {
      _id
      name
      path
      indentations
      documentType
      parentDocumentIds
    }
  }
`;

export const copyDocumentMutation = gql`
  mutation CopyDocumentMutation($input: CopyDocumentsInput!) {
    copyDocument(input: $input) {
      response
      action
      documentsAdded {
        isCopy
        checkedDocumentId
        action
        documentType
        level
        newNexusDocument {
          ${nexusDocumentWithoutInput}
        }
      }
    }
  }
`;

export const moveDocumentMutation = gql`
  mutation moveDocumentMutation($input: MoveDocumentInput!) {
    moveDocument(input: $input) {
      response
      action
      documentMovedId
    }
  }
`;

export const updateDocumentMutation = gql`
  mutation UpdateDocumentMutation($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      response
      action
      documentsUpdated {
        accountId
        updateKeys
        documentId
        action
        documentType
        level
        updatedNexusDocument {
          ${nexusDocumentWithoutInput}
        }
      }
    }
  }
`;

export const bulkUpdateDocumentsMutation = gql`
  mutation BulkUpdateDocumentsMutation($input: BulkUpdateDocumentsInput!) {
    bulkUpdateDocuments(input: $input) {
      response
    }
  }
`;

export const emailPokeMutation = gql`
  mutation EmailPokeMutation($input: EmailPokeInput!) {
    emailPoke(input: $input) {
      response
    }
  }
`;

export const changeTableDataMutation = gql`
  mutation ChangeActivityMutation($input: ChangeActivityInput!) {
    changeActivity(input: $input) {
      status
    }
  }
`;

export const documentAddedSubscription = gql`
  subscription ($documentsUpdatedInput: DocumentsUpdatedInput) {
    documentsAdded(input: $documentsUpdatedInput) {
      checkedDocumentId
      action
      isCopy
      documentType
      level
      newNexusDocument {
        ${nexusDocumentWithoutInput}
      }
    }
  }
`;

export const documentUpdatedSubscription = gql`
  subscription ($documentsUpdatedInput: DocumentsUpdatedInput) {
    documentsUpdated(input: $documentsUpdatedInput) {
      accountId
      updateKeys
      documentId
      action
      documentType
      level
      updatedNexusDocument {
        ${nexusDocumentWithoutInput}
      }
    }
  }
`;

export const documentBasicAddedSubscription = gql`
  subscription ($documentsUpdatedInput: DocumentsUpdatedInput) {
    documentsAdded(input: $documentsUpdatedInput) {
      checkedDocumentId
      action
      documentType
      level
      newNexusDocument {
        ${nexusDocumentBasicWithoutInput}
      }
    }
  }
`;

export const documentBasicUpdatedSubscription = gql`
  subscription ($documentsUpdatedInput: DocumentsUpdatedInput) {
    documentsUpdated(input: $documentsUpdatedInput) {
      accountId
      updateKey
      updateKeys
      documentId
      action
      documentType
      level
      updatedNexusDocument {
        ${nexusDocumentBasicWithoutInput}
      }
    }
  }
`;

export const refetchDataSubscription = gql`
  subscription ($refetchDataInput: RefetchDataInput) {
    refetchData(input: $refetchDataInput) {
      action
    }
  }
`;

export const documentMovedSubscription = gql`
  subscription ($documentMovedInput: DocumentMovedInput) {
    documentMoved(input: $documentMovedInput) {
      documentMovedId
    }
  }
`;

export const accountUsersQuery = gql`
  query QueryAccountUsers($accountsUsersInput: AccountsUsersInput) {
    accountUsers(input: $accountsUsersInput) {
      _id
      name
      email
    }
  }
`;

export const accountLabelsQuery = gql`
  query QueryAccountLabels($accountsLabelsInput: AccountsLabelsInput) {
    accountLabels(input: $accountsLabelsInput) {
      _id
      accountId
      name
      color
    }
  }
`;
export const tableDataQueryBasic = gql`
  query QueryAccounts(
    $accountsInput: AccountsInput
    $nexusDocumentsQueryInput: NexusDocumentsQueryInput
 
  ) {
    accounts(input: $accountsInput) {
      _id
      name
      status
      
      ${evalFields}
      
      action01 ${action}
      action02 ${action}
      action03 ${action}
  
      totalAction01 ${action}
      totalAction02 ${action}
      totalAction03 ${action}

      style {
        gantt {
          bar {
            backgroundColor
          }
        }
      }
      

      nexusDocuments(input: $nexusDocumentsQueryInput) {
        ${nexusDocumentBasic}
        nexusDocuments(input: $nexusDocumentsQueryInput) {
          ${nexusDocumentBasic}
          nexusDocuments(input: $nexusDocumentsQueryInput) {
            ${nexusDocumentBasic}
            nexusDocuments(input: $nexusDocumentsQueryInput) {
              ${nexusDocumentBasic}
              nexusDocuments(input: $nexusDocumentsQueryInput) {
                ${nexusDocumentBasic}
                nexusDocuments(input: $nexusDocumentsQueryInput) {
                  ${nexusDocumentBasic}
                  nexusDocuments(input: $nexusDocumentsQueryInput) {
                    ${nexusDocumentBasic}
                    nexusDocuments(input: $nexusDocumentsQueryInput) {
                      ${nexusDocumentBasic}
                      nexusDocuments(input: $nexusDocumentsQueryInput) {
                        ${nexusDocumentBasic}
                        nexusDocuments(input: $nexusDocumentsQueryInput) {
                          ${nexusDocumentBasic}
                          nexusDocuments(input: $nexusDocumentsQueryInput) {
                            ${nexusDocumentBasic}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
`;

export const tableDataQuery2 = gql`
  query QueryAccounts(
    $accountsInput: AccountsInput
    $nexusDocumentsQueryInput: NexusDocumentsQueryInput
 
  ) {
    accounts(input: $accountsInput) {
      timeZone
      _id
      name

      status
      
      ${evalFields}
      
      action01 ${action}
      action02 ${action}
      action03 ${action}
  
      totalAction01 ${action}
      totalAction02 ${action}
      totalAction03 ${action}

      style {
        gantt {
          bar {
            backgroundColor
          }
        }
      }
      

      nexusDocuments(input: $nexusDocumentsQueryInput) {
        ${nexusDocument}
        nexusDocuments(input: $nexusDocumentsQueryInput) {
          ${nexusDocument}
          nexusDocuments(input: $nexusDocumentsQueryInput) {
            ${nexusDocument}
            nexusDocuments(input: $nexusDocumentsQueryInput) {
              ${nexusDocument}
              nexusDocuments(input: $nexusDocumentsQueryInput) {
                ${nexusDocument}
                nexusDocuments(input: $nexusDocumentsQueryInput) {
                  ${nexusDocument}
                  nexusDocuments(input: $nexusDocumentsQueryInput) {
                    ${nexusDocument}
                    nexusDocuments(input: $nexusDocumentsQueryInput) {
                      ${nexusDocument}
                      nexusDocuments(input: $nexusDocumentsQueryInput) {
                        ${nexusDocument}
                        nexusDocuments(input: $nexusDocumentsQueryInput) {
                          ${nexusDocument}
                          nexusDocuments(input: $nexusDocumentsQueryInput) {
                            ${nexusDocument}
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }
`;

// console.log(
//   'tableDataQuery2: ',
//   `
// query QueryAccounts(
//   $accountsInput: AccountsInput
//   $foldersInput: FoldersInput
// ) {
//   accounts(input: $accountsInput) {
//     _id
//     name

//     nexusDocuments(input: $nexusDocumentsQueryInput) {
//       ${nexusDocument}
//       nexusDocuments(input: $nexusDocumentsQueryInput) {
//         ${nexusDocument}
//         nexusDocuments(input: $nexusDocumentsQueryInput) {
//           ${nexusDocument}
//           nexusDocuments(input: $nexusDocumentsQueryInput) {
//             ${nexusDocument}
//             nexusDocuments(input: $nexusDocumentsQueryInput) {
//               ${nexusDocument}
//               nexusDocuments(input: $nexusDocumentsQueryInput) {
//                 ${nexusDocument}
//                 nexusDocuments(input: $nexusDocumentsQueryInput) {
//                   ${nexusDocument}
//                   nexusDocuments(input: $nexusDocumentsQueryInput) {
//                     ${nexusDocument}
//                     nexusDocuments(input: $nexusDocumentsQueryInput) {
//                       ${nexusDocument}
//                       nexusDocuments(input: $nexusDocumentsQueryInput) {
//                         ${nexusDocument}
//                         nexusDocuments(input: $nexusDocumentsQueryInput) {
//                           ${nexusDocument}
//                         }
//                       }
//                     }
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }

//   }
// }
// `
// );

export const commentsQuery = gql`
  query QueryComments($commentsInput: CommentsInput) {
    comments(input: $commentsInput) {
      _id
      type
      documentId
      date
      userId
      user {
        _id
        name
        email
      }
      text {
        text
        date
      }
      reactions {
        userId {
          _id
          firstName
          lastName
          email
        }
        reaction
      }
    }
  }
`;

export const addCommentMutation = gql`
  mutation CommentsMutation($addCommentInput: AddCommentInput!) {
    addComment(input: $addCommentInput) {
      response
    }
  }
`;

export const commentAddedSubscription = gql`
  subscription ($input: CommentAddedInput) {
    commentAdded(input: $input) {
      action
      comment {
        parentDocumentId
        accountId
        accountName
        accountColor
        _id
        type
        path
        documentId
        date
        userId
        user {
          _id
          name
          firstName
          lastName
          email
        }
        text {
          text
          date
        }
        reactions {
          userId {
            _id
            firstName
            lastName
            email
          }
          reaction
        }
      }
    }
  }
`;

export const documentNewsFeedQuery = gql`
  query QueryNewsFeedItems($input: DocumentNewsFeedItemsInput) {
    documentNewsFeedItems(input: $input) {
      _id
      type
      accountId
      accountName
      accountColor
      documentId
      date
      path
      interactivePath {
        _id
        name
      }
      userId
      user {
        _id
        name
        email
      }
      datePickerStartDate
      datePickerEndDate
      text {
        text
        date
      }
      labels {
        name
        color
      }
      documents {
        documentId
        accountId
        documentType
        name
        path
        startDate
        endDate
        status
        warnings
        interactivePath {
          _id
          name
        }
      }
      reactions {
        userId {
          _id
          firstName
          lastName
          email
        }
        reaction
      }
      childComments {
        documentId
        date
        user {
          firstName
          lastName
          email
        }
        text {
          text
          date
        }
      }
    }
  }
`;

export const newsFeedQuery = gql`
  query QueryNewsFeedItems($input: NewsFeedItemsInput) {
    newsFeedItems(input: $input) {
      _id
      type
      accountId
      accountName
      accountColor
      documentId
      date
      path
      interactivePath {
        _id
        name
      }
      userId
      user {
        _id
        name
        email
      }
      datePickerStartDate
      datePickerEndDate
      text {
        text
        date
      }
      labels {
        name
        color
      }
      documents {
        documentId
        accountId
        documentType
        name
        path
        interactivePath {
          _id
          name
        }
        startDate
        endDate
        status
        warnings
      }
      reactions {
        userId {
          _id
          firstName
          lastName
          email
        }
        reaction
      }
      childComments {
        documentId
        date
        user {
          firstName
          lastName
          email
        }
        text {
          text
          date
        }
      }
    }
  }
`;

export const newsFeedItemQuery = gql`
  query QueryNewsFeedItems($input: NewsFeedItemInput) {
    newsFeedItem(input: $input) {
      _id
      type
      accountId
      accountName
      accountColor
      documentId
      date
      path
      interactivePath {
        _id
        name
      }
      userId
      user {
        _id
        name
        email
      }
      datePickerStartDate
      datePickerEndDate
      text {
        text
        date
      }
      labels {
        name
        color
      }
      documents {
        documentId
        accountId
        documentType
        name
        path
        startDate
        endDate
        status
        warnings
      }
      reactions {
        userId {
          _id
          firstName
          lastName
          email
        }
        reaction
      }
      childComments {
        documentId
        date
        user {
          firstName
          lastName
          email
        }
        text {
          text
          date
        }
      }
    }
  }
`;

export const managerAccountUsersQuery = gql`
  query QueryManagerAccountUsers(
    $managerAccountUsersInput: ManagerAccountUsersInput
  ) {
    managerAccountUsers(input: $managerAccountUsersInput) {
      _id
      name
      email
    }
  }
`;

export const userInfoAndAccountsQuery = gql`
  query QueryUserInfoAndAccounts(
    $userInfoAndAccountsInput: UserInfoAndAccountsInput
  ) {
    userInfoAndAccounts(input: $userInfoAndAccountsInput) {
      _id
      name
      email
      emergencyContact
      emergencyDocumentTypes
      phoneNumber
      isPhoneNumberVerified
      role
      timeZone
      isSmsWarningsDelayed
      accounts {
        _id
        name
        permissions
      }
      lastLoginAt
    }
  }
`;

export const userTokensAndConnectionsQuery = gql`
  query QueryUserTokensAndConnections(
    $userTokensAndConnectionsInput: UserTokensAndConnectionsInput
  ) {
    userTokensAndConnections(input: $userTokensAndConnectionsInput) {
      tokens {
        dataSource
        expiresAt
        dataSourceUserId
        connections {
          name
          id
          status
        }
      }
      dataConnections {
        _id
        name
        dataSource
      }
    }
  }
`;

export const accountInfoQuery = gql`
  query QueryAccountInfo($accountInfosInput: AccountInfosInput) {
    accountInfo(input: $accountInfosInput) {
      _id
      name
      role
      permissions
      users {
        _id
        name
        email
        permissions
      }
      managerAccounts {
        _id
        name
        role
        managerAccountToAccountRelationId
      }
    }
  }
`;

export const timeboxListInfoQuery = gql`
  query QueryAccountInfo($timeboxListInfoInput: TimeboxListInfoInput) {
    timeboxListInfo(input: $timeboxListInfoInput) {
      _id
      name
      role
      permissions
      users {
        _id
        name
        email
        permissions
      }
      managerAccounts {
        _id
        name
        role
        managerAccountToAccountRelationId
      }
    }
  }
`;

export const DataSourcesQuery = gql`
  query QueryDataSources($dataSourcesInput: DataSourcesInput) {
    dataSources(input: $dataSourcesInput) {
      _id
      name
      dataSourceRef
      documentType
      dataSourceAccountId
      accountToDataSourceRelationId
      defaultNexusDocumentTemplateId
    }
  }
`;

export const AccountToDataSourceSettingsQuery = gql`
  query QueryAccountToDataSourceSettings(
    $input: AccountToDataSourceSettingsInput
  ) {
    accountToDataSourceSettings(input: $input) {
      managerAccountId
      accountId
      dataSourceId
      dataSourceRef
      permissions
      defaultNexusDocumentTemplateId

      defaultScanSettings {
        budgetTypes
        countries
      }
      defaultNexusDocumentValues {
        status
        allowMissingBudget
        allowMissingClicks
        allowAbnormallyHighCtr
        allowMissingImpressionsYesterday
        allowImpressionsBeforeStartDate
        allowImpressionsAfterEndDate
        action01 ${action}
        action02 ${action}
        action03 ${action}
      }
    }
  }
`;

export const ManagerAccountToAccountSettingsQuery = gql`
  query QueryManagerAccountToAccountSettings(
    $input: ManagerAccountToAccountSettingsInput
  ) {
    managerAccountToAccountSettings(input: $input) {
      managerAccountId
      accountId
      timeboxAutoTaggingKeywords {
        value
        operator
        isCaseSensitive
      }
    }
  }
`;

export const listAddedDataSourceIdsQuery = gql`
  query QueryListAddedDataSourceIds($input: ListAddedDataSourceIdsInput) {
    listAddedDataSourceIds(input: $input)
  }
`;

export const UpdateUserPhoneNumberMutation = gql`
  mutation UpdateUserPhoneNumberMutation($input: UpdateUserPhoneNumberInput!) {
    updateUserPhoneNumber(input: $input) {
      response
    }
  }
`;

export const VerifyUserPhoneNumberMutation = gql`
  mutation VerifyUserPhoneNumberMutation($input: VerifyUserPhoneNumberInput!) {
    verifyUserPhoneNumber(input: $input) {
      response
    }
  }
`;

export const RemoveUserPhoneNumberMutation = gql`
  mutation RemoveUserPhoneNumberMutation($input: RemoveUserPhoneNumberInput!) {
    removeUserPhoneNumber(input: $input) {
      response
    }
  }
`;

export const UpdateUserMutation = gql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      response
    }
  }
`;

export const UpdateAccountToDataSourceSettingsMutation = gql`
  mutation UpdateAccountToDataSourceSettingsMutation(
    $input: UpdateAccountToDataSourceSettingsInput!
  ) {
    updateAccountToDataSourceSettings(input: $input) {
      response
    }
  }
`;

export const AddDataSourceAlertRuleMutation = gql`
  mutation AddDataSourceAlertRuleMutation(
    $input: AddDataSourceAlertRuleInput!
  ) {
    addDataSourceAlertRule(input: $input) {
      response
    }
  }
`;

export const UpdateDataSourceAlertRuleMutation = gql`
  mutation UpdateDataSourceAlertRuleMutation(
    $input: UpdateDataSourceAlertRuleInput!
  ) {
    updateDataSourceAlertRule(input: $input) {
      response
    }
  }
`;

export const DeleteDataSourceAlertRuleMutation = gql`
  mutation DeleteDataSourceAlertRuleMutation(
    $input: DeleteDataSourceAlertRuleInput!
  ) {
    deleteDataSourceAlertRule(input: $input) {
      response
    }
  }
`;

export const ListFacebookAdsCampaignsQuery = gql`
  query QueryFacebookAdsListCampaigns($dataSourceInput: DataSourceInput) {
    listfacebookadscampaigns(input: $dataSourceInput) {
      accountId
      name
      id
      status
      startDate
      endDate
    }
  }
`;

export const ListSnapchatAdsCampaignsQuery = gql`
  query QueryListSnapchatAdsCampaigns($dataSourceInput: DataSourceInput) {
    listsnapchatadscampaigns(input: $dataSourceInput) {
      id
      name
      startDate
      endDate
      status
    }
  }
`;

export const ListTikTokAdsCampaignsQuery = gql`
  query QueryListTikTokAdsCampaigns($dataSourceInput: DataSourceInput) {
    listtiktokadscampaigns(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListPinterestAdsCampaignsQuery = gql`
  query QueryListPinterestAdsCampaigns($dataSourceInput: DataSourceInput) {
    listpinterestadscampaigns(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListReadpeakCampaignsQuery = gql`
  query QueryListReadpeakCampaigns($dataSourceInput: DataSourceInput) {
    listreadpeakcampaigns(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListStrossleCampaignsQuery = gql`
  query QueryListStrossleCampaigns($dataSourceInput: DataSourceInput) {
    liststrosslecampaigns(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListAmazonDspOrdersQuery = gql`
  query QueryListAmazonDspOrders($dataSourceInput: DataSourceInput) {
    listamazondsporders(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListTaboolaAdsCampaignsQuery = gql`
  query QueryListTaboolaAdsCampaigns($dataSourceInput: DataSourceInput) {
    listtaboolaadscampaigns(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListMicrosoftAdvertisingCampaignsQuery = gql`
  query QueryListMicrosoftAdvertisingCampaigns(
    $dataSourceInput: DataSourceInput
  ) {
    listmicrosoftadvertisingcampaigns(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListMicrosoftInvestInsertionOrdersQuery = gql`
  query QueryListMicrosoftInvestInsertionOrders(
    $dataSourceInput: DataSourceInput
  ) {
    listmicrosoftinvestinsertionorders(input: $dataSourceInput) {
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListLinkedInAdsCampaignGroupsQuery = gql`
  query QueryListLinkedInAdsCampaignGroups($dataSourceInput: DataSourceInput) {
    listlinkedinadscampaigngroups(input: $dataSourceInput) {
      id
      name
      startDate
      endDate
      status
    }
  }
`;

export const ListGoogleAdsCampaignsQuery = gql`
  query QueryGoogleAdsListCampaigns($dataSourceInput: DataSourceInput) {
    listgoogleadscampaigns(input: $dataSourceInput) {
      name
      id
      startDate
      endDate
      status
    }
  }
`;

export const ListAppleSearchAdsCampaignsQuery = gql`
  query QueryAppleSearchAdsListCampaigns($dataSourceInput: DataSourceInput) {
    listapplesearchadscampaigns(input: $dataSourceInput) {
      name
      id
      startDate
      endDate
      status
    }
  }
`;

export const ListCM360CampaignsQuery = gql`
  query QueryListCM360Campaigns($dataSourceInput: DataSourceInput) {
    listcm360campaigns(input: $dataSourceInput) {
      id
      name
      startDate
      endDate
    }
  }
`;

export const ListDV360InsertionOrdersQuery = gql`
  query QueryListDV360InsertionOrders($dataSourceInput: DataSourceInput) {
    listdv360insertionorders(input: $dataSourceInput) {
      displayName
      advertiserId
      campaignId
      insertionOrderId
      startDate
      endDate
      status
    }
  }
`;

export const ListAdformCampaignsQuery = gql`
  query QueryListAdformCampaigns($dataSourceInput: DataSourceInput) {
    listadformcampaigns(input: $dataSourceInput) {
      advertiserId
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const ListAdformOrdersQuery = gql`
  query QueryListAdformOrders($input: ListAdformOrdersInput) {
    listadformorders(input: $input) {
      advertiserId
      campaignId
      id
      name
      status
      startDate
      endDate
    }
  }
`;

export const FacebookAdsActionTypesQuery = gql`
  query QueryFacebookAdsActionTypes($fbActionTypeInput: FbActionTypeInput) {
    facebookadsactiontypes(input: $fbActionTypeInput) {
      type
      id
      name
    }
  }
`;

export const AdformActionTypesQuery = gql`
  query QueryAdformActionTypes($input: AdformActionTypesInput) {
    adformactiontypes(input: $input) {
      type
      id
      name
    }
  }
`;

export const LinkedInAdsActionTypesQuery = gql`
  query QueryLinkedInAdsActionTypes($input: LinkedInAdsActionTypesInput) {
    linkedinadsactiontypes(input: $input) {
      id
      name
      type
    }
  }
`;

export const MicrosoftAdvertisingActionTypesQuery = gql`
  query QueryMicrosoftAdvertisingActionTypes(
    $input: MicrosoftAdvertisingActionTypesInput
  ) {
    microsoftadvertisingactiontypes(input: $input) {
      id
      name
      type
    }
  }
`;

export const MicrosoftInvestActionTypesQuery = gql`
  query QueryMicrosoftInvestActionTypes(
    $input: MicrosoftInvestActionTypesInput
  ) {
    microsoftinvestactiontypes(input: $input) {
      id
      name
      type
    }
  }
`;

export const CM360ActionTypesQuery = gql`
  query QueryCM360ActionTypes($input: FbActionTypeInput) {
    cm360actiontypes(input: $input) {
      type
      advertiserId
      activityId
      activityName
      activityGroupId
      activityGroupName
      attributionType
    }
  }
`;

export const DV360ActionTypesQuery = gql`
  query QueryDV360ActionTypes($input: FbActionTypeInput) {
    dv360actiontypes(input: $input) {
      type
      advertiserId
      activityId
      activityName
      activityGroupId
      activityGroupName
      attributionType
    }
  }
`;

export const GoogleAdsActionTypesQuery = gql`
  query QueryFacebookAdsActionTypes($fbActionTypeInput: FbActionTypeInput) {
    googleadsactiontypes(input: $fbActionTypeInput) {
      type
      name
      id
    }
  }
`;

export const GoogleAnalytics4ParametersQuery = gql`
  query QueryGoogleAnalytics4Parameters(
    $googleAnalytics4ParametersInput: GoogleAnalytics4ParametersInput
  ) {
    googleanalytics4parameters(input: $googleAnalytics4ParametersInput) {
      firstUserCampaignName
      firstUserMedium
      firstUserSource
      sessionCampaignName
      sessionMedium
      sessionSource
      eventName
    }
  }
`;

export const appleSearchAdsTokensQuery = gql`
  query QueryAppleSearchAdsTokens($input: AppleSearchAdsTokensInput) {
    appleSearchAdsTokens(input: $input) {
      _id
      appleUserId
      parentOrgId
      parentOrgName
      clientId
    }
  }
`;

export const microsoftInvestTokensQuery = gql`
  query QueryMicrosoftInvestTokens($input: MicrosoftInvestTokensInput) {
    microsoftInvestTokens(input: $input) {
      _id
      microsoftInvestUserId
      microsoftInvestUserName
      microsoftInvestEntityName
    }
  }
`;

// remove this. Dupicate of add document.
export const addDataSourceRelationMutation = gql`
  mutation AddActivityMutation($input: AddDocumentsInput!) {
    addDocument(input: $input) {
      response
    }
  }
`;

export const addCustomDataSourceMutation = gql`
  mutation addCustomDataSourceMutation($input: AddCustomDataSourceInput) {
    addCustomDataSource(input: $input) {
      response
    }
  }
`;

export const addUserMutation = gql`
  mutation addUserMutation($input: AddUserInput) {
    addUser(input: $input) {
      response
    }
  }
`;

export const addAppleSearchAdsTokenMutation = gql`
  mutation addAppleSearchAdsTokenMutation($input: AddAppleSearchAdsTokenInput) {
    addAppleSearchAdsToken(input: $input) {
      response
    }
  }
`;

export const addMicrosoftInvestAuthTokenMutation = gql`
  mutation AddMicrosoftInvestAuthTokenMutation(
    $input: AddMicrosoftInvestAuthTokenInput!
  ) {
    addMicrosoftInvestAuthToken(input: $input) {
      response
      users {
        id
        username
        entity_name
      }
    }
  }
`;

export const addMicrosoftInvestTokenMutation = gql`
  mutation AddMicrosoftInvestTokenMutation(
    $input: AddMicrosoftInvestTokenInput!
  ) {
    addMicrosoftInvestToken(input: $input) {
      response
    }
  }
`;

export const managerAccountAccountListQuery = gql`
  query QueryAccountUsers(
    $managerAccountAccountListInput: ManagerAccountAccountListInput
  ) {
    managerAccountAccountList(input: $managerAccountAccountListInput) {
      _id
      name
      permissions
    }
  }
`;

export const managerAccountTimeboxListsQuery = gql`
  query QueryManagerAccountTimeboxListsQuery(
    $managerAccountTimeboxListsInput: ManagerAccountTimeboxListsInput
  ) {
    managerAccountTimeboxLists(input: $managerAccountTimeboxListsInput) {
      _id
      name
      permissions
    }
  }
`;

export const addAccountUserRelationMutation = gql`
  mutation addAccountToUserRelationMutation(
    $input: AddAccountToUserRelationInput
  ) {
    addAccountToUserRelation(input: $input) {
      response
    }
  }
`;

export const addTimeboxListUserRelationMutation = gql`
  mutation addTimeboxListToUserRelationMutation(
    $input: AddTimeboxListToUserRelationInput
  ) {
    addTimeboxListToUserRelation(input: $input) {
      response
    }
  }
`;

export const addUserToTimeboxListRelationMutation = gql`
  mutation addAccountToUserRelationMutation(
    $input: AddUserToTimeboxListRelationInput
  ) {
    addUserToTimeboxListRelation(input: $input) {
      response
    }
  }
`;

export const addManagerAccountToAccountRelationMutation = gql`
  mutation addManagerAccountToAccountRelationMutation(
    $input: AddManagerAccountToAccountRelationInput
  ) {
    addManagerAccountToAccountRelation(input: $input) {
      response
    }
  }
`;

export const removeAccountUserRelationMutation = gql`
  mutation removeAccountToUserRelationMutation(
    $input: RemoveAccountToUserRelationInput
  ) {
    removeAccountToUserRelation(input: $input) {
      response
    }
  }
`;

export const removeTimeboxListUserRelationMutation = gql`
  mutation removeTimeboxListToUserRelationMutation(
    $input: RemoveTimeboxListToUserRelationInput
  ) {
    removeTimeboxListToUserRelation(input: $input) {
      response
    }
  }
`;

export const toggleFavoriteAccountsMutation = gql`
  mutation toggleFavoriteAccountsMutation($input: ToggleFavoriteAccountsInput) {
    toggleFavoriteAccounts(input: $input) {
      response
    }
  }
`;

export const createAccountMutation = gql`
  mutation createAccountMutation($input: CreateAccountsInput) {
    createAccount(input: $input) {
      response
    }
  }
`;

export const createTimeboxListMutation = gql`
  mutation createTimeboxListMutation($input: CreateTimeboxListInput) {
    createTimeboxList(input: $input) {
      response
    }
  }
`;

export const dataSourcesQuery = gql`
  query QueryDataSources($dataSourcesInput: DataSourcesInput) {
    dataSources(input: $dataSourcesInput) {
      _id
      name
      dataSourceRef
      dataSourceAccountId
      user {
        firstName
        lastName
        email
      }
    }
  }
`;

export const dataSourceErrorsQuery = gql`
  query QueryDataSourceErrors($dataSourceErrorsInput: DataSourceErrorsInput) {
    dataSourceErrors(input: $dataSourceErrorsInput) {
      _id
      name
      dataSourceRef
      dataSourceUpdatedAt
      dataSourceAccountId
      status
      tokenInfo {
        expiresAt
        user {
          email
        }
      }
    }
  }
`;

export const actionDataSourcesQuery = gql`
  query QueryActionDataSources(
    $actionDataSourcesInput: ActionDataSourcesInput
  ) {
    actionDataSources(input: $actionDataSourcesInput) {
      _id
      name
      dataSource
      dataSourceRef
      dataSourceAccountId
    }
  }
`;

export const getDataSourceOAuthUrlMutation = gql`
  mutation getDataSourceOAuthUrlMutation($input: GetDataSourceOAuthUrlsInput) {
    getDataSourceOAuthUrl(input: $input) {
      response
      url
    }
  }
`;

export const googleAdsAccountsQuery = gql`
  query QueryGoogleDataSources(
    $googleAdsAccountsInput: GoogleAdsAccountsInput
  ) {
    googleAdsAccounts(input: $googleAdsAccountsInput) {
      id
      descriptiveName
    }
  }
`;

export const appleSearchAdsAccountsQuery = gql`
  query QueryAppleSearchAdsAccounts($input: AppleSearchAdsAccountsInput) {
    appleSearchAdsAccounts(input: $input) {
      id
      name
    }
  }
`;

export const microsoftInvestAdvertisersQuery = gql`
  query QueryMicrosoftInvestAdvertisers(
    $input: MicrosoftInvestAdvertisersInput
  ) {
    microsoftInvestAdvertisers(input: $input) {
      id
      name
    }
  }
`;

export const cm360AdvertisersQuery = gql`
  query QueryCM360Advertisers($cm360AdvertisersInput: CM360AdvertisersInput) {
    cm360Advertisers(input: $cm360AdvertisersInput) {
      advertiserId
      accountId
      name
      accountId
      profileId
    }
  }
`;

export const dv360AdvertisersQuery = gql`
  query QueryDV360Advertisers($dv360AdvertisersInput: DV360AdvertisersInput) {
    dv360Advertisers(input: $dv360AdvertisersInput) {
      advertiserId
      name
      partnerId
    }
  }
`;

export const adformAdvertisersQuery = gql`
  query QueryAdformAdvertisers($input: AdformAdvertisersInput) {
    adformAdvertisers(input: $input) {
      advertiserId
      name
    }
  }
`;

export const googleAnalyticsAccountsQuery = gql`
  query QueryDataSources($googleAdsAccountsInput: GoogleAdsAccountsInput) {
    googleAnalyticsAccounts(input: $googleAdsAccountsInput) {
      name
      displayName
    }
  }
`;

export const facebookAdsAccountsQuery = gql`
  query QueryDataSources($facebookAdsAccountsInput: FacebookAdsAccountsInput) {
    facebookAdsAccounts(input: $facebookAdsAccountsInput) {
      id
      name
      currency
      timezone_name
      timezone_id
      timezone_offset_hours_utc
    }
  }
`;

export const snapchatAdsAccountsQuery = gql`
  query QueryDataSources($snapchatAdsAccountsInput: SnapchatAdsAccountsInput) {
    snapchatAdsAccounts(input: $snapchatAdsAccountsInput) {
      id
      name
    }
  }
`;

export const tikTokAdsAdvertisersQuery = gql`
  query QueryDataSources($input: TikTokAdsAdvertisersInput) {
    tikTokAdsAdvertisers(input: $input) {
      id
      name
    }
  }
`;

export const pinterestAdsAccountsQuery = gql`
  query QueryDataSources($input: PinterestAdsAccountsInput) {
    pinterestAdsAccounts(input: $input) {
      id
      name
    }
  }
`;

export const readpeakAccountsQuery = gql`
  query QueryDataSources($input: ReadpeakAccountsInput) {
    readpeakAccounts(input: $input) {
      id
      name
    }
  }
`;

export const strossleAccountsQuery = gql`
  query QueryDataSources($input: StrossleAccountsInput) {
    strossleAccounts(input: $input) {
      id
      name
    }
  }
`;

export const amazonAdsAccountsQuery = gql`
  query QueryDataSources($input: AmazonAdsAccountsInput) {
    amazonAdsAccounts(input: $input) {
      id
      name
    }
  }
`;

export const taboolaAdsAccountsQuery = gql`
  query QueryDataSources($input: TaboolaAdsAccountsInput) {
    taboolaAdsAccounts(input: $input) {
      id
      name
    }
  }
`;

export const microsoftAdvertisingAccountsQuery = gql`
  query QueryDataSources($input: MicrosoftAdvertisingAccountsInput) {
    microsoftAdvertisingAccounts(input: $input) {
      id
      name
    }
  }
`;

export const linkedInAdsAccountsQuery = gql`
  query QueryDataSources($input: LinkedInAdsAccountsInput) {
    linkedInAdsAccounts(input: $input) {
      id
      name
    }
  }
`;

export const addGoogleAdsDataSourceMutation = gql`
  mutation addGoogleAdsDataSourceMutation($input: AddGoogleAdsDataSourceInput) {
    addGoogleAdsDataSource(input: $input) {
      response
    }
  }
`;

export const addAppleSearchAdsDataSourceMutation = gql`
  mutation addAppleSearchAdsDataSourceMutation(
    $input: AddAppleSearchAdsDataSourceInput
  ) {
    addAppleSearchAdsDataSource(input: $input) {
      response
    }
  }
`;

export const addMicrosoftInvestDataSourceMutation = gql`
  mutation addMicrosoftInvestDataSourceMutation(
    $input: AddMicrosoftInvestDataSourceInput
  ) {
    addMicrosoftInvestDataSource(input: $input) {
      response
    }
  }
`;

export const addCm360AdvertisersMutation = gql`
  mutation AddCM360DataSourceMutation($input: AddCM360DataSourceInput!) {
    addCM360DataSource(input: $input) {
      response
    }
  }
`;

export const addDV360AdvertisersMutation = gql`
  mutation AddDV360DataSourceMutation($input: AddDV360DataSourceInput!) {
    addDV360DataSource(input: $input) {
      response
    }
  }
`;

export const addAdformDataSourceMutation = gql`
  mutation AddAdformDataSourceMutation($input: AddAdformDataSourceInput!) {
    addAdformDataSource(input: $input) {
      response
    }
  }
`;

export const addGoogleAnalytics4DataSourceMutation = gql`
  mutation addGoogleAnalytics4DataSourceMutation(
    $input: AddGoogleAnalytics4DataSourceInput
  ) {
    addGoogleAnalytics4DataSource(input: $input) {
      response
    }
  }
`;

export const addFacebookAdsDataSourceMutation = gql`
  mutation addFacebookAdsDataSourceMutation(
    $input: AddFacebookAdsDataSourceInput
  ) {
    addFacebookAdsDataSource(input: $input) {
      response
    }
  }
`;

export const addSnapchatAdsDataSourceMutation = gql`
  mutation addSnapchatAdsDataSourceMutation(
    $input: AddSnapchatAdsDataSourceInput
  ) {
    addSnapchatAdsDataSource(input: $input) {
      response
    }
  }
`;

export const addTikTokAdsDataSourceMutation = gql`
  mutation addTikTokAdsDataSourceMutation($input: AddTikTokAdsDataSourceInput) {
    addTikTokAdsDataSource(input: $input) {
      response
    }
  }
`;

export const addPinterestAdsDataSourceMutation = gql`
  mutation addPinterestAdsDataSourceMutation(
    $input: AddPinterestAdsDataSourceInput
  ) {
    addPinterestAdsDataSource(input: $input) {
      response
    }
  }
`;

export const addReadpeakDataSourceMutation = gql`
  mutation addReadpeakDataSourceMutation($input: AddReadpeakDataSourceInput) {
    addReadpeakDataSource(input: $input) {
      response
    }
  }
`;

export const addStrossleDataSourceMutation = gql`
  mutation addStrossleDataSourceMutation($input: AddStrossleDataSourceInput) {
    addStrossleDataSource(input: $input) {
      response
    }
  }
`;

export const addAmazonDspDataSourceMutation = gql`
  mutation addAmazonDspDataSourceMutation($input: AddAmazonDspDataSourceInput) {
    addAmazonDspDataSource(input: $input) {
      response
    }
  }
`;

export const addTaboolaAdsDataSourceMutation = gql`
  mutation addTaboolaAdsDataSourceMutation(
    $input: AddTaboolaAdsDataSourceInput
  ) {
    addTaboolaAdsDataSource(input: $input) {
      response
    }
  }
`;

export const addMicrosoftAdvertisingDataSourceMutation = gql`
  mutation addMicrosoftAdvertisingDataSourceMutation(
    $input: AddMicrosoftAdvertisingDataSourceInput
  ) {
    addMicrosoftAdvertisingDataSource(input: $input) {
      response
    }
  }
`;

export const addLinkedInAdsDataSourceMutation = gql`
  mutation addLinkedinAdsDataSourceMutation(
    $input: AddLinkedInAdsDataSourceInput
  ) {
    addLinkedInAdsDataSource(input: $input) {
      response
    }
  }
`;

export const dataSourceInfoQuery = gql`
  query QueryDataSourceInfo($dataSourceInfoInput: DataSourceInfoInput) {
    dataSourceInfo(input: $dataSourceInfoInput) {
      _id
      id
      ocid
      useSuperDataSource
      status
      name
      role
      dataSourceRef
      user {
        _id
        name
        firstName
        lastName
        email
      }
      managerAccounts {
        _id
        name
      }
      accounts {
        _id
        name
      }
      trackingAlertIsActive
      trackingAlerts {
        days
        actions {
          name
          string
          id
        }
      }
    }
  }
`;

export const dataSourceAlertRulesQuery = gql`
  query QueryDataSourceAlertRules($input: DataSourceAlertRulesInput) {
    dataSourceAlertRules(input: $input) {
      _id
      status
      type
      field
      operator
      value
      alertUserIds
      unitOfTime
      duration
      dataSourceRef
      dataSourceId
    }
  }
`;

export const addAccountToDataSourceRelationMutation = gql`
  mutation addAccountToDataSourceRelation(
    $input: AddAccountToDataSourceRelationInput
  ) {
    addAccountToDataSourceRelation(input: $input) {
      response
    }
  }
`;

export const removeAccountToDataSourceRelationMutation = gql`
  mutation removeAccountToDataSourceRelationMutation(
    $input: RemoveAccountToDataSourceRelationInput
  ) {
    removeAccountToDataSourceRelation(input: $input) {
      response
    }
  }
`;

export const updateDataSourceMutation = gql`
  mutation UpdateDataSourceMutation($input: UpdateDataSourceInput!) {
    updateDataSource(input: $input) {
      response
    }
  }
`;

export const customColumnSetsQuery = gql`
  query QueryCustomColumnSets($customColumnSetsInput: CustomColumnSetsInput) {
    customColumnSets(input: $customColumnSetsInput) {
      _id
      name
      columns
      savedTo
    }
  }
`;

export const customFieldSetsQuery = gql`
  query QueryCustomFieldSets($customFieldSetsInput: CustomFieldSetsInput) {
    customFieldSets(input: $customFieldSetsInput) {
      _id
      name
      fields {
        name
        string
        leftYAxisMetrics {
          name
          string
        }
        rightYAxisMetrics {
          name
          string
        }
      }
      savedTo
    }
  }
`;

export const addCustomColumnSetsMutation = gql`
  mutation addCustomColumnSetMutation($input: AddCustomColumnSetInput!) {
    addCustomColumnSet(input: $input) {
      _id
      name
      columns
    }
  }
`;

export const addCustomFieldSetsMutation = gql`
  mutation addCustomFieldSetMutation($input: AddCustomFieldSetInput!) {
    addCustomFieldSet(input: $input) {
      response
    }
  }
`;

export const deleteCustomColumnSetsMutation = gql`
  mutation deleteCustomColumnSetMutation($input: DeleteCustomColumnSetInput!) {
    deleteCustomColumnSet(input: $input) {
      response
    }
  }
`;

export const deleteCustomFieldSetsMutation = gql`
  mutation deleteCustomFieldSetMutation($input: DeleteCustomFieldSetInput!) {
    deleteCustomFieldSet(input: $input) {
      response
    }
  }
`;

export const createLabelMutation = gql`
  mutation CreateLabelMutation($input: CreateLabelInput!) {
    createLabel(input: $input) {
      response
      label {
        _id
        accountId
        name
        color
      }
    }
  }
`;

export const updateLabelMutation = gql`
  mutation UpdateLabelMutation($input: UpdateLabelInput!) {
    updateLabel(input: $input) {
      response
      label {
        _id
        accountId
        name
        color
      }
    }
  }
`;
