import React, { useContext, useEffect, useState } from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

import { DataContext } from '../../../../../context/DataContext';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict,
  colorPalette
} from './../dictionary';

const desktopOS = [
  {
    label: 'Windows',
    value: 72.72
  },
  {
    label: 'OS X',
    value: 16.38
  },
  {
    label: 'Linux',
    value: 3.83
  },
  {
    label: 'Chrome OS',
    value: 2.42
  },
  {
    label: 'Other',
    value: 4.65
  }
];

const mobileOS = [
  {
    label: 'Android',
    value: 70.48
  },
  {
    label: 'iOS',
    value: 28.8
  },
  {
    label: 'Other',
    value: 0.71
  }
];

const platforms = [
  {
    label: 'Mobile',
    value: 59.12
  },
  {
    label: 'Desktop',
    value: 40.88
  }
];

function PieChartWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const key = widget.metrics?.[0] || 'cost';
  const {
    listOfData,
    arrayOfData,

    listOfFilteredDataSourceRows
  } = useContext(DataContext);

  // console.log('debug99>arrayOfData: ', arrayOfData);

  // console.log('PieChartWidget>listOfData: ', listOfData);

  const [seriesData, setSeriesData] = useState([]);

  const valueFormatter = (item) =>
    `${key}: ${(
      Math.round((item.value + Number.EPSILON) * 100) / 100
    ).toLocaleString()} ( ${item.share}% )`;

  useEffect(() => {
    let widgetGroupByKey = '';

    switch (widget.groupBy[0]) {
      case 'documentType':
        widgetGroupByKey = 'documentType';
        break;
      case 'name':
        widgetGroupByKey = 'name';
        break;
    }
    const testGroupBy = Object.groupBy(
      listOfFilteredDataSourceRows,
      (row) => row[widgetGroupByKey]
    );

    // console.log('PieChartWidget>testGroupBy: ', testGroupBy);

    const groupByKeys = Object.keys(testGroupBy);

    const newSeriesData = [];
    for (let groupByKey of groupByKeys) {
      let groupByValue = 0;

      for (let row of testGroupBy[groupByKey]) {
        groupByValue += row[key];
      }
      let seriesData = {
        // label: documentTypeToNameDict[groupByKey],
        value: groupByValue
      };

      switch (widgetGroupByKey) {
        case 'documentType':
          seriesData.label =
            documentTypeToShortNameDict?.[groupByKey]?.slice(0, 15) +
            (documentTypeToShortNameDict?.[groupByKey]?.length > 15
              ? '...'
              : '');
          break;

        case 'name':
          seriesData.label =
            groupByKey?.slice(0, 20) + (groupByKey?.length > 20 ? '...' : '');
          break;
      }
      newSeriesData.push(seriesData);
    }

    let totalValue = 0;
    for (let row of newSeriesData) {
      totalValue += row.value || null;
    }

    for (let row of newSeriesData) {
      row.share = Math.round((row.value / totalValue) * 100);
      row.value = Math.round(row.value);
    }
    console.log('totalValue: ', totalValue);
    setSeriesData(newSeriesData);
  }, [listOfData, listOfFilteredDataSourceRows, widget]);

  const size = {
    // width: 400,
    // height: 200
    width: widget.style.width.split('px')[0],
    height: widget.style.height.split('px')[0] * 0.9
  };

  // console.log('PieChartWidget>widget: ', widget);
  const data = {
    data: seriesData,
    valueFormatter
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
        // position: 'relative',
        // width: widget.style.width.split('px')[0] * 0.9,
        // height: widget.style.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {/* <div
        style={{
          borderRadius: '0px 0px 5px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px'
        }}
      > */}
        {widget.title}
        {/* </div> */}
      </div>
      <PieChart
        colors={colorPalette} // Use palette
        series={[
          {
            arcLabel: (item) => `${item.share}%`,
            arcLabelMinAngle: 35,
            arcLabelRadius: '60%',
            ...data
          }
        ]}
        sx={{
          [`& .${pieArcLabelClasses.root}`]: {
            fontWeight: 'bold'
          }
        }}
        slotProps={{
          legend: {
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            itemMarkWidth: 10,
            itemMarkHeight: 10,
            padding: 0,
            labelStyle: {
              fontSize: 12
              //fill: 'blue'
            }
          }
        }}
        {...size}
      />
    </div>
  );
}

export default React.memo(PieChartWidget);
