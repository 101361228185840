import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';

import formatDateYYYYMMDD from './../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import { AuthContext } from './AuthContext';
import { DataContext } from './DataContext';
import { AccountContext } from './AccountContext';

import getFastViewDataStartDate from './../components/tableV4/roitable_functions/getFastViewDataStartDate';

export const InterfaceContext = React.createContext();

// #################### imports regarding date range #################################
const Moment = require('moment');
const MomentRange = require('moment-range');

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const InterfaceProvider = (props) => {
  const { managerAccounts, setManagerAccounts } = useContext(AccountContext);
  const { user } = useContext(AuthContext);
  const { listOfData } = useContext(DataContext);

  const [clientId, setClientId] = useState();

  useEffect(() => {
    setClientId(user?._id + '_' + uuidv4());
  }, [user?._id]);

  const [dataLevel, setDataLevel] = useState('campaign');
  const [exportMode, setExportMode] = useState(false);

  const [resetSelectedAccountOptions, setResetSelectedAccountOptions] =
    useState(0);

  const [openRows, setOpenRows] = useState([]);
  const [isOpenRowsOutdated, setIsOpenRowsOutdated] = useState(false);
  const [isTableShifted, setIsTableShifted] = useState(false);

  const [isTypeItemDraggable, setIsTypeItemDraggable] = useState(true);

  const [isMovingItemId, setIsMovingItemId] = useState(null);

  const [draggingItem, setDraggingItem] = useState(null);

  const [selectedUserTimeboxer, setSelectedUserTimeboxer] = useState();

  const [datePreset, setDatePreset] = useState('');

  const [rightClickMenuAnchorRef, setRightClickMenuAnchorRef] = useState(null);

  const [rightClickMenuOpen, setRightClickMenuOpen] = useState(false);

  const [rightClickMenuToggledItem, setRightClickMenuToggledItem] =
    useState(null);

  const [editSetFilterMenuItem, setEditSetFilterMenuItem] = useState({});

  const [
    rightClickMenuContentComponentType,
    setRightClickMenuContentComponentType
  ] = useState('');

  const [rightClickMenuToggledItemKey, setRightClickMenuToggledItemKey] =
    useState('');

  const [displayColumnsAsPercent, setDisplayColumnsAsPercent] = useState(
    JSON.parse(localStorage.getItem('displayColumnsAsPercent')) || []
  );
  const updateDisplayColumnsAsPercent = (value) => {
    const copyOfDisplayColumnsAsPercent = [...displayColumnsAsPercent];

    if (copyOfDisplayColumnsAsPercent.includes(value)) {
      const index = copyOfDisplayColumnsAsPercent.indexOf(value);

      copyOfDisplayColumnsAsPercent.splice(index, 1);
    } else {
      copyOfDisplayColumnsAsPercent.push(value);
    }
    setDisplayColumnsAsPercent(copyOfDisplayColumnsAsPercent);
    localStorage.setItem(
      'displayColumnsAsPercent',
      JSON.stringify(copyOfDisplayColumnsAsPercent)
    );
  };

  const [displayColumnsAsBarSparkline, setDisplayColumnsAsBarSparkline] =
    useState(
      JSON.parse(localStorage.getItem('displayColumnsAsBarSparkline')) || []
    );
  const updateDisplayColumnsAsBarSparkline = (value) => {
    const copyOfDisplayColumnsAsBarSparkline = [
      ...displayColumnsAsBarSparkline
    ];

    if (copyOfDisplayColumnsAsBarSparkline.includes(value)) {
      const index = copyOfDisplayColumnsAsBarSparkline.indexOf(value);

      copyOfDisplayColumnsAsBarSparkline.splice(index, 1);
    } else {
      copyOfDisplayColumnsAsBarSparkline.push(value);
    }
    setDisplayColumnsAsBarSparkline(copyOfDisplayColumnsAsBarSparkline);
    localStorage.setItem(
      'displayColumnsAsBarSparkline',
      JSON.stringify(copyOfDisplayColumnsAsBarSparkline)
    );
  };

  const [copiedDocumentIds, setCopiedDocumentIds] = useState([]);
  const [copiedDocumentsAccountId, setCopiedDocumentsAccountId] = useState([]);

  // const [typeItemTemplateMenuOpen, setTypeItemTemplateMenuOpen] =
  //   useState(false);

  // ######################### date picker ########################################

  const getDates = (startDate, endDate) => {
    let dates = [];

    //to avoid modifying the original date
    const theDate = new Date(formatDateYYYYMMDD(startDate));
    const theEndDate = new Date(formatDateYYYYMMDD(endDate));
    // theDate.setHours(5);
    // theEndDate.setHours(5);
    while (theDate <= theEndDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }

    // if(dates[dates.length-1] !== theEndDate){
    //   dates.push(theEndDate);
    // }

    dates = dates.map((date) => formatDateYYYYMMDD(date));
    return dates;
  };
  var todaysDate = new Date();
  var firstDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth(),
    1
  );
  firstDayOfMonth.setHours(5);

  var lastDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth() + 1,
    0
  );
  lastDayOfMonth.setHours(5);

  const default_date_start_first_date_as_string = firstDayOfMonth
    .toISOString()
    .split('T')[0];

  const default_date_stop_last_date_of_month_as_string = lastDayOfMonth
    .toISOString()
    .split('T')[0];
  const [selectedDateStart, setSelectedDateStart] = React.useState(
    ''
    // default_date_start_first_date_as_string
  );

  // const handleDateStartChange = (start_date) => {
  //   setSelectedDateStart(start_date);
  // };

  const [selectedDateStop, setSelectedDateStop] = React.useState(
    // default_date_stop.toISOString().split('T')[0]
    ''
    // default_date_stop_last_date_of_month_as_string
    // '2020-05-09'
  );

  const [selectedDateStartTimebox, setSelectedDateStartTimebox] =
    React.useState(
      formatDateYYYYMMDD(new Date())
      // default_date_start_first_date_as_string
    );

  // const handleDateStartChange = (start_date) => {
  //   setSelectedDateStart(start_date);
  // };

  const [selectedDateStopTimebox, setSelectedDateStopTimebox] = React.useState(
    // default_date_stop.toISOString().split('T')[0]
    formatDateYYYYMMDD(new Date())
    // default_date_stop_last_date_of_month_as_string
    // '2020-05-09'
  );

  const [selectedDateStartTimeboxReport, setSelectedDateStartTimeboxReport] =
    useState(new Date(selectedDateStartTimebox));

  const [selectedDateStopTimeboxReport, setSelectedDateStopTimeboxReport] =
    useState(new Date(selectedDateStartTimebox));
  // const handleDateStopChange = (stop_date) => {
  //   setSelectedDateStop(stop_date);
  // };

  useEffect(() => {
    setSelectedDateStartTimeboxReport(new Date(selectedDateStartTimebox));
    setSelectedDateStopTimeboxReport(new Date(selectedDateStopTimebox));
  }, [selectedDateStartTimebox, selectedDateStopTimebox]);

  const [range, setRange] = useState([]);

  const [range2, setRange2] = useState([]);

  useEffect(() => {
    const moment = MomentRange.extendMoment(Moment);
    const start = moment(selectedDateStart, 'YYYY-MM-DD');
    const end = moment(selectedDateStop, 'YYYY-MM-DD');
    const range = moment.range(start, end);
    setRange(range);
  }, [selectedDateStart, selectedDateStop]);

  const [timeboxRange, setTimeboxRange] = useState([]);
  useEffect(() => {
    const simpleRange = getDates(
      selectedDateStartTimebox,
      selectedDateStopTimebox
    );

    setTimeboxRange(simpleRange);
  }, [selectedDateStartTimebox, selectedDateStopTimebox]);

  useEffect(() => {
    if (selectedDateStart && selectedDateStop) {
      const simpleRange = getDates(selectedDateStart, selectedDateStop);

      setRange2(simpleRange);
    }
  }, [selectedDateStart, selectedDateStop]);
  // console.log('range2: ', range2);

  // #################################################

  const [hideSelected, setHideSelected] = useState([]);

  useEffect(() => {
    const localStorageHideSelected = localStorage.getItem('hideSelected');

    if (localStorageHideSelected) {
      setHideSelected(JSON.parse(localStorage.getItem('hideSelected')));
    } else {
      const newSelected = [
        'totalActionsDocument',
        'globalTasks'
        // 'accountName',
        // 'path',
        // 'task',
        // 'subTask',
        // 'cm360',
        // 'dv360',
        // 'googleAds',
        // 'facebookAds',
        // 'snapchatAds',
        // 'folder',
        // 'manualDataEntry',
        // 'customForm',
        // 'personal'
      ];
      localStorage.setItem('hideSelected', JSON.stringify(newSelected));
      setHideSelected(newSelected);
    }
  }, []);

  //info fields
  const [fields, setFields] = useState([]);
  const [overrideDefaultFields, setOverrideDefaultFields] = useState(false);

  const [refetchFieldSetsTrigger, setRefetchFieldSetTrigger] = useState(0);
  const refetchFieldSets = () => {
    setRefetchFieldSetTrigger(refetchFieldSetsTrigger + 1);
  };

  // #############################################################

  const [daysToGraph, updateDaysToGraph] = useState(15);

  const setDaysToGraph = (value) => {
    localStorage.setItem('daysToGraph', JSON.stringify(value));
    updateDaysToGraph(value);
  };
  useEffect(() => {
    const localStorageDaysToGraph = localStorage.getItem('daysToGraph');

    if (localStorageDaysToGraph) {
      setDaysToGraph(JSON.parse(localStorageDaysToGraph));
    } else {
      localStorage.setItem('daysToGraph', JSON.stringify(15));
      setDaysToGraph(15);
    }
  }, []);

  // ######################### Advanced mode #####################

  const [advancedMode, updateAdvancedMode] = useState(false);

  const setAdvancedMode = (value) => {
    localStorage.setItem('advancedMode', JSON.stringify(value));
    updateAdvancedMode(value);
  };
  useEffect(() => {
    const localStorageAdvancedMode = localStorage.getItem('advancedMode');

    if (localStorageAdvancedMode) {
      setAdvancedMode(JSON.parse(localStorageAdvancedMode));
    } else {
      localStorage.setItem('advancedMode', JSON.stringify(false));
      setAdvancedMode(false);
    }
  }, []);

  const [isKPIWarningsOff, updateIsKPIWarningsOff] = useState(
    JSON.parse(localStorage.getItem('isKPIWarningsOff')) || false
  );

  const setIsKPIWarningsOff = (value) => {
    localStorage.setItem('isKPIWarningsOff', JSON.stringify(value));
    updateIsKPIWarningsOff(value);
  };
  useEffect(() => {
    const localStorageIsKPIWarningsOff =
      localStorage.getItem('isKPIWarningsOff');
    const parsedLocalStorageIsKPIWarningsOff = JSON.parse(
      localStorageIsKPIWarningsOff
    );

    if (
      localStorageIsKPIWarningsOff &&
      parsedLocalStorageIsKPIWarningsOff !== isKPIWarningsOff
    ) {
      setIsKPIWarningsOff(parsedLocalStorageIsKPIWarningsOff);
    } else if (!localStorageIsKPIWarningsOff) {
      localStorage.setItem('isKPIWarningsOff', JSON.stringify(false));
      setIsKPIWarningsOff(false);
    }
  }, []);

  const [isInvoiceWarningsOff, updateIsInvoiceWarningsOff] = useState(
    JSON.parse(localStorage.getItem('isInvoiceWarningsOff')) || false
  );

  const setIsInvoiceWarningsOff = (value) => {
    localStorage.setItem('isInvoiceWarningsOff', JSON.stringify(value));
    updateIsInvoiceWarningsOff(value);
  };
  useEffect(() => {
    const localStorageIsInvoiceWarningsOff = localStorage.getItem(
      'isInvoiceWarningsOff'
    );
    const parsedLocalStorageIsInvoiceWarningsOff = JSON.parse(
      localStorageIsInvoiceWarningsOff
    );

    if (
      localStorageIsInvoiceWarningsOff &&
      parsedLocalStorageIsInvoiceWarningsOff !== isInvoiceWarningsOff
    ) {
      setIsInvoiceWarningsOff(parsedLocalStorageIsInvoiceWarningsOff);
    } else if (!localStorageIsInvoiceWarningsOff) {
      localStorage.setItem('isInvoiceWarningsOff', JSON.stringify(false));
      setIsInvoiceWarningsOff(false);
    }
  }, []);

  const [documentIdsToBeDuplicated, setDocumentIdsToBeDuplicated] = useState();

  const [
    accountIdForDocumentsToBeDuplicated,
    setAccountIdForDocumentsToBeDuplicated
  ] = useState();

  // ##################### Demo Mode ########################################

  const [isDemoMode, updateIsDemoMode] = useState(
    JSON.parse(localStorage.getItem('isDemoMode')) || false
  );

  const setIsDemoMode = (value) => {
    localStorage.setItem('isDemoMode', JSON.stringify(value));
    updateIsDemoMode(value);
  };
  useEffect(() => {
    const localStorageIsInvoiceWarningsOff = localStorage.getItem('isDemoMode');
    const parsedLocalStorageIsDemoModeOn = JSON.parse(
      localStorageIsInvoiceWarningsOff
    );

    if (
      localStorageIsInvoiceWarningsOff &&
      localStorageIsInvoiceWarningsOff !== isDemoMode
    ) {
      setIsInvoiceWarningsOff(localStorageIsInvoiceWarningsOff);
    } else if (!localStorageIsInvoiceWarningsOff) {
      localStorage.setItem('isDemoMode', JSON.stringify(false));
      setIsInvoiceWarningsOff(false);
    }
  }, []);

  useEffect(() => {
    if (isDemoMode) {
      setManagerAccounts(
        managerAccounts.filter(
          (managerAccount) =>
            String(managerAccount._id) === '66d61b1e1e0fc1c56a32e7fe'
        )
      );
    }
  }, [managerAccounts, isDemoMode]);

  // ################### shift ######################

  const [shiftHeld, setShiftHeld] = useState(false);
  const [altHeld, setAltHeld] = useState(false);

  function downHandler({ key }) {
    if (key === 'Shift') {
      setShiftHeld(true);
    }
    if (key === 'Alt') {
      setAltHeld(true);
    }
    // console.log('key: ', key);
  }

  function upHandler({ key }) {
    if (key === 'Shift') {
      setShiftHeld(false);
    }
    if (key === 'Alt') {
      setAltHeld(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  // console.log('shiftHeld: ', shiftHeld);
  // console.log('altHeld: ', altHeld);

  // ###################### window size change ##########################

  const [windowSize, setWindowSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  // useEffect(() => {
  //   // console.log('size: ', size);
  //   try {
  //     switch (true) {
  //       case windowSize?.[0] > 2000:
  //         document.body.style.zoom = '120%';
  //         break;
  //       case windowSize?.[0] > 1200:
  //         document.body.style.zoom = '70%';
  //         break;

  //       default:
  //         document.body.style.zoom = '100%';
  //     }
  //   } catch (error) {}
  // });

  const [typeItemAnchorRef, setTypeItemAnchorRef] = useState(null);

  const [typeItemOpen, setTypeItemOpen] = useState(false);

  const [typeItemToggledItem, setTypeItemToggledItem] = useState(null);

  const [typeItemTemplateMenuOpen, setTypeItemTemplateMenuOpen] =
    useState(false);

  const [sparklineDateRange, setSparklineDateRange] = useState([]);

  useEffect(() => {
    if (isOpenRowsOutdated) {
      const openRowsIds = openRows.map((obj) => obj._id);
      const latestOpenRows = listOfData
        .filter((obj) => openRowsIds.includes(obj._id))
        .map((item) => {
          const newItem = {
            _id: item._id,
            accountId: item.accountId,
            childOf: []
          };
          for (let i = item.level; i > 0; i--) {
            newItem[`level_${i}_container_id`] =
              item[`level_${i}_container_id`];
            newItem?.childOf?.push(item[`level_${i}_container_id`]);
          }
          newItem[`level_${item.level}_container_id`] = item._id;
          return newItem;
        });
      // console.log('openRows:', openRows);
      setOpenRows(latestOpenRows);
      setIsOpenRowsOutdated(false);
    }
  }, [listOfData]);

  // useEffect(() => {
  //   if (!daysToGraph) {
  //     return;
  //   }
  //   const start = new Date();
  //   start.setDate(start.getDate() - (daysToGraph - 1));
  //   const end = new Date();
  //   setSparklineDateRange(getDates(start, end));
  // }, [range2]);
  const [startDateForLastDaysData, setStartDateForLastDaysData] = useState(
    new Date()
  );

  const [aggregatedDateRange, setAggregatedDateRange] = useState([]);

  useEffect(() => {
    if (daysToGraph) {
      // console.log('debug9911>daysToGraph: ', daysToGraph);
      // console.log(
      //   'debug9911>startDateForLastDaysData: ',
      //   startDateForLastDaysData
      // );

      const newStartDateForLastDaysData = getFastViewDataStartDate(
        selectedDateStart,
        selectedDateStop,
        daysToGraph
      );

      // console.log(
      //   'debug9911>newStartDateForLastDaysData: ',
      //   newStartDateForLastDaysData
      // );
      setStartDateForLastDaysData(newStartDateForLastDaysData);

      // console.log('daysToGraph: ', daysToGraph);

      const newAggregatedDateRange = range2.filter(
        (date) =>
          formatDateYYYYMMDD(date) >=
            formatDateYYYYMMDD(newStartDateForLastDaysData) &&
          formatDateYYYYMMDD(date) <= formatDateYYYYMMDD(new Date())
      );

      setAggregatedDateRange(newAggregatedDateRange);
      // console.log('debug9911>newAggregatedDateRange: ', newAggregatedDateRange);
      // console.log('debug9911>range2: ', range2);
    }
  }, [range2, daysToGraph, selectedDateStart, selectedDateStop]);
  // console.log('range2: ', range2);

  // console.log('debug9911>daysToGraph: ', daysToGraph);
  // console.log('startDateForLastDaysData: ', startDateForLastDaysData);
  return (
    <InterfaceContext.Provider
      value={{
        selectedDateStart,
        setSelectedDateStart,
        selectedDateStop,
        setSelectedDateStop,
        selectedDateStartTimebox,
        setSelectedDateStartTimebox,
        selectedDateStopTimebox,
        setSelectedDateStopTimebox,
        selectedDateStartTimeboxReport,
        setSelectedDateStartTimeboxReport,
        selectedDateStopTimeboxReport,
        setSelectedDateStopTimeboxReport,
        range,
        setRange,
        range2,
        setRange2,
        hideSelected,
        setHideSelected,
        fields,
        setFields,
        overrideDefaultFields,
        setOverrideDefaultFields,
        refetchFieldSets,
        refetchFieldSetsTrigger,
        advancedMode,
        setAdvancedMode,
        isKPIWarningsOff,
        setIsKPIWarningsOff,
        isInvoiceWarningsOff,
        setIsInvoiceWarningsOff,
        exportMode,
        setExportMode,
        rightClickMenuAnchorRef,
        setRightClickMenuAnchorRef,
        rightClickMenuOpen,
        setRightClickMenuOpen,
        rightClickMenuToggledItem,
        setRightClickMenuToggledItem,
        rightClickMenuContentComponentType,
        setRightClickMenuContentComponentType,
        rightClickMenuToggledItemKey,
        setRightClickMenuToggledItemKey,
        displayColumnsAsPercent,
        setDisplayColumnsAsPercent: updateDisplayColumnsAsPercent,
        displayColumnsAsBarSparkline,
        setDisplayColumnsAsBarSparkline: updateDisplayColumnsAsBarSparkline,
        copiedDocumentIds,
        setCopiedDocumentIds,
        copiedDocumentsAccountId,
        setCopiedDocumentsAccountId,
        clientId,
        dataLevel,
        setDataLevel,
        datePreset,
        setDatePreset,
        documentIdsToBeDuplicated,
        setDocumentIdsToBeDuplicated,
        accountIdForDocumentsToBeDuplicated,
        setAccountIdForDocumentsToBeDuplicated,
        isTableShifted,
        setIsTableShifted,
        shiftHeld,
        altHeld,
        selectedUserTimeboxer,
        setSelectedUserTimeboxer,
        timeboxRange,
        typeItemAnchorRef,
        setTypeItemAnchorRef,
        typeItemOpen,
        setTypeItemOpen,
        typeItemToggledItem,
        setTypeItemToggledItem,
        typeItemTemplateMenuOpen,
        setTypeItemTemplateMenuOpen,
        daysToGraph,
        setDaysToGraph,
        sparklineDateRange,
        setSparklineDateRange,
        openRows,
        setOpenRows,
        isOpenRowsOutdated,
        setIsOpenRowsOutdated,
        isDemoMode,
        setIsDemoMode,
        editSetFilterMenuItem,
        setEditSetFilterMenuItem,
        isTypeItemDraggable,
        setIsTypeItemDraggable,
        isMovingItemId,
        setIsMovingItemId,
        draggingItem,
        setDraggingItem,
        resetSelectedAccountOptions,
        setResetSelectedAccountOptions,
        startDateForLastDaysData,
        aggregatedDateRange,
        windowSize,
        setWindowSize
        // typeItemTemplateMenuOpen,
        // setTypeItemTemplateMenuOpen
      }}
    >
      {props.children}
    </InterfaceContext.Provider>
  );
};
