import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import { useTheme } from '@mui/material';

function WidgetWrapper({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  setMouseDownWidgetIndex,
  // editMode,
  dropOnWidget,
  dragOver,
  dragStart,
  dragEnd,
  isDraggingWidget,
  setIsDraggingWidget,
  onMouseDownHandler,
  onMouseUpHandler,
  children
  // widgetType
}) {
  const theme = useTheme();

  const { setContextMenuAnchorPosition, setWidgetIdThatTriggeredContextMenu } =
    useContext(ReportBuilderContext);

  const [isDraggingThisWidget, setIsDraggingThisWidget] = useState(false);
  const [edit, toggleEdit] = useState(false);
  const [isWidgetDescriptionMenuOpen, setIsWidgetDescriptionMenuOpen] =
    useState(false);

  const [textInput, setTextInput] = useState('');

  const [valueIsEdited, setValueIsEdited] = useState(false);

  const [originalWidgetCopy, setOriginalWidgetCopy] = useState({ ...widget });

  useEffect(() => {
    if (textInput) {
      setValueIsEdited(true);
    }
    setTextInput(widget.value);
  }, [widget.value]);
  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  const onContextHandler = (e, widget) => {
    // console.log('onContextHandler>e: ', e);

    e.preventDefault();
    console.log('widget._id: ', widget._id);
    setWidgetIdThatTriggeredContextMenu(widget._id);
    setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
  };

  // const onClickHandler = (e) => {
  //   var isRightMB;
  //   var isLeftMB;
  //   e = e || window.event;

  //   if ('which' in e) {
  //     // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
  //     isRightMB = e.which == 3;
  //     isLeftMB = e.which == 1;
  //   } else if ('button' in e) {
  //     // IE, Opera
  //     isRightMB = e.button == 2;
  //     isLeftMB = e.button == 1;
  //   }
  //   console.log('isRightMB: ', isRightMB);
  // };

  const onSaveHandler = () => {
    updateWidget(
      { ...widget, value: textInput || originalWidgetCopy.value },
      widgetIndex
    );
    // setTextInput('');

    if (!textInput) {
      setValueIsEdited(false);
    }
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setTextInput(widget.value);
      toggleEdit(false);
      return;
    }

    if (event.key === 'Enter') {
      console.log('do validate');
      updateWidget(
        { ...widget, value: textInput || originalWidgetCopy.value },
        widgetIndex
      );
      // setTextInput('');

      if (!textInput) {
        setValueIsEdited(false);
      }

      toggleEdit(false);
      return;
    }
  };

  const numUpper = (textInput?.match(/[A-Z]/g) || []).length;
  const numLarge = (textInput?.match(/[MmWw]/g) || []).length;
  const theRest = textInput?.length - numUpper;
  console.log('numUpper: ', numUpper);
  let cursor = 'pointer';

  // switch (true) {
  //   case isDraggingWidget:
  //     cursor = 'grabbing';
  //     break;
  //   // case !isDraggingWidget:
  //   //   cursor = 'grab';
  //   //   break;
  //   case !editMode && widget.type === 'TEXT':
  //     cursor = 'text';
  //     break;
  //   case editMode && widget.type === 'TEXT':
  //     cursor = 'grab';
  //     break;
  //   case editMode:
  //     cursor = 'grab';
  //     break;
  //   default:
  //     cursor = 'not-allowed';
  // }

  return (
    <div
      id={widget?._id}
      onContextMenu={(e) => onContextHandler(e, widget)}
      draggable
      style={{
        border:
          widget.isShowingCard &&
          ![
            // 'MANAGER_ACCOUNT_LOGO_WIDGET', 'DATE_PICKER_WIDGET'
          ].includes(widget.type) &&
          (isDraggingThisWidget
            ? '1px solid grey'
            : '1px solid rgba(150,150,150,0.15)'),
        background:
          widget.isShowingCard &&
          ![
            // 'MANAGER_ACCOUNT_LOGO_WIDGET', 'DATE_PICKER_WIDGET'
          ].includes(widget.type) &&
          (theme.palette.mode === 'dark'
            ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
            : 'rgba(255,255,255,1)'),
        // height: '100%',
        // width: '100%',
        // padding: '4px',
        // color: 'black',
        // background: 'white',
        // border: '1px solid lightgrey',
        position: 'absolute',

        // left: widget.offsetX + 'px',
        // top: widget.offsetY + 'px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        // margin: '0px 0.5px',
        transform: 'translate(0, 0)',
        // overflow: 'hidden',
        lineBreak: 'anywhere',

        // boxShadow:
        //   !isDraggingThisWidget && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
        ...widget.style,
        boxShadow: widget.isShowingCard
          ? 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
          : 'unset'
      }}
      onDragOver={(e) => dragOver(e, widget, widgetIndex)}
      // draggable={editMode}
      onDragStart={(e) => {
        dragStart(e, widget, 'field_widgets', widgetIndex);
        setIsDraggingThisWidget(true);
      }}
      onDragEnd={(e) => {
        dragEnd(e, widget, widgetIndex);
        setIsDraggingThisWidget(false);
      }}
      onMouseUpHandler={onMouseUpHandler}
      onClick={() => {
        toggleEdit(true);
        // if (editMode || widget.type === 'TEXT') {
        //   toggleEdit(true);

        //   if (!editMode && !valueIsEdited) {
        //     setTextInput('');
        //   }
        // }
      }}
      onDrop={(e) => dropOnWidget(e, widget, widgetIndex)}
      onBlur={(e) => onSaveHandler(e)}
    >
      {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
      {/* {edit ? (
        <textarea
          autoFocus
          style={{
            padding: '10px',
            fontFamily: 'Roboto, Raleway, sans-serif',
            lineHeight: '1.5',
            fontSize: '12px',
            letterSpacing: '0.2px',
            color: 'black',
            // width: textInput.length * 1 + 'ch',
            // width:
            //   numUpper * 0.35 + numLarge * 1 + textInput.length * 0.925 + 'ch',
            // minWidth: '1ch',
            width: widget.style.width,
            height: widget.style.height,
            outline: 'none',
            border: 'none'
            // fontSize: '11.5px'
          }}
          value={textInput}
          onChange={onChangeHandler}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Tooltip
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                maxWidth: '200px'
                // padding: '2px'
              }}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>
                {widget.type === 'FREETEXT'
                  ? '* Free text *'
                  : '* Static text *'}
              </span>

              <span
                style={{
                  maxWidth: '190px',
                  whiteSpace: 'pre-line'
                  // paddingRight: '10px'
                  // padding: '5px'
                }}
              >
                {widget.description}
              </span>
            </div>
          }
        >
          <span
            style={{
              padding: '10px',
              // width: '100%',
              // lineBreak: 'anywhere',
              width: widget.style.width,
              height: widget.style.height,
              maxWidth: widget.style.width,
              maxHeight: widget.style.height,
              //   background: 'red',
              // top: timebox?.duration <= 5 && '-5px',
              // position: timebox?.duration <= 5 && 'relative',
              // lineHeight: timebox?.duration <= 5 && '3px',
              // padding:
              //   timebox?.duration <= 10 ? '3px 9px 0px 9px' : '9px 9px 3px 9px',
              whiteSpace: 'pre-wrap',
              overflowY: 'scroll'
            }}
            onMouseDown={() => setIsDraggingWidget(true)}
            onMouseUp={() => setIsDraggingWidget(false)}
          >
          
            {widget.value}
          </span>
        </Tooltip>
      )} */}
      {children}
      {/* {isWidgetDescriptionMenuOpen && (
        <WidgetDescriptionMenu
          isWidgetDescriptionMenuOpen={isWidgetDescriptionMenuOpen}
          setIsWidgetDescriptionMenuOpen={setIsWidgetDescriptionMenuOpen}
          // inputRef={inputRef}
          // textInput={textInput}
          // setTextInput={setTextInput}
          widgetIndex={widgetIndex}
          // options={options}
          // setOptions={setOptions}
          // editMode={editMode}
          widget={widget}
          updateWidget={updateWidget}
          toggleEdit={toggleEdit}
        />
      )} */}

      {widget.isResizable && (
        <div
          className={
            theme?.palette?.mode === 'dark'
              ? 'timebox-resize-border-dark'
              : 'timebox-resize-border-light'
          }
          style={{
            width: '15px',
            height: '15px',
            // background: 'green',
            zIndex: 50000,
            // cursor:
            //   widget?.isMutable &&
            //   widget?.type !== 'outlookCalendarEvent' &&
            //   'ns-resize',
            cursor: 'nwse-resize',
            position: 'absolute',
            bottom: '0px',
            right: '0px'
          }}
          onMouseDown={(e) => {
            setMouseDownWidgetIndex(widgetIndex);
            // if (!timebox.isMutable) {
            //   return;
            // }
            //   setOriginalTimebox({ ...timebox });
            e.stopPropagation();
            e.preventDefault();
            // if (timebox.type === 'outlookCalendarEvent' || altHeld) {
            //   return;
            // }
            onMouseDownHandler(e, widgetIndex, 'resize');
          }}
          // onMouseUp={(e) => {
          //   e.stopPropagation();
          //   console.log('debug30>Timebox>timebox._id: ', timebox._id);
          //   onMouseUpHandler(e, 'timebox', timebox._id);
          // }}
          // onMouseMove={updateMousePosition}
        ></div>
      )}
      {widget.isShowingCard && widget?.style?.boxShadow && (
        <div
          style={{
            bottom: '-5px',
            // right: '0px',
            height: '5px',
            position: 'absolute',
            filter: 'opacity(50%)',
            // backgroundColor: stylesStoredInRedux.background.backgroundColor
            //   ? stylesStoredInRedux.background.backgroundColor
            //   : 'rgba(255,255,255,0)',
            // cursor: 'col-resize',
            // backgroundColor: 'red',
            userSelect: 'none',
            width: 'calc(100%)',
            zIndex: '1099',
            borderRadius: '0px 0px 5px 5px',
            overflow: 'hidden'
          }}
        >
          <div
            style={{
              filter: 'opacity(50%)',
              top: '45px',
              // right: '0px',
              height: '5px',
              // position: 'absolute',
              background:
                '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
              // cursor: 'col-resize',
              // backgroundColor: 'red',
              userSelect: 'none',
              width: 'calc(100%)',
              zIndex: '1100'
            }}
          ></div>
        </div>
      )}
    </div>
  );
}

export default WidgetWrapper;
