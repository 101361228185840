import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

export default function SliderSizes({ widget, styleKey, min, max, step }) {
  const [value, setValue] = React.useState([20, 37]);

  const handleChange = (event, newValue) => {
    //   setValue(newValue);
    var copyOfWidgets = [...widgets];
    var index = copyOfWidgets.findIndex(
      (widget_) => widget_._id === widgetIdThatTriggeredContextMenu
    );
    updateWidget(
      { ...widget, style: { ...widget.style, opacity: newValue } },
      index
    );
  };

  const {
    widgets,
    setWidgets,
    widgetIdThatTriggeredContextMenu,
    updateWidget
  } = useContext(ReportBuilderContext);

  return (
    <Box sx={{ width: 170 }}>
      <Slider
        // size="small"
        // defaultValue={widget.style[styleKey]}
        // aria-label="Small"
        // valueLabelDisplay="auto"

        step={step}
        marks
        min={min}
        max={max}
        getAriaLabel={() => 'Temperature range'}
        value={widget.style?.[styleKey]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        // getAriaValueText={valuetext}
      />
    </Box>
  );
}
