import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { AccountContext } from './../../../context/AccountContext';
import { DialogContext } from './../../../context/DialogContext';

import CloseIcon from '@mui/icons-material/Close';

import EditDialogContent from './EditDialogContent';

import { useSnackbar } from 'notistack';
// import { AccountContext } from '../../../context/AccountContext';
import { MutationContext } from '../../../context/MutationContext';

import formatDateYYYYMMDD from './../roitable_functions/formatDateYYYYMMDD';

import { FilterContext } from '../../../context/FilterContext';

import Spinner from './../../layout/SpinnerSmall';

import { useTheme } from '@emotion/react';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const EditModal = () => {
  const theme = useTheme();
  const [awaitingResponse, toggleAwaitingResponse] = useState(false);
  const { currentManagerAccount } = useContext(AccountContext);

  const { checkedBoxes } = useContext(FilterContext);

  const [scrollTop, setScrollTop] = useState(0);
  const { editDialogOpen: open, setEditDialogOpen: setOpen } =
    useContext(DialogContext);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // #########################################################

  // const { currentManagerAccount } = useContext(AccountContext);

  const [saveTemplateMenuOpen, setSaveTemplateMenuOpen] = useState(false);

  const checkedAccounts = [];
  const checkedTypes = [];
  const checkedDocumentTypes = [];
  const dataSourceAccountIds = [];
  let disableEdit = false;
  for (let checkedbox of checkedBoxes) {
    if (!checkedAccounts.includes(checkedbox.accountId)) {
      checkedAccounts.push(checkedbox.accountId);
    }
    if (!checkedDocumentTypes.includes(checkedbox?.documentType)) {
      checkedDocumentTypes.push(checkedbox.documentType);
    }
    if (!checkedTypes.includes(checkedbox.type)) {
      checkedTypes.push(checkedbox.type);
    }

    switch (checkedbox.type) {
      case 'facebook_ads_campaign':
      case 'google_ads_campaign':
      case 'dv360_ads_campaign':
      case 'cm360_ads_campaign':
        if (
          !dataSourceAccountIds.includes(
            `${checkedbox?.documentType}_${checkedbox.dataSourceAccountId}`
          )
        ) {
          dataSourceAccountIds.push(
            `${checkedbox?.documentType}_${checkedbox.dataSourceAccountId}`
          );
        }

        break;
      case 'folder':
      case 'manualDataEntry':
        if (!dataSourceAccountIds.includes(`${checkedbox?.documentType}`)) {
          dataSourceAccountIds.push(`${checkedbox?.documentType}`);
        }
        break;

      default:
        disableEdit = true;
    }
  }
  const accountId = checkedAccounts.length === 1 ? checkedAccounts[0] : null;

  const { enqueueSnackbar } = useSnackbar();

  // const [updateDocument, { called }] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);

  // ############ calc first and last day of month:

  var todaysDate = new Date();
  var firstDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth(),
    1
  );
  // firstDayOfMonth.setHours(5);
  firstDayOfMonth = formatDateYYYYMMDD(firstDayOfMonth);

  var lastDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth() + 1,
    0
  );
  // lastDayOfMonth.setHours(5);

  lastDayOfMonth = formatDateYYYYMMDD(lastDayOfMonth);

  const [templateDocument, setTemplateDocument] = useState(null);

  const [document, setDocument] = useState({
    status: 'DRAFT',
    invoiceStatus: 'UNDEFINED',
    startDate: firstDayOfMonth,
    endDate: lastDayOfMonth,
    members: []
  });

  // const [status, setStatus] = useState('DRAFT');
  // const [startDate, setStartDate] = useState(firstDayOfMonth);
  // const [endDate, setEndDate] = useState(lastDayOfMonth);

  const [fields, setFields] = useState([]);

  // ############### check if all documents are of same data source ####################

  const [isActionBulkEditAllowed, setIsActionBulkEditAllowed] = useState(false);
  useEffect(() => {
    const arrayOfNexusAccountIds = [];
    const arrayOfTypes = [];

    if (open) {
      for (let checkedBox of checkedBoxes) {
        if (checkedBox.isSetChild) {
          return;
        }
        if (!arrayOfNexusAccountIds.includes(checkedBox.accountId)) {
          arrayOfNexusAccountIds.push(checkedBox.accountId);
        }
        if (!arrayOfTypes.includes(checkedBox.documentType)) {
          arrayOfTypes.push(checkedBox.documentType);
        }
      }

      console.log('debug9431>arrayOfNexusAccountIds: ', arrayOfNexusAccountIds);
      console.log('debug9431>arrayOfTypes: ', arrayOfTypes);

      if (arrayOfNexusAccountIds.length === 1 && arrayOfTypes.length === 1) {
        const arrayOfDataSourceAccountIds = [];

        for (let checkedBox of checkedBoxes) {
          const item = arrayOfTypes[0];

          switch (arrayOfTypes[0]) {
            case 'facebookAds':
            case 'snapchatAds':
            case 'pinterestAds':
            case 'linkedInAds':
              arrayOfDataSourceAccountIds.push(item.accountId);
              break;

            case 'tikTokAds':
              // advertiserId
              // serviceType: String
              arrayOfDataSourceAccountIds.push(item.advertiserId);
              break;

            case 'readpeak':
              arrayOfDataSourceAccountIds.push(item.clientId);
              break;

            case 'strossle':
              // groupId
              arrayOfDataSourceAccountIds.push(item.groupId);
              break;

            case 'amazonDsp':
              // advertiserId
              arrayOfDataSourceAccountIds.push(item.advertiserId);
              break;

            case 'taboolaAds':
              // accountId
              // accountNumber

              arrayOfDataSourceAccountIds.push(item.accountId);
              break;

            case 'microsoftAdvertising':
              // accountId: String,
              // accountNumber: String,

              arrayOfDataSourceAccountIds.push(item.accountId);

              break;

            case 'microsoftInvest':
              // accountId: String,
              // accountNumber: String,

              arrayOfDataSourceAccountIds.push(item.advertiserId);

              break;
            case 'appleSearchAds':
              // orgId: String,
              arrayOfDataSourceAccountIds.push(item.orgId);

              break;

            case 'googleAds':
              // customerId: String,
              arrayOfDataSourceAccountIds.push(item.customerId);
              break;
            case 'cm360':
              // accountId: String,
              // advertiserId: String,

              arrayOfDataSourceAccountIds.push(item.advertiserId);
              break;
            case 'dv360':
              // partnerId: String,
              // advertiserId: String,
              arrayOfDataSourceAccountIds.push(item.advertiserId);
              break;
            case 'adform':
              // clientId: String,
              arrayOfDataSourceAccountIds.push(item.clientId);
              break;

            default:
          }
        }

        function onlyUnique(value, index, array) {
          return array.indexOf(value) === index;
        }

        const arrayOfDataSourceUniqueAccountIds =
          arrayOfDataSourceAccountIds.filter(onlyUnique);

        if (arrayOfDataSourceUniqueAccountIds.length !== 1) {
          return;
        }

        setIsActionBulkEditAllowed(true);
      }
    }

    // dataSourcePartnerId: nexusDocument.dataSourcePartnerId,
    // dataSourceAccountId: nexusDocument.dataSourceAccountId,
    // dataSourceAccountNumber: nexusDocument.dataSourceAccountNumber,
    // dataSourceAdvertiserId: nexusDocument.dataSourceAdvertiserId,
    // dataSourceProfileId: nexusDocument.dataSourceProfileId,
    // dataSourceProfileAccountId:
    //   nexusDocument.dataSourceProfileAccountId,
    // dataSourceCustomerId: nexusDocument.dataSourceCustomerId,
    // dataSourceClientId: nexusDocument.dataSourceClientId,
    // dataSourceGroupId: nexusDocument.dataSourceGroupId,
    // dataSourceCampaignGroupId:
    //   nexusDocument.dataSourceCampaignGroupId,
    // dataSourceCampaignId: nexusDocument.dataSourceCampaignId,
    // dataSourceInsertionOrderId:
    //   nexusDocument.dataSourceInsertionOrderId,
  }, [checkedBoxes, open]);

  // ###################################################################################

  const handleClose = () => {
    // setEditStatus(false);
    // setEditStartDate(false);
    // setEditEndDate(false);
    setFields([]);

    setDocument({
      status: 'DRAFT',
      invoiceStatus: 'UNDEFINED',
      startDate: firstDayOfMonth,
      endDate: lastDayOfMonth,
      members: []
    });

    // setStatus('DRAFT');

    setOpen(false);
  };

  const onSaveHandler = async () => {
    if (checkedBoxes.length === 0) {
      enqueueSnackbar('No checkboxes checked.', {
        variant: 'error'
      });
      return;
    }

    if (checkedAccounts.length > 1) {
      enqueueSnackbar('You can only bulk edit one account at the time.', {
        variant: 'error'
      });
      return;
    }

    const updateKeys = fields;

    // if (editStatus) {
    //   updateKeys.push('status');
    // }

    // if (editStartDate) {
    //   updateKeys.push('startDate');
    // }

    // if (editEndDate) {
    //   updateKeys.push('endDate');
    // }

    if (updateKeys.length === 0) {
      enqueueSnackbar('No update options selected.', {
        variant: 'error'
      });
      return;
    }

    for (let obj of checkedBoxes) {
      if (
        (![
          'folder',
          'manualDataEntry',
          'customForm',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'microsoft_invest_insertion_order',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group',
          'task'
          // 'taxonomy'
        ].includes(obj?.type) &&
          !obj.isSet) ||
        obj.isSetChild
        // (['googleAds'].includes(obj.documentType) && !obj.isSet)
      ) {
        enqueueSnackbar(
          'Only Folders, Facebook Campaigns, Google Ads campaigns, Campaign Manager 360 campaigns, Manual Data Entries todo-lists and todos support bulk edit atm.',
          {
            variant: 'error'
          }
        );
        return;
      }

      if (
        fields.filter((field) =>
          [
            'allowImpressionsBeforeStartDate',
            'allowImpressionsAfterEndDate'
          ].includes(field)
        ).length &&
        ((![
          // 'folder',
          // 'manualDataEntry',
          // 'customForm',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'microsoft_invest_insertion_order',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
          // 'task'
          // 'taxonomy'
        ].includes(obj?.type) &&
          !obj.isSet) ||
          obj.isSetChild)
        // (['googleAds'].includes(obj.documentType) && !obj.isSet)
      ) {
        console.log('Unsuported document type: ', obj?.type);

        enqueueSnackbar(
          'Only data source activities support bulk edit of allow impressions before start date and allow impressions before end date.',
          {
            variant: 'error'
          }
        );
        return;
      }

      switch (true) {
        case fields.includes('startDate') && fields.includes('endDate'):
          if (document.startDate && document.endDate) {
            if (new Date(document.startDate) > new Date(document.endDate)) {
              enqueueSnackbar(
                'End date must be later than or equal to start date1',
                {
                  variant: 'error'
                }
              );
              return;
            } else {
              break;
            }
          } else {
            enqueueSnackbar('Error, reload page.', {
              variant: 'error'
            });
            return;
          }

        case fields.includes('startDate') &&
          document.startDate &&
          obj.endDate &&
          new Date(document.startDate) > new Date(obj.endDate):
          enqueueSnackbar(
            'Start date must be earlier than or equal to end date',
            {
              variant: 'error'
            }
          );
          return;

        case fields.includes('endDate') &&
          document.endDate &&
          obj.startDate &&
          new Date(document.endDate) < new Date(obj.startDate):
          enqueueSnackbar(
            'End date must be later than or equal to start date',
            {
              variant: 'error'
            }
          );
          return;
      }

      // if (fields.includes('startDate')) {
      //   if (
      //     (fields.includes('endDate') &&
      //       document?.startDate &&
      //       document?.endDate &&
      //       new Date(document?.startDate) > new Date(document?.endDate)) ||
      //     (obj.endDate &&
      //       document.startDate &&
      //       new Date(document?.startDate) > new Date(obj.endDate))
      //   ) {
      //     enqueueSnackbar(
      //       'End date must be later than or equal to start date',
      //       {
      //         variant: 'error'
      //       }
      //     );
      //     return;
      //   }
      // }

      // if (fields.includes('endDate')) {
      //   if (
      //     (fields.includes('startDate') &&
      //       document?.startDate &&
      //       document?.endDate &&
      //       new Date(document?.startDate) > new Date(document?.endDate)) ||
      //     (obj.startDate &&
      //       document?.endDate &&
      //       new Date(document?.endDate) < new Date(obj.startDate))
      //   ) {
      //     enqueueSnackbar(
      //       'End date must be later than or equal to start date',
      //       {
      //         variant: 'error'
      //       }
      //     );
      //     return;
      //   }
      // }
    }

    const documents = checkedBoxes.map((obj) => {
      const newObj = {
        accountId: obj.accountId,
        documentId: obj._id,
        documentType: obj.documentType
      };

      for (let field of fields) {
        switch (field) {
          case 'startDate':
          case 'endDate':
            newObj[field] = formatDateYYYYMMDD(document[field]);

            break;

          default:
            newObj[field] = document[field];
        }
      }

      // for (let updateKey of updateKeys){
      //   newObj[updateKey] = obj[updateKey];
      // }
      // if (fields.includes('status')) {
      //   newObj.status = document.status;
      // }

      // if (fields.includes('startDate')) {
      //   newObj.startDate = new Date(document.startDate)
      //     .toLocaleDateString()
      //     .split('T')[0];
      // }

      // if (fields.includes('endDate')) {
      //   newObj.endDate = new Date(document.endDate)
      //     .toLocaleDateString()
      //     .split('T')[0];
      // }

      return newObj;
    });

    let input = {
      managerAccountId: currentManagerAccount?._id,
      updateKeys,
      documents
    };

    // if (
    //   limitDates &&
    //   startDate &&
    //   endDate &&
    //   new Date(startDate) > new Date(endDate)
    // ) {
    //   enqueueSnackbar('End date must be later than or equal to start date', {
    //     variant: 'error'
    //   });
    //   console.log('End date must be later than or equal to start date');
    //   return;
    // }

    // updateDocument({ variables: { input } });
    toggleAwaitingResponse(true);
    try {
      const res = await updateDocument({
        variables: { input: { ...input } }
      });

      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
      toggleAwaitingResponse(false);
      handleClose();
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
      toggleAwaitingResponse(false);
    }
  };

  // #########################################################

  // const body = <div style={{ display: 'flex', flexDirection: 'column' }}></div>;

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            width: '700px',
            maxWidth: '100vw',
            maxHeight: '90%',
            backgroundImage:
              theme?.palette?.mode === 'dark' &&
              'linear-gradient(to top, rgb(55, 55, 66), rgb(75, 75, 86))'
          }
        }}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div>Bulk Edit</div>

            <div
              style={{
                cursor: 'pointer',
                right: '0px',
                textAlign: 'right'
                // paddingRight: '25px'
              }}
            >
              <span style={{ width: '50px' }} onClick={handleClose}>
                <CloseIcon className="hover-light-grey" />
              </span>
            </div>
          </div>
          <div style={{ fontSize: '12px', padding: '0px 5px' }}></div>
        </DialogTitle>
        <DialogContent>
          <EditDialogContent
            open={open}
            setOpen={setOpen}
            checkedBoxes={checkedBoxes}
            fields={fields}
            setFields={setFields}
            document={document}
            setDocument={setDocument}
            dataSourceAccountIds={dataSourceAccountIds}
            checkedDocumentTypes={checkedDocumentTypes}
            templateDocument={templateDocument}
            checkedTypes={checkedTypes}
            currentManagerAccount={currentManagerAccount}
            accountId={accountId}
            setTemplateDocument={setTemplateDocument}
            handleClose={handleClose}
            onSaveHandler={onSaveHandler}
            isActionBulkEditAllowed={isActionBulkEditAllowed}
          />
        </DialogContent>
        <DialogActions>
          {awaitingResponse ? (
            <div style={{ marginRight: '10px' }}>
              <Spinner />
            </div>
          ) : (
            <>
              <Button
                size="small"
                // style={{ minWidth: '91px' }}
                autoFocus
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                // style={{ minWidth: '91px' }}
                variant="contained"
                onClick={onSaveHandler}
                disabled={!checkedBoxes.length}
              >
                Apply
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditModal.propTypes = {
  // style: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  stylesStoredInRedux: state.style
});

export default connect(mapStateToProps, {})(EditModal);
