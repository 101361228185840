import React, { useContext } from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import InputIcon from '@mui/icons-material/Input';
import FolderIcon from '@mui/icons-material/Folder';
// import LinkIcon from '@mui/icons-material/Link';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';
import AssignmentIcon from '@mui/icons-material/Assignment';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import facebookLogo from './../item-components/body/typeItem/f_logo_RGB-Blue_1024.png';
import googleAdsLogo from './../item-components/body/typeItem/Google-Ads-Logo.png';

import cm360Logo from './../item-components/body/typeItem/logo_campaign_manager_192px.svg';
import dv360Logo from './../item-components/body/typeItem/dv360logo.svg';

import snapchatAdsLogo from './../item-components/body/typeItem/ghostlogo.svg';
import tikTokAdsLogo from './../item-components/body/typeItem/tiktok_logo.svg';
import pinterestAdsLogo from './../item-components/body/typeItem/pinterest_logo.svg';
import readpeakLogo from './../item-components/body/typeItem/readpeak_logo.png';
import strossleIcon from './../item-components/body/typeItem/strossle_icon.png';
import amazonDspLogo from './../item-components/body/typeItem/amazon_logo.png';
import taboolaAdsLogo from './../item-components/body/typeItem/taboolaIcon.png';
import microsoftAdvertisingLogo from './../item-components/body/typeItem/microsoftAdvertisingLogo.png';

import microsoftInvestLogo from './../item-components/body/typeItem/microsoftInvestLogo.png';

import AppleIcon from '@mui/icons-material/Apple';

import adformIcon from './../item-components/body/typeItem/adformicon.png';

import linkedInAdsLogo from './../item-components/body/typeItem/LI-In-Bug.png';

import PowerInputIcon from '@mui/icons-material/PowerInput';

// import Draggable from './Draggable';

import { styled, useTheme } from '@mui/material/styles';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../context/InterfaceContext';

function IconComponent({
  currentManagerAccountId,
  // item,
  // itemKey,
  // itemValue,
  // rowNumber,
  keyForMap,
  // itemStyle,
  // checked,
  documentType,
  iconColor,
  customWidth
}) {
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  console.log('IconComponent>documentType: ', documentType);
  const theme = useTheme();

  // ####################################################
  const size = 0.5;
  let displayValue = '';
  console.log('customWidth: ', customWidth);
  const iconStyle = {
    color: iconColor,
    width: customWidth * size || 20 * size + 'px'
    // color: 'rgb(150,150,150)'
    // color: itemStyle['color']
    // color: 'rgba(54, 82, 156,1)'
    // color: 'rgba(33,40,90, 0.7)'
  };

  switch (documentType) {
    case 'account':
      displayValue = <AccountBalanceIcon style={{ ...iconStyle }} />;
      break;
    case 'totalActionsDocument':
      displayValue = <PublicIcon style={{ ...iconStyle }} />;
      break;
    case 'folder':
      displayValue = <FolderIcon style={{ ...iconStyle }} />;
      break;
    case 'manualDataEntry':
      displayValue = <InputIcon style={{ ...iconStyle }} />;
      break;
    case 'customForm':
      displayValue = <DynamicFormIcon style={{ ...iconStyle }} />;
      break;
    case 'facebookAds':
      displayValue = (
        <img
          draggable={false}
          // id={item._id}

          src={facebookLogo}
          alt="Logo"
          style={{
            width: customWidth * size || 20 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
            // userSelect: 'none'
          }}
        />
      );
      break;
    case 'facebook_ads_ad_set':
      displayValue = (
        <img
          draggable={false}
          src={facebookLogo}
          alt="Logo"
          style={{
            width: 14 * size * 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '13px'
          }}
        />
      );
      break;

    case 'googleAds':
      displayValue = (
        <img
          draggable={false}
          src={googleAdsLogo}
          alt="Logo"
          style={{
            width: customWidth * size || 20 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;
    case 'google_ads_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={googleAdsLogo}
          alt="Logo"
          style={{
            width: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '13px'
          }}
        />
      );
      break;

    case 'cm360':
      displayValue = (
        <img
          draggable={false}
          src={cm360Logo}
          alt="Logo"
          style={{
            width: customWidth * size || 20 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'dv360':
      displayValue = (
        <img
          draggable={false}
          src={dv360Logo}
          alt="Logo"
          style={{
            width: customWidth * size || 20 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'dv360_line_item':
      displayValue = (
        <img
          draggable={false}
          src={dv360Logo}
          alt="Logo"
          style={{
            width: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'adform':
      displayValue = (
        <img
          draggable={false}
          src={adformIcon}
          alt="Logo"
          style={{
            width: customWidth * size || 20 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'adform_line_item':
      displayValue = (
        <img
          draggable={false}
          src={adformIcon}
          alt="Logo"
          style={{
            width: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'snapchatAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px',
            border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={snapchatAdsLogo}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'snapchat_ads_ad_squad':
      displayValue = (
        <img
          draggable={false}
          src={snapchatAdsLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'tikTokAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={tikTokAdsLogo}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'tiktok_ads_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={tikTokAdsLogo}
          alt="Logo"
          style={{
            width: 14 * size + 'px'

            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'pinterestAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={pinterestAdsLogo}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'pinterest_ads_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={pinterestAdsLogo}
          alt="Logo"
          style={{
            width: 14 * size + 'px',
            height: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'readpeak':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={readpeakLogo}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'readpeak_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={readpeakLogo}
          alt="Logo"
          style={{
            width: 14 * size + 'px',
            height: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'strossle':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={strossleIcon}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'strossle_ad_set':
      displayValue = (
        <img
          draggable={false}
          src={strossleIcon}
          alt="Logo"
          style={{
            width: 14 * size + 'px',
            height: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'amazonDsp':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={amazonDspLogo}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'amazon_dsp_line_item':
      displayValue = (
        <img
          draggable={false}
          src={amazonDspLogo}
          alt="Logo"
          style={{
            width: 14 * size + 'px',
            height: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'taboolaAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={taboolaAdsLogo}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'microsoftAdvertising':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={microsoftAdvertisingLogo}
            alt="Logo"
            style={{
              // width: '15px',
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'microsoftInvest':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={microsoftInvestLogo}
            alt="Logo"
            style={{
              // width: '15px',
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'appleSearchAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <AppleIcon
            draggable={false}
            style={{
              // width: '15px',
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'adform':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={adformIcon}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'adform_line_item':
      displayValue = (
        <img
          draggable={false}
          src={adformIcon}
          alt="Logo"
          style={{
            width: 14 * size + 'px',
            height: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'linkedInAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: customWidth * size || 20 * size + 'px',
            height: customWidth * size || 20 * size + 'px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={linkedInAdsLogo}
            alt="Logo"
            style={{
              width: customWidth * size || 20 * size + 'px',
              height: customWidth * size || 20 * size + 'px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'linkedin_ads_campaign':
      displayValue = (
        <img
          draggable={false}
          src={linkedInAdsLogo}
          alt="Logo"
          style={{
            width: 14 * size + 'px',
            height: 14 * size + 'px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'globalTasks':
      displayValue = <AssignmentIcon style={{ ...iconStyle }} />;
      break;

    case 'task':
      displayValue = <ListAltIcon style={{ ...iconStyle }} />;
      break;
    case 'subTask':
      displayValue = <ListIcon style={{ ...iconStyle }} />;
      break;
    case 'taxonomy':
      displayValue = (
        <PowerInputIcon style={{ ...iconStyle, transform: 'rotate(180deg)' }} />
      );
      break;
    default:
    // displayValue = itemValue;
  }

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={
        {
          // zIndex: '1000',
          // maxHeight: '46px',
          // // backgroundColor: checked
          // //   ? itemStyle['checkedBackgroundColor']
          // //   : itemStyle['backgroundColor'],
          // // color: itemStyle['color'],
          // // backgroundColor: 'rgba(255, 255, 255, 1)',
          // // borderRight: itemStyle['border'],
          // // borderBottom: itemStyle['border'],
          // // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          // fontSize: '12px',
          // // gridRowStart: rowNumber + 2,
          // // gridRowEnd: rowNumber + 2,
          // // position: 'sticky',
          // left: '46px',
          // // display: 'grid',
          // height: '100%',
          // width: '100%',
          // // paddingTop: '12px',
          // whiteSpace: 'nowrap',
          // display: 'flex',
          // justifyContent: 'center',
          // alignContent: 'center',
          // alignItems: 'center'
        }
      }
    >
      {(inView || exportMode) && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%'
            // paddingLeft: '11px',
            // cursor: [
            //   'folder',
            //   'manualDataEntry',
            //   'customForm',
            //   'facebook_ads_campaign',
            //   'google_ads_campaign',
            //   'cm360_campaign',
            //   'dv360_insertion_order',
            //   'snapchat_ads_campaign',
            //   'tiktok_ads_campaign'
            // ].includes(itemValue)
            //   ? 'grab'
            //   : ''
          }}
        >
          {displayValue}
          {/* {displayValue}
          {item?.template && <div style={{ fontSize: '8px' }}>Template</div>}
          {!item?.template && item?.templateDescendant && (
            <div
              style={{ fontSize: '6px', fontStyle: 'italic', color: 'grey' }}
            >
              Templ. desc.
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

export default React.memo(IconComponent);
