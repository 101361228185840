import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { LineChart } from '@mui/x-charts/LineChart';

import { DataContext } from '../../../../../context/DataContext';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict,
  colorPalette
} from './../dictionary';

import formatDateYYYYMMDD from './../../../roitable_functions/formatDateYYYYMMDD';
import { InterfaceContext } from '../../../../../context/InterfaceContext';

function LineChartWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const key = widget.metrics?.[0] || 'cost';
  const { listOfData, arrayOfData, listOfFilteredDataSourceRows } =
    useContext(DataContext);
  const { aggregatedDateRange, startDateForLastDaysData } =
    useContext(InterfaceContext);

  // console.log('debug99>arrayOfData: ', arrayOfData);

  // console.log('PieChartWidget>listOfData: ', listOfData);

  const [seriesData, setSeriesData] = useState([]);

  const valueFormatter = (item) =>
    `${key}: ${(
      Math.round((item.value + Number.EPSILON) * 100) / 100
    ).toLocaleString()} ( ${item.share}% )`;

  const [series, setSeries] = useState([]);
  const [dates, setDates] = useState([new Date()]);

  useEffect(() => {
    const dataSourceData = listOfFilteredDataSourceRows.filter(
      (row) =>
        [
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'microsoft_invest_insertion_order',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group',
          'manualDataEntry'
        ].includes(row.type) &&
        (row.endDate >= formatDateYYYYMMDD(startDateForLastDaysData) ||
          !row.endDate)
    );

    let widgetGroupByKey = '';

    switch (widget.groupBy[0]) {
      case 'documentType':
        widgetGroupByKey = 'documentType';
        break;
      case 'name':
        widgetGroupByKey = 'name';
        break;
    }

    let tempGroupBy = {};
    console.log('widget.groupBy: ', widget.groupBy);

    console.log('widget.groupBy.length: ', widget.groupBy.length);
    if (widget.groupBy.length) {
      tempGroupBy = Object.groupBy(
        dataSourceData,
        (row) => row[widgetGroupByKey]
      );
    } else {
      tempGroupBy = { default: dataSourceData };
    }

    // console.log('PieChartWidget>testGroupBy: ', testGroupBy);
    // console.log('tempGroupBy: ', tempGroupBy);

    const groupByKeys = Object.keys(tempGroupBy);

    // console.log('groupByKeys: ', groupByKeys);
    // console.log('tempGroupBy: ', tempGroupBy);
    // console.log('widget.metrics111: ', widget.metrics);

    const allSeries = [];

    for (let groupByKey of groupByKeys) {
      // data source
      console.log('debug2233>row103>groupByKey: ', groupByKey);
      const newSeriesData = [];

      let allPropagationData = [];
      for (let row of tempGroupBy[groupByKey]) {
        allPropagationData = [
          ...allPropagationData,
          ...(row?.total_list_of_propagation_data || [])
        ];
      }
      const groupByDate = Object.groupBy(allPropagationData, (obj) =>
        formatDateYYYYMMDD(obj.date_start)
      );

      for (let date of aggregatedDateRange) {
        // console.log('date111: ', date);
        let sumObject = {};
        // console.log('groupByDate[date]: ', groupByDate[date]);
        for (let dataObj of groupByDate?.[date] || []) {
          for (let metric of [
            'budget',
            'cost',
            'impressions',
            'clicks',
            'action01Count',
            'action01Value',
            'action02Count',
            'action02Value',
            'action03Count',
            'action03Value'
          ]) {
            // console.log('dataObj[metric]: ', dataObj[metric]);
            sumObject[metric] =
              (sumObject[metric] || 0) + (dataObj[metric] || 0);
          }
        }

        for (let metric of [
          'cpm',
          'cpcv',
          'cpc',
          'ctr',
          'action01Roas',
          'action01Cpa'
        ]) {
          let newValue = null;
          switch (metric) {
            case 'cpm':
              newValue = sumObject['impressions']
                ? sumObject['cost'] / (sumObject['impressions'] / 1000)
                : 0;
              break;

            case 'cpcv':
              newValue = sumObject['videoCompletions']
                ? sumObject['cost'] / sumObject['videoCompletions']
                : 0;
              break;

            case 'cpc':
              newValue = sumObject['clicks']
                ? sumObject['cost'] / sumObject['clicks']
                : 0;
              break;

            case 'ctr':
              newValue = sumObject['impressions']
                ? (sumObject['clicks'] / sumObject['impressions']) * 100
                : 0;
              break;

            case 'action01Roas':
              newValue = sumObject['cost']
                ? sumObject['action01Value'] / sumObject['cost']
                : 0;
              break;

            case 'action01Cpa':
              newValue = sumObject['action01Count']
                ? sumObject['cost'] / sumObject['action01Count']
                : 0;
              break;
          }

          sumObject[metric] =
            Math.round((newValue + Number.EPSILON) * 100) / 100; //.toLocaleString();
        }

        newSeriesData.push(sumObject);
      }
      // console.log('groupByDate: ', groupByDate);
      // console.log(' newSeriesData: ', newSeriesData);
      allSeries.push({
        label:
          documentTypeToShortNameDict[groupByKey] ||
          (groupByKey !== 'default' ? groupByKey : undefined),
        data: newSeriesData.map((obj) => obj?.[key]),
        showMark: false
      });

      // for (let row of tempGroupBy[groupByKey]) {
      //   const groupByDate = Object.groupBy(
      //     row?.total_list_of_propagation_data || [],
      //     (obj) => formatDateYYYYMMDD(obj.date_start)
      //   );

      //   for (let date of aggregatedDateRange) {
      //     console.log('date111: ', date);
      //     let sumObject = {};
      //     console.log('groupByDate[date]: ', groupByDate[date]);
      //     for (let dataObj of groupByDate[date]) {
      //       for (let metric of widget.metrics) {
      //         console.log('dataObj[metric]: ', dataObj[metric]);
      //         sumObject[metric] += dataObj[metric] || 0;
      //       }
      //     }
      //     newSeriesData.push(sumObject);
      //   }
      //   console.log('groupByDate: ', groupByDate);
      //   console.log(' newSeriesData: ', newSeriesData);
      //   allSeries.push({ data: newSeriesData });
      // }
    }

    // console.log('allSeries: ', allSeries);

    // const seriesWithData = allSeries.filter((serie) =>
    //   serie.data.some((val) => Boolean(val))
    // );
    // console.log('seriesWithData: ', seriesWithData);
    setSeries(allSeries);
    //   let groupByValue = 0;

    //   for (let row of testGroupBy[groupByKey]) {
    //     groupByValue += row[key];
    //   }
    //   let seriesData = {
    //     // label: documentTypeToNameDict[groupByKey],
    //     value: groupByValue
    //   };

    //   switch (widgetGroupByKey) {
    //     case 'documentType':
    //       seriesData.label = documentTypeToNameDict[groupByKey];
    //       break;

    //     case 'name':
    //       seriesData.label =
    //         groupByKey.slice(0, 20) + (groupByKey.length > 20 ? '...' : '');
    //       break;
    //   }
    //   newSeriesData.push(seriesData);
    // }

    // let totalValue = 0;
    // for (let row of newSeriesData) {
    //   totalValue += row?.value || null;
    // }

    // for (let row of newSeriesData) {
    //   row.share = Math.round((row?.value / totalValue) * 100);
    //   row.value = Math.round(row?.value);
    // }
    // console.log('totalValue: ', totalValue);
    // setSeriesData(newSeriesData);
    setDates(aggregatedDateRange?.map((date) => new Date(date)));
  }, [listOfData, aggregatedDateRange, widget, listOfFilteredDataSourceRows]);

  // const data = {
  //   data: seriesData
  //   // valueFormatter
  // };

  return (
    // <div onMouseUpHandler={onMouseUpHandler}>

    // <div
    //   style={{
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     // justifyContent: 'space-between',
    //     justifyContent: 'space-between',
    //     alignItems: 'center'
    //     // position: 'relative',
    //     // width: widget.style.width.split('px')[0] * 0.9,
    //     // height: widget.style.height.split('px')[0] * 0.8,
    //     // padding: '0px'
    //   }}
    // >
    //   <div
    //     style={{
    //       width: '100%',
    //       display: 'flex',
    //       flexDirection: 'row',
    //       justifyContent: 'center',
    //       alignContent: 'center',
    //       fontSize: '14px',
    //       fontWeight: 'bold'
    //     }}
    //   >
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center'
        // position: 'relative',
        // width: widget.style.width.split('px')[0] * 0.9,
        // height: widget.style.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          fontWeight: 'bold',
          fontSize: '14px',
          // padding: '3px',
          // paddingLeft: '10px',
          // borderRadius: '0px 5px 0px 10px',
          borderRadius: '0px 0px 0px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px',
          background: 'rgba(150,150,150,0.1)',
          minWidth: '100px'
        }}
      >
        {/* <div
          style={{
            borderRadius: '0px 0px 5px 5px',
            background: 'rgba(150,150,150,0.1)',
            padding: '0px 7px 2px 7px'
          }}
        > */}
        {widget.title}
        {/* </div> */}
      </div>
      <div
        style={{
          display: 'flex'
          // padding: '0px 3px'
        }}
      >
        <LineChart
          colors={colorPalette} // Use palette
          // xAxis={[
          //   new Date('2024-08-01'),
          //   new Date('2024-08-02'),
          //   new Date('2024-08-03'),
          //   new Date('2024-08-04'),
          //   new Date('2024-08-05'),
          //   new Date('2024-08-06'),
          //   new Date('2024-08-07')
          // ]}

          xAxis={[
            {
              label: 'Date',
              data: dates, // xAxisData,
              tickInterval: dates, // xAxisData,
              scaleType: 'time',
              valueFormatter: (date) => {
                const formattedDate = formatDateYYYYMMDD(date);
                // console.log('formattedDate: ', formattedDate);
                return formattedDate;
              } //(date) => dayjs(date).format('MMM D')
            }
          ]}
          series={
            // [
            //   {
            //     data: [1, 2, 3, 4, 5, 6, 7]
            //   }
            // ]
            series

            // [
            //   {
            //     // arcLabel: (item) => `${item.share}%`,
            //     // arcLabelMinAngle: 35,
            //     // arcLabelRadius: '60%',
            //     ...data
            //   }
            // ]
          }
          margin={{
            left: 70
            // right: 60
            // top: 80,
            // bottom: 80,
          }}
          slotProps={{
            legend: {
              hidden: series?.length > 10,
              // direction: 'column',
              // position: { vertical: 'middle', horizontal: 'right' },
              itemMarkWidth: 10,
              itemMarkHeight: 10,
              padding: { top: 0, left: 10, right: 10 },
              labelStyle: {
                fontSize: 12
                //fill: 'blue'
              }
            }
          }}
          width={widget.style.width.split('px')[0]}
          height={
            widget.style.height.split('px')[0] -
            (widget?.groupBy?.length ? 20 : 0)
          }
        />
      </div>
    </div>
  );
}

export default React.memo(LineChartWidget);
