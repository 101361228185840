export const columnArray = [
  'dataSourceCurrency',
  '_id',
  'warnings',
  'status',
  'invoiceStatus',
  'invoiceOrderId',
  'checkedAt',
  'template',
  'path',
  'comments',
  'members',
  'labels',
  'allowImpressionsBeforeStartDate',
  'dateRange',
  'startDate',
  'endDate',
  'allowImpressionsAfterEndDate',
  'numberOfDays',
  // 'numberOfDaysLeft',
  'daysLeft',
  'taxonomyTargeting',
  'taxonomyWidgets',
  'generalDescription',
  'buyingTypeDescription',
  'creativeDescription',
  'formatDescription',
  'channelDescription',
  'audienceDataDescription',
  'targetGroupDescription',
  'placementDescription',
  'ganttBarDisplaySetting',
  'goalDescription',
  'budget',
  'budgetLeft',
  'budgetCurrency',
  'allowMissingBudget',
  'calcDailyBudget',
  'calcDailyBudgetYesterday',
  'plannedGross',
  'plannedNet',
  'plannedNetNet',
  'plannedCtc',

  'plannedTrp',
  'plannedCpp',
  'plannedTechFee',
  'plannedAdOpsFee',
  'plannedAdServingCost',
  'plannedFeePercent',

  'kpi',
  'plannedImpressions',
  'plannedClicks',
  'plannedCtr',
  'plannedCpc',
  'plannedReach',
  'plannedCpm',
  'plannedFrequency',
  'plannedActionCount',
  'plannedActionValue',
  'plannedActionRoas',
  'plannedCpa',
  'cost',
  // 'costLifetime',
  // 'impressionsLifetime',
  'pace',
  // 'paceYesterday',
  'calcPaceYesterday',
  // 'pace_bar',
  'universe',
  'trp',
  'spots',
  'impressions',
  'viewableImpressions',
  'measurableImpressions',
  'eligibleImpressions',
  'cpm',
  'vCpm',
  'videoCompletions',
  // 'reach',
  'clicks',
  'allowMissingClicks',
  'allowAbnormallyHighCtr',
  'allowMissingImpressionsYesterday',
  'ctr',
  'viewableRate',
  'vcr',
  'searchImpressionShare',
  'cpcv',
  'cpc',
  'action01',
  'action01Count',
  'action01Cvr',
  'action01Cpa',
  'action01Value',
  'action01Roas',
  'action01Vpa',
  'action02',
  'action02Count',
  'action02Cvr',
  'action02Cpa',
  'action02Value',
  'action02Roas',
  'action02Vpa',
  'action03',
  'action03Count',
  'action03Cvr',
  'action03Cpa',
  'action03Value',
  'action03Roas',
  'action03Vpa',

  // 'totalCost',
  // 'totalImpressions',
  // 'totalCpm',
  // 'totalClicks',
  // 'totalCtr',
  // 'totalCpc',

  // 'totalAction01',
  // 'totalAction01Count',
  // 'totalaction01Cvr',
  // 'totalAction01Cpa',
  // 'totalAction01Value',
  // 'totalAction01Roas',
  // 'totalAction01Vpa',
  // 'totalAction02',
  // 'totalAction02Count',
  // 'totalaction02Cvr',
  // 'totalAction02Cpa',
  // 'totalAction02Value',
  // 'totalAction02Roas',
  // 'totalAction02Vpa',
  // 'totalAction03',
  // 'totalAction03Count',
  // 'totalaction03Cvr',
  // 'totalAction03Cpa',
  // 'totalAction03Value',
  // 'totalAction03Roas',
  // 'totalAction03Vpa',

  'ganttBarColor',
  'gantt',

  'impressionsToday',
  'impressionsYesterday',
  'impressionsSparkline',

  'viewableImpressionsToday',
  'viewableImpressionsYesterday',
  'viewableImpressionsSparkline',

  'measurableImpressionsToday',
  'measurableImpressionsYesterday',
  'measurableImpressionsSparkline',

  'eligibleImpressionsToday',
  'eligibleImpressionsYesterday',
  'eligibleImpressionsSparkline',

  'videoCompletionsToday',
  'videoCompletionsYesterday',
  'videoCompletionsSparkline',

  // 'cpmToday',
  // 'cpmYesterday',
  'cpmSparkline',
  'vCpmSparkline',

  'costToday',
  'costYesterday',
  'costSparkline',

  // 'clicksToday',
  // 'clicksYesterday',
  'clicksSparkline',

  // 'ctrToday',
  // 'ctrYesterday',
  'ctrSparkline',
  'viewableRateSparkline',
  'vcrSparkline',
  'searchImpressionShareSparkline',

  // 'cpcToday',
  'cpcvSparkline',
  'cpcSparkline',
  // 'cpcYesterday',

  'action01CountSparkline',
  'action01CvrSparkline',
  'action01CpaSparkline',
  'action01ValueSparkline',
  'action01RoasSparkline',
  'action01VpaSparkline',

  'action02CountSparkline',
  'action02CvrSparkline',
  'action02CpaSparkline',
  'action02ValueSparkline',
  'action02RoasSparkline',
  'action02VpaSparkline',

  'action03CountSparkline',
  'action03CvrSparkline',
  'action03CpaSparkline',
  'action03ValueSparkline',
  'action03RoasSparkline',
  'action03VpaSparkline'

  // 'action01CountToday',
  // 'action01CvrToday',
  // 'action01CpaToday',
  // 'action01ValueToday',
  // 'action01RoasToday',
  // 'action01VpaToday',

  // 'action02CountToday',
  // 'action02CvrToday',
  // 'action02CpaToday',
  // 'action02ValueToday',
  // 'action02RoasToday',
  // 'action02VpaToday',

  // 'action03CountToday',
  // 'action03CvrToday',
  // 'action03CpaToday',
  // 'action03ValueToday',
  // 'action03RoasToday',
  // 'action03VpaToday',

  // 'action01CountYesterday',
  // 'action01CvrYesterday',
  // 'action01CpaYesterday',
  // 'action01ValueYesterday',
  // 'action01RoasYesterday',
  // 'action01VpaYesterday',

  // 'action02CountYesterday',
  // 'action02CvrYesterday',
  // 'action02CpaYesterday',
  // 'action02ValueYesterday',
  // 'action02RoasYesterday',
  // 'action02VpaYesterday',

  // 'action03CountYesterday',
  // 'action03CvrYesterday',
  // 'action03CpaYesterday',
  // 'action03ValueYesterday',
  // 'action03RoasYesterday',
  // 'action03VpaYesterday'
];

export const defaultColumnSets = [
  {
    _id: 'column_setup_planner',
    name: 'Planner',
    columns: [
      // 'name',
      // 'type',
      'warnings',
      'status',
      'invoiceStatus',
      'invoiceOrderId',
      // 'notifications',
      'path',
      // 'comments',
      'members',
      'labels',
      'dateRange',
      // 'startDate',
      // 'endDate',
      'numberOfDays',
      'generalDescription',
      'buyingTypeDescription',
      'creativeDescription',
      'formatDescription',
      'channelDescription',
      'audienceDataDescription',
      'targetGroupDescription',
      'placementDescription',
      'ganttBarDisplaySetting',
      'goalDescription',
      'plannedGross',
      'plannedNet',
      'plannedNetNet',
      'plannedCtc',

      'plannedTrp',
      'plannedCpp',
      'plannedTechFee',
      'plannedAdOpsFee',
      'plannedAdServingCost',
      'plannedFeePercent',

      'budget',
      'budgetLeft',
      'budgetCurrency',
      'calcDailyBudget',
      'universe',
      'kpi',
      'plannedImpressions',
      'plannedClicks',
      'plannedCtr',
      'plannedCpc',
      'plannedReach',
      'plannedCpm',
      'plannedFrequency',
      'plannedActionCount',
      'plannedActionValue',
      'plannedActionRoas',
      'plannedCpa',
      'ganttBarColor',
      'gantt'
    ]
  },
  {
    _id: 'column_setup_c_level',
    name: 'C-level',
    columns: [
      'warnings',
      'status',
      'path',
      'dateRange',
      // 'startDate',
      // 'endDate',
      'budget',
      'budgetLeft',
      'calcDailyBudget',
      'cost',
      'impressions',
      'cpm',
      'action01',
      'action01Count',
      'action01Cpa',
      'action01Value',
      'action01Roas',
      'action01Vpa',
      'ganttBarColor',
      'gantt'
    ]
  },
  {
    _id: 'column_setup_pacing',
    name: 'Pacing',
    columns: [
      'warnings',
      'status',
      'checkedAt',
      'path',
      'members',
      // 'allowImpressionsBeforeStartDate',
      'dateRange',
      // 'startDate',
      // 'endDate',
      // 'allowImpressionsAfterEndDate',
      'budget',
      'budgetLeft',
      // 'allowMissingBudget',
      'calcDailyBudget',
      'calcDailyBudgetYesterday',
      'cost',
      'costYesterday',
      'pace',
      'calcPaceYesterday'
      // 'impressions'
      // 'gantt'
    ]
  },
  {
    _id: 'column_setup_morning_sync',
    name: 'Morning Sync',
    columns: [
      'warnings',
      'status',
      'checkedAt',
      'path',
      'members',
      'allowImpressionsBeforeStartDate',
      'dateRange',
      // 'startDate',
      // 'endDate',
      'allowImpressionsAfterEndDate',
      'budget',
      'allowMissingBudget',
      // 'calcDailyBudget',
      // 'calcDailyBudgetYesterday',
      'cost',
      // 'costYesterday',
      'pace',
      'calcPaceYesterday',
      'impressions'
      // 'gantt'
    ]
  },
  {
    _id: 'column_setup_morning_sync_sem',
    name: 'Morning Sync SEM',
    columns: [
      'warnings',
      'status',
      'checkedAt',
      'path',
      'members',
      'allowImpressionsBeforeStartDate',
      'dateRange',
      // 'startDate',
      // 'endDate',
      'allowImpressionsAfterEndDate',
      'budget',
      // 'budgetLeft',
      'allowMissingBudget',
      // 'calcDailyBudget',
      // 'calcDailyBudgetYesterday',
      'cost',
      // 'costYesterday',
      'pace',
      'calcPaceYesterday',
      'impressions',
      'searchImpressionShare',
      'clicks',
      'allowMissingClicks',
      'allowAbnormallyHighCtr',
      'allowMissingImpressionsYesterday',
      'ctr',
      // 'cpcv',
      'cpc'

      // 'gantt'
    ]
  },
  {
    _id: 'column_setup_delivery_a1',
    name: 'Delivery A1',
    columns: [
      // 'name',
      // 'type',
      'warnings',
      'status',
      'checkedAt',
      // 'notifications',
      'path',
      // 'comments',
      'members',
      'labels',
      'allowImpressionsBeforeStartDate',
      'dateRange',
      // 'startDate',
      // 'endDate',
      'allowImpressionsAfterEndDate',
      'numberOfDays',
      // 'numberOfDaysLeft',
      'daysLeft',
      'generalDescription',
      // 'buyingTypeDescription',
      // 'creativeDescription',
      // 'formatDescription',
      // 'channelDescription',
      // "audienceDataDescription",
      // 'targetGroupDescription',
      // 'placementDescription',
      'goalDescription',
      'budget',
      'budgetLeft',
      'budgetCurrency',
      'allowMissingBudget',
      'calcDailyBudget',
      // 'plannedGross',
      // 'plannedNet',
      // 'plannedNetNet',
      // 'plannedCtc',

      // 'plannedTrp',
      // 'plannedTechFee',
      // 'plannedAdOpsFee',
      // 'plannedAdServingCost',
      // 'plannedFeePercent',

      //         'kpi',
      // 'plannedImpressions',
      // 'plannedClicks',
      // 'plannedCtr',
      // 'plannedCpc',
      // 'plannedReach',
      // 'plannedCpm',
      // 'plannedFrequency',
      // 'plannedActionCount',
      // 'plannedActionValue',
      // 'plannedActionRoas',
      // 'plannedCpa',
      'cost',
      'pace',
      'calcPaceYesterday',
      'impressions',
      'cpm',
      'videoCompletions',
      'vcr',
      'cpcv',
      'clicks',
      'allowMissingClicks',
      'allowAbnormallyHighCtr',
      // 'allowMissingImpressionsYesterday',
      'ctr',
      // 'viewableRate',

      // 'searchImpressionShare',

      'cpc',
      'action01',
      'action01Count',
      'action01Cvr',
      'action01Cpa',
      'action01Value',
      'action01Roas',
      'action01Vpa',
      'ganttBarColor',
      'gantt'
    ]
  },
  {
    _id: 'column_setup_delivery_a1_a2_a3',
    name: 'Delivery A1, A2, A3',
    columns: [
      // 'name',
      // 'type',
      'warnings',
      'status',
      // 'notifications',
      'path',
      // 'comments',
      'members',
      'labels',
      'dateRange',
      // 'startDate',
      // 'endDate',
      'numberOfDays',
      'generalDescription',

      // 'buyingTypeDescription',
      // 'creativeDescription',
      // 'formatDescription',
      // 'channelDescription',
      // "audienceDataDescription",
      // 'targetGroupDescription',
      // 'placementDescription',
      'goalDescription',
      'budget',
      'budgetLeft',
      'budgetCurrency',
      'calcDailyBudget',
      'plannedGross',
      'plannedNet',
      'plannedNetNet',
      'plannedCtc',

      'plannedTrp',
      'plannedCpp',
      'plannedTechFee',
      'plannedAdOpsFee',
      'plannedAdServingCost',
      'plannedFeePercent',

      'cost',
      'pace',
      'impressions',
      // 'reach',
      'cpm',
      'videoCompletions',
      'cpcv',
      'clicks',
      'ctr',
      'viewableRate',
      'vcr',
      'searchImpressionShare',
      'cpc',
      'action01',
      'action01Count',
      'action01Cvr',
      'action01Cpa',
      'action01Value',
      'action01Roas',
      'action01Vpa',
      'action02',
      'action02Count',
      'action02Cvr',
      'action02Cpa',
      'action02Value',
      'action02Roas',
      'action02Vpa',
      'action03',
      'action03Count',
      'action03Cvr',
      'action03Cpa',
      'action03Value',
      'action03Roas',
      'action03Vpa',
      'ganttBarColor',
      'gantt'
    ]
  },
  {
    _id: 'taxonomy',
    name: 'Taxonomy',
    columns: [
      // 'name',
      // 'type',
      'warnings',
      'status',
      // 'notifications',
      'path',
      // 'comments',
      'members',
      'labels',
      'taxonomyTargeting',
      'taxonomyWidgets'
    ]
  },
  {
    _id: 'invoice',
    name: 'Invoice',
    columns: [
      // 'name',
      // 'type',

      'warnings',
      'path',
      'dateRange',
      // 'startDate',
      // 'endDate',
      'status',
      'invoiceStatus',
      'invoiceOrderId',
      'cost',
      'members',
      'labels',
      'generalDescription'
      // 'taxonomyTargeting',
      // 'taxonomyWidgets'
    ]
  }
];

export const columnDictionary = {
  dataSourceCurrency: 'Data Source Currency',
  _id: 'ID',
  name: 'Name',
  warnings: 'Warnings',
  status: 'Status',
  invoiceStatus: 'Invoice Status',
  invoiceOrderId: 'Invoice Order ID',
  checkedAt: 'Checked At',
  template: 'Template',
  path: 'Path',
  comments: 'Comments',
  members: 'Members',
  labels: 'Labels',
  dateRange: 'Date Range',
  startDate: 'Start Date',
  endDate: 'End Date',
  allowMissingBudget: 'Allow Missing Budget',
  allowMissingClicks: 'Allow Missing Clicks',
  allowAbnormallyHighCtr: 'Allow Abnormally High Ctr',
  allowMissingImpressionsYesterday: 'Allow Missing Impressions Yesterday',
  allowImpressionsBeforeStartDate: 'Allow Impressions Before Start Date',
  allowImpressionsAfterEndDate: 'Allow Impressions After End Date',
  numberOfDays: 'Number of Days',
  numberOfDaysLeft: 'Number of Days Left',
  daysLeft: 'Days Left (Beta)',

  taxonomyTargeting: 'Taxonomy Targeting',
  taxonomyWidgets: 'Taxonomy Widgets',
  generalDescription: 'General Description',

  buyingTypeDescription: 'Buying Type Description',
  creativeDescription: 'Creative Description',
  formatDescription: 'Format Description',
  channelDescription: 'Channel Description',
  audienceDataDescription: 'Audience Data Description',

  targetGroupDescription: 'Target Group Description',
  placementDescription: 'Placement Description',
  ganttBarDisplaySetting: 'Gantt Bar Display Setting',
  goalDescription: 'Goal Description',
  budget: 'Budget',
  budgetLeft: 'Budget Left',
  budgetYesterday: 'Budget Yesterday',
  budgetSparkline: 'Budget Sparkline',
  budgetCurrency: 'Budget Currency',
  calcDailyBudget: 'Calculated Daily Budget',
  calcDailyBudgetYesterday: 'Calc Daily Budget Yesterday',

  plannedGross: 'Planned Gross',
  plannedNet: 'Planned Net',
  plannedNetNet: 'Planned Net Net',
  plannedCtc: 'Planned Cost To Client',

  plannedTrp: 'Planned Target Rating Points',
  plannedCpp: 'Planned Cost Per Point',
  plannedTechFee: 'Planned Tech Fee',
  plannedAdOpsFee: 'Planned Ad Ops Fee',
  plannedAdServingCost: 'Planned Ad Serving Cost',
  plannedFeePercent: 'Planned Fee Percent',

  kpi: 'KPI',
  plannedImpressions: 'Planned Impressions',
  plannedClicks: 'Planned Clicks',
  plannedCtr: 'Planned CTR',
  plannedCpc: 'Planned CPC',
  plannedReach: 'Planned Reach',
  plannedCpm: 'Planned CPM',
  plannedFrequency: 'Planned Frequency',
  plannedActionCount: 'Planned Actions',
  plannedActionValue: 'Planned Action Value',
  plannedActionRoas: 'Planned Action ROAS',
  plannedCpa: 'Planned CPA',
  costLifetime: 'Lifetime Cost',
  impressionsLifetime: 'Lifetime Impressions',
  cost: 'Cost',
  pace: 'Pace',
  paceYesterday: 'Pace Yesterday',
  calcPaceYesterday: 'Calculated Pace Yesterday',
  pace_bar: 'Pace Bar',
  universe: 'Universe',
  trp: 'Target Rating Points',
  spots: 'Spots',
  impressions: 'Impressions',
  viewableImpressions: 'Viewable Impressions',
  measurableImpressions: 'Measurable Impressions',
  eligibleImpressions: 'Eligible Impressions',
  videoCompletions: 'Video Completions',
  cpm: 'CPM',
  vCpm: 'vCPM',
  reach: 'Reach',
  clicks: 'Clicks',
  ctr: 'CTR %',
  viewableRate: 'Viewable Rate',
  vcr: 'Video Completion Rate',
  searchImpressionShare: 'Search Impression Share',
  cpcv: 'CPCV',
  cpc: 'CPC',
  action01: 'Action 01 Type',
  action01Count: 'Action 01 Count',
  action01Cvr: 'Action 01 Conversion Rate',
  action01Cpa: 'Action 01 CPA',
  action01Value: 'Action 01 Value',
  action01Roas: 'Action 01 ROAS',
  action01Vpa: 'Action 01 Average Value Per Action',
  action02: 'Action 02 Type',
  action02Count: 'Action 02 Count',
  action02Cvr: 'Action 02 Conversion Rate',
  action02Cpa: 'Action 02 CPA',
  action02Value: 'Action 02 Value',
  action02Roas: 'Action 02 ROAS',
  action02Vpa: 'Action 02 Average Value Per Action',
  action03: 'Action 03 Type',
  action03Count: 'Action 03 Count',
  action03Cvr: 'Action 03 Conversion Rate',
  action03Cpa: 'Action 03 CPA',
  action03Value: 'Action 03 Value',
  action03Roas: 'Action 03 ROAS',
  action03Vpa: 'Action 03 Average Value Per Action',

  totalCost: 'Total Cost',
  totalImpressions: 'Total Impressions',
  totalCpm: 'Total CPM',
  totalClicks: 'Total Clicks',
  totalCtr: 'Total CTR',
  totalCpc: 'Total CPC',

  totalAction01: 'Total Action 01',
  totalAction01Count: 'Total Action 01 Count',
  totalaction01Cvr: 'Total Action 01 Conversion Rate',
  totalAction01Cpa: 'Total Action 01 CPA',
  totalAction01Value: 'Total Action 01 Value',
  totalAction01Roas: 'Total Action 01 ROAS',
  totalAction01Vpa: 'Total Action 01 Average Value Per Action',
  totalAction02: 'Total Action 02',
  totalAction02Count: 'Total Action 02 Count',
  totalaction02Cvr: 'Total Action 02 Conversion Rate',
  totalAction02Cpa: 'Total Action 02 CPA',
  totalAction02Value: 'Total Action 02 Value',
  totalAction02Roas: 'Total Action 02 ROAS',
  totalAction02Vpa: 'Total Action 02 Average Value Per Action',
  totalAction03: 'Total Action 03',
  totalAction03Count: 'Total Action 03 Count',
  totalaction03Cvr: 'Total Action 03 Conversion Rate',
  totalAction03Cpa: 'Total Action 03 CPA',
  totalAction03Value: 'Total Action 03 Value',
  totalAction03Roas: 'Total Action 03 ROAS',
  totalAction03Vpa: 'Total Action 03 Average Value Per Action',

  totalAction01CountSparkline: 'Total Action 01 Count Sparkline',
  totalaction01CvrSparkline: 'Total Action 01 Conversion Rate Sparkline',
  totalAction01CpaSparkline: 'Total Action 01 CPA Sparkline',
  totalAction01ValueSparkline: 'Total Action 01 Value Sparkline',
  totalAction01RoasSparkline: 'Total Action 01 ROAS Sparkline',
  totalAction01VpaSparkline:
    'Total Action 01 Average Value Per Action Sparkline',

  totalAction02CountSparkline: 'Total Action 02 Count Sparkline',
  totalaction02CvrSparkline: 'Total Action 02 Conversion Rate Sparkline',
  totalAction02CpaSparkline: 'Total Action 02 CPA Sparkline',
  totalAction02ValueSparkline: 'Total Action 02 Value Sparkline',
  totalAction02RoasSparkline: 'Total Action 02 ROAS Sparkline',
  totalAction02VpaSparkline:
    'Total Action 02 Average Value Per Action Sparkline',

  totalAction03CountSparkline: 'Total Action 03 Count Sparkline',
  totalaction03CvrSparkline: 'Total Action 03 Conversion Rate Sparkline',
  totalAction03CpaSparkline: 'Total Action 03 CPA Sparkline',
  totalAction03ValueSparkline: 'Total Action 03 Value Sparkline',
  totalAction03RoasSparkline: 'Total Action 03 ROAS Sparkline',
  totalAction03VpaSparkline:
    'Total Action 03 Average Value Per Action Sparkline',

  ganttBarColor: 'Gantt Bar Color',
  gantt: 'Gantt',

  impressionsToday: 'Impressions Today',
  impressionsYesterday: 'Impressions Yesterday',
  impressionsSparkline: 'Impressions Sparkline',

  viewableImpressionsToday: 'Viewable Impressions Today',
  viewableImpressionsYesterday: 'Viewable Impressions Yesterday',
  viewableImpressionsSparkline: 'Viewable Impressions Sparkline',

  measurableImpressionsToday: 'Measurable Impressions Today',
  measurableImpressionsYesterday: 'Measurable Impressions Yesterday',
  measurableImpressionsSparkline: 'Measurable Impressions Sparkline',

  eligibleImpressionsToday: 'Eligible Impressions Today',
  eligibleImpressionsYesterday: 'Eligible Impressions Yesterday',
  eligibleImpressionsSparkline: 'Eligible Impressions Sparkline',

  videoCompletionsToday: 'Video Completions Today',
  videoCompletionsYesterday: 'Video Completions Yesterday',
  videoCompletionsSparkline: 'Video Completions Sparkline',

  cpmToday: 'CPM Today',
  cpmYesterday: 'CPM Yesterday',
  cpmSparkline: 'CPM Sparkline',

  vCpmToday: 'vCPM Today',
  vCpmYesterday: 'vCPM Yesterday',
  vCpmSparkline: 'vCPM Sparkline',

  costToday: 'Cost Today',
  costYesterday: 'Cost Yesterday',
  costSparkline: 'Cost Sparkline',

  trpToday: 'Target Rating Points Today',
  trpYesterday: 'Target Rating Points Yesterday',
  trpSparkline: 'Target Rating Points Sparkline',

  spotsToday: 'Spots Today',
  spotsYesterday: 'Spots Yesterday',
  spotsSparkline: 'Spots Sparkline',

  clicksToday: 'Clicks Today',
  clicksYesterday: 'Clicks Yesterday',
  clicksSparkline: 'Clicks Sparkline',

  ctrToday: 'CTR Today',
  ctrYesterday: 'CTR Yesterday',
  ctrSparkline: 'CTR Sparkline',

  viewableRateToday: 'Viewable Rate Today',
  viewableRateYesterday: 'Viewable Rate Yesterday',
  viewableRateSparkline: 'Viewable Rate Sparkline',

  vcrToday: 'Video Completion Rate Today',
  vcrYesterday: 'Video Completion Rate Yesterday',
  vcrSparkline: 'Video Completion Rate Sparkline',

  searchImpressionShareToday: 'Search Impression Share Today',
  searchImpressionShareYesterday: 'Search Impression Share Yesterday',
  searchImpressionShareSparkline: 'Search Impression Share Sparkline',

  cpcvToday: 'CPCV Today',
  cpcvSparkline: 'CPCV Sparkline',
  cpcvYesterday: 'CPCV Yesterday',

  cpcToday: 'CPC Today',
  cpcSparkline: 'CPC Sparkline',
  cpcYesterday: 'CPC Yesterday',

  action01CountSparkline: 'Action 01 Count Sparkline',
  action01CvrSparkline: 'Action 01 Conversion Rate Sparkline',
  action01CpaSparkline: 'Action 01 CPA Sparkline',
  action01ValueSparkline: 'Action 01 Value Sparkline',
  action01RoasSparkline: 'Action 01 ROAS Sparkline',
  action01VpaSparkline: 'Action 01 Average Value Per Action Sparkline',

  action02CountSparkline: 'Action 02 Count Sparkline',
  action02CvrSparkline: 'Action 02 Conversion Rate Sparkline',
  action02CpaSparkline: 'Action 02 CPA Sparkline',
  action02ValueSparkline: 'Action 02 Value Sparkline',
  action02RoasSparkline: 'Action 02 ROAS Sparkline',
  action02VpaSparkline: 'Action 02 Average Value Per Action Sparkline',

  action03CountSparkline: 'Action 03 Count Sparkline',
  action03CvrSparkline: 'Action 03 Conversion Rate Sparkline',
  action03CpaSparkline: 'Action 03 CPA Sparkline',
  action03ValueSparkline: 'Action 03 Value Sparkline',
  action03RoasSparkline: 'Action 03 ROAS Sparkline',
  action03VpaSparkline: 'Action 03 Average Value Per Action Sparkline',

  action01CountToday: 'Action 01 Count Today',
  action01CvrToday: 'Action 01 Conversion Rate Today',
  action01CpaToday: 'Action 01 CPA Today',
  action01ValueToday: 'Action 01 Value Today',
  action01RoasToday: 'Action 01 ROAS Today',
  action01VpaToday: 'Action 01 Average Value Per Action Today',

  action02CountToday: 'Action 02 Count Today',
  action02CvrToday: 'Action 02 Conversion Rate Today',
  action02CpaToday: 'Action 02 CPA Today',
  action02ValueToday: 'Action 02 Value Today',
  action02RoasToday: 'Action 02 ROAS Today',
  action02VpaToday: 'Action 02 Average Value Per Action Today',

  action03CountToday: 'Action 03 Count Today',
  action03CvrToday: 'Action 03 Conversion Rate Today',
  action03CpaToday: 'Action 03 CPA Today',
  action03ValueToday: 'Action 03 Value Today',
  action03RoasToday: 'Action 03 ROAS Today',
  action03VpaToday: 'Action 03 Average Value Per Action Today',

  action01CountYesterday: 'Action 01 Count Yesterday',
  action01CvrYesterday: 'Action 01 Conversion Rate Yesterday',
  action01CpaYesterday: 'Action 01 CPA Yesterday',
  action01ValueYesterday: 'Action 01 Value Yesterday',
  action01RoasYesterday: 'Action 01 ROAS Yesterday',
  action01VpaYesterday: 'Action 01 Average Value Per Action Yesterday',

  action02CountYesterday: 'Action 02 Count Yesterday',
  action02CvrYesterday: 'Action 02 Conversion Rate Yesterday',
  action02CpaYesterday: 'Action 02 CPA Yesterday',
  action02ValueYesterday: 'Action 02 Value Yesterday',
  action02RoasYesterday: 'Action 02 ROAS Yesterday',
  action02VpaYesterday: 'Action 02 Average Value Per Action Yesterday',

  action03CountYesterday: 'Action 03 Count Yesterday',
  action03CvrYesterday: 'Action 03 Conversion Rate Yesterday',
  action03CpaYesterday: 'Action 03 CPA Yesterday',
  action03ValueYesterday: 'Action 03 Value Yesterday',
  action03RoasYesterday: 'Action 03 ROAS Yesterday',
  action03VpaYesterday: 'Action 03 Average Value Per Action Yesterday'
};

// CATEGORIES

export const categories = {
  ALL: [
    '_id',
    'warnings',
    'status',
    'invoiceStatus',
    'invoiceOrderId',
    'checkedAt',
    'template',
    'path',
    'comments',
    'members',
    'labels',
    'allowImpressionsBeforeStartDate',
    'dateRange',
    'startDate',
    'endDate',
    'allowImpressionsAfterEndDate',
    'numberOfDays',
    // 'numberOfDaysLeft',
    'daysLeft',
    'taxonomyTargeting',
    'taxonomyWidgets',
    'generalDescription',
    'buyingTypeDescription',
    'creativeDescription',
    'formatDescription',
    'channelDescription',
    'audienceDataDescription',
    'targetGroupDescription',
    'placementDescription',
    'ganttBarDisplaySetting',
    'goalDescription',
    'budget',
    'budgetLeft',
    'budgetCurrency',
    'allowMissingBudget',
    'calcDailyBudget',
    'calcDailyBudgetYesterday',
    'plannedGross',
    'plannedNet',
    'plannedNetNet',
    'plannedCtc',

    'plannedTrp',
    'plannedCpp',
    'plannedTechFee',
    'plannedAdOpsFee',
    'plannedAdServingCost',
    'plannedFeePercent',

    'kpi',
    'plannedImpressions',
    'plannedClicks',
    'plannedCtr',
    'plannedCpc',
    'plannedReach',
    'plannedCpm',
    'plannedFrequency',
    'plannedActionCount',
    'plannedActionValue',
    'plannedActionRoas',
    'plannedCpa',
    'cost',
    'dataSourceCurrency',
    // 'costLifetime',
    // 'impressionsLifetime',
    'pace',
    // 'paceYesterday',
    'calcPaceYesterday',
    // 'pace_bar',
    'universe',
    'trp',
    'spots',
    'impressions',
    'viewableImpressions',
    'measurableImpressions',
    'eligibleImpressions',
    'cpm',
    'vCpm',
    'videoCompletions',
    // 'reach',
    'clicks',
    'allowMissingClicks',
    'allowAbnormallyHighCtr',
    'allowMissingImpressionsYesterday',
    'ctr',
    'viewableRate',
    'vcr',
    'searchImpressionShare',
    'cpcv',
    'cpc',
    'action01',
    'action01Count',
    'action01Cvr',
    'action01Cpa',
    'action01Value',
    'action01Roas',
    'action01Vpa',
    'action02',
    'action02Count',
    'action02Cvr',
    'action02Cpa',
    'action02Value',
    'action02Roas',
    'action02Vpa',
    'action03',
    'action03Count',
    'action03Cvr',
    'action03Cpa',
    'action03Value',
    'action03Roas',
    'action03Vpa',

    'totalCost',
    'totalImpressions',
    'totalCpm',
    'totalClicks',
    'totalCtr',
    'totalCpc',

    'totalAction01',
    'totalAction01Count',
    // 'totalaction01Cvr',
    'totalAction01Cpa',
    'totalAction01Value',
    'totalAction01Roas',
    'totalAction01Vpa',
    'totalAction02',
    'totalAction02Count',
    // 'totalaction02Cvr',
    'totalAction02Cpa',
    'totalAction02Value',
    'totalAction02Roas',
    'totalAction02Vpa',
    'totalAction03',
    'totalAction03Count',
    // 'totalaction03Cvr',
    'totalAction03Cpa',
    'totalAction03Value',
    'totalAction03Roas',
    'totalAction03Vpa',

    'ganttBarColor',
    'gantt',

    'impressionsToday',
    'impressionsYesterday',
    'impressionsSparkline',

    'viewableImpressionsToday',
    'viewableImpressionsYesterday',
    'viewableImpressionsSparkline',

    'measurableImpressionsToday',
    'measurableImpressionsYesterday',
    'measurableImpressionsSparkline',

    'eligibleImpressionsToday',
    'eligibleImpressionsYesterday',
    'eligibleImpressionsSparkline',

    'videoCompletionsToday',
    'videoCompletionsYesterday',
    'videoCompletionsSparkline',

    // 'cpmToday',
    // 'cpmYesterday',
    'cpmSparkline',
    'vCpmSparkline',

    'costToday',
    'costYesterday',
    'costSparkline',

    // 'clicksToday',
    // 'clicksYesterday',
    'clicksSparkline',

    // 'ctrToday',
    // 'ctrYesterday',
    'ctrSparkline',
    'viewableRateSparkline',
    'vcrSparkline',
    'searchImpressionShareSparkline',

    // 'cpcToday',
    'cpcvSparkline',
    'cpcSparkline',
    // 'cpcYesterday',

    'action01CountSparkline',
    'action01CvrSparkline',
    'action01CpaSparkline',
    'action01ValueSparkline',
    'action01RoasSparkline',
    'action01VpaSparkline',

    'action02CountSparkline',
    'action02CvrSparkline',
    'action02CpaSparkline',
    'action02ValueSparkline',
    'action02RoasSparkline',
    'action02VpaSparkline',

    'action03CountSparkline',
    'action03CvrSparkline',
    'action03CpaSparkline',
    'action03ValueSparkline',
    'action03RoasSparkline',
    'action03VpaSparkline'

    // 'action01CountToday',
    // 'action01CvrToday',
    // 'action01CpaToday',
    // 'action01ValueToday',
    // 'action01RoasToday',
    // 'action01VpaToday',

    // 'action02CountToday',
    // 'action02CvrToday',
    // 'action02CpaToday',
    // 'action02ValueToday',
    // 'action02RoasToday',
    // 'action02VpaToday',

    // 'action03CountToday',
    // 'action03CvrToday',
    // 'action03CpaToday',
    // 'action03ValueToday',
    // 'action03RoasToday',
    // 'action03VpaToday',

    // 'action01CountYesterday',
    // 'action01CvrYesterday',
    // 'action01CpaYesterday',
    // 'action01ValueYesterday',
    // 'action01RoasYesterday',
    // 'action01VpaYesterday',

    // 'action02CountYesterday',
    // 'action02CvrYesterday',
    // 'action02CpaYesterday',
    // 'action02ValueYesterday',
    // 'action02RoasYesterday',
    // 'action02VpaYesterday',

    // 'action03CountYesterday',
    // 'action03CvrYesterday',
    // 'action03CpaYesterday',
    // 'action03ValueYesterday',
    // 'action03RoasYesterday',
    // 'action03VpaYesterday'
  ],
  // BUDGET: [
  //   'budget',
  //   'budgetCurrency',
  //   'allowMissingBudget',
  //   'calcDailyBudget',
  //   'calcDailyBudgetYesterday'
  // ],
  PERFORMANCE: [
    'cost',
    'dataSourceCurrency',
    // 'costLifetime',
    // 'impressionsLifetime',
    // 'pace',
    // 'paceYesterday',
    // 'calcPaceYesterday',

    // 'trp',
    // 'spots',
    'impressions',
    // 'viewableImpressions',
    // 'measurableImpressions',
    // 'eligibleImpressions',
    'cpm',
    // 'vCpm',
    // 'reach',
    'clicks',
    'ctr',
    // 'viewableRate',
    'videoCompletions',
    'cpcv',
    'cpc',

    'impressionsToday',
    'impressionsYesterday',
    'impressionsSparkline',

    // 'viewableImpressionsToday',
    // 'viewableImpressionsYesterday',
    // 'viewableImpressionsSparkline',

    // 'measurableImpressionsToday',
    // 'measurableImpressionsYesterday',
    // 'measurableImpressionsSparkline',

    // 'eligibleImpressionsToday',
    // 'eligibleImpressionsYesterday',
    // 'eligibleImpressionsSparkline',

    // 'cpmToday',
    // 'cpmYesterday',
    'cpmSparkline',
    // 'vCpmSparkline',

    'costToday',
    'costYesterday',
    'costSparkline',

    // 'clicksToday',
    // 'clicksYesterday',
    'clicksSparkline',

    // 'ctrToday',
    // 'ctrYesterday',
    'ctrSparkline',
    // 'viewableRateSparkline',
    // 'searchImpressionShareSparkline',

    // 'cpcToday',
    'cpcvSparkline',
    'cpcSparkline'
    // 'cpcYesterday',
  ],
  VIEWABILITY: [
    'viewableImpressions',
    'measurableImpressions',
    'eligibleImpressions',
    'viewableRate',
    'viewableRateSparkline',
    'viewableImpressionsToday',
    'viewableImpressionsYesterday',
    'viewableImpressionsSparkline',

    'measurableImpressionsToday',
    'measurableImpressionsYesterday',
    'measurableImpressionsSparkline',

    'eligibleImpressionsToday',
    'eligibleImpressionsYesterday',
    'eligibleImpressionsSparkline',
    'vCpm',
    'vCpmSparkline'
  ],
  BUDGETANDPACE: [
    'budget',
    'budgetLeft',
    'budgetCurrency',
    'calcDailyBudget',
    'calcDailyBudgetYesterday',
    'pace',
    // 'paceYesterday',
    'calcPaceYesterday'
  ],
  // PACE: [
  //   // 'budget',
  //   // 'budgetCurrency',
  //   // 'allowMissingBudget',
  //   // 'calcDailyBudget',
  //   // 'calcDailyBudgetYesterday',
  //   'pace',
  //   'paceYesterday',
  //   'calcPaceYesterday'
  //   // 'pace_bar',
  // ],
  ACTIONS: [
    'action01',
    'action01Count',
    'action01Cvr',
    'action01Cpa',
    'action01Value',
    'action01Roas',
    'action01Vpa',
    'action02',
    'action02Count',
    'action02Cvr',
    'action02Cpa',
    'action02Value',
    'action02Roas',
    'action02Vpa',
    'action03',
    'action03Count',
    'action03Cvr',
    'action03Cpa',
    'action03Value',
    'action03Roas',
    'action03Vpa',

    'totalAction01',
    'totalAction01Count',
    // 'totalaction01Cvr',
    'totalAction01Cpa',
    'totalAction01Value',
    'totalAction01Roas',
    'totalAction01Vpa',
    'totalAction02',
    'totalAction02Count',
    // 'totalaction02Cvr',
    'totalAction02Cpa',
    'totalAction02Value',
    'totalAction02Roas',
    'totalAction02Vpa',
    'totalAction03',
    'totalAction03Count',
    // 'totalaction03Cvr',
    'totalAction03Cpa',
    'totalAction03Value',
    'totalAction03Roas',
    'totalAction03Vpa',

    'action01CountSparkline',
    'action01CvrSparkline',
    'action01CpaSparkline',
    'action01ValueSparkline',
    'action01RoasSparkline',
    'action01VpaSparkline',

    'action02CountSparkline',
    'action02CvrSparkline',
    'action02CpaSparkline',
    'action02ValueSparkline',
    'action02RoasSparkline',
    'action02VpaSparkline',

    'action03CountSparkline',
    'action03CvrSparkline',
    'action03CpaSparkline',
    'action03ValueSparkline',
    'action03RoasSparkline',
    'action03VpaSparkline'

    // 'action01CountToday',
    // 'action01CvrToday',
    // 'action01CpaToday',
    // 'action01ValueToday',
    // 'action01RoasToday',
    // 'action01VpaToday',

    // 'action02CountToday',
    // 'action02CvrToday',
    // 'action02CpaToday',
    // 'action02ValueToday',
    // 'action02RoasToday',
    // 'action02VpaToday',

    // 'action03CountToday',
    // 'action03CvrToday',
    // 'action03CpaToday',
    // 'action03ValueToday',
    // 'action03RoasToday',
    // 'action03VpaToday',

    // 'action01CountYesterday',
    // 'action01CvrYesterday',
    // 'action01CpaYesterday',
    // 'action01ValueYesterday',
    // 'action01RoasYesterday',
    // 'action01VpaYesterday',

    // 'action02CountYesterday',
    // 'action02CvrYesterday',
    // 'action02CpaYesterday',
    // 'action02ValueYesterday',
    // 'action02RoasYesterday',
    // 'action02VpaYesterday',

    // 'action03CountYesterday',
    // 'action03CvrYesterday',
    // 'action03CpaYesterday',
    // 'action03ValueYesterday',
    // 'action03RoasYesterday',
    // 'action03VpaYesterday'
  ],

  COMPETITIVE: ['searchImpressionShare', 'eligibleImpressions'],
  PLANNED: [
    'plannedGross',
    'plannedNet',
    'plannedNetNet',
    'plannedCtc',

    'plannedTrp',
    'plannedCpp',
    'plannedTechFee',
    'plannedAdOpsFee',
    'plannedAdServingCost',
    'plannedFeePercent',

    // 'kpi',
    'plannedImpressions',
    'plannedClicks',
    'plannedCtr',
    'plannedCpc',
    'plannedReach',
    'plannedCpm',
    'plannedFrequency',
    'plannedActionCount',
    'plannedActionValue',
    'plannedActionRoas',
    'plannedCpa'
  ],
  DESCRIPTIONS: [
    'generalDescription',
    'buyingTypeDescription',
    'creativeDescription',
    'formatDescription',
    'channelDescription',
    'audienceDataDescription',
    'targetGroupDescription',
    'placementDescription',
    'ganttBarDisplaySetting',
    'goalDescription'
  ],
  WARNINGS: [
    'warnings',
    'allowImpressionsBeforeStartDate',
    'allowImpressionsAfterEndDate',
    'allowMissingBudget',
    'allowMissingClicks',
    'allowAbnormallyHighCtr',
    'allowMissingImpressionsYesterday'
  ],
  MISCELLANEOUS: [
    '_id',
    'warnings',
    'status',
    'checkedAt',
    'template',
    'path',
    'comments',
    'members',
    'labels',
    'dateRange',
    'startDate',
    'endDate',

    'numberOfDays',
    // 'numberOfDaysLeft',
    'daysLeft',
    // 'taxonomyTargeting',
    // 'taxonomyWidgets',

    'ganttBarColor',
    'gantt'
  ]
};
